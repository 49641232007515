import { useEffect, useRef, useState } from 'react';
import { Button } from '@trimbleinc/modus-react-bootstrap';
import './styles.scss';
import StartDateTimeInput from '../../../../../../components/Common/StartDateTimeInput';
import EndDateTimeInput from '../../../../../../components/Common/EndDateTimeInput';
import IncidentIdInput from '../../../../../../components/Common/IncidentIdInput';
import DropDownInput from '../../../../../../components/Common/DropDownInput';
import MessageBox from '../../../../../../components/Common/MessageBox';
import AccordionInput from '../../../../../../components/Common/AccordionInput';
import Swal from 'sweetalert2';
import {
  CREATE_FORM_CONSTANTS,
  createRequest,
  details,
  CREATE_INCIDENT_MACRO_VARIBALES,
} from './CreateIncidentForm.constants';
import { useNavigate } from 'react-router-dom';
import { createIncident } from '../../../../../../services/incidentManagement.service';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { fetchServiceMessage } from 'services/serviceManagement.service';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';
import AlternativeBeamInput from 'components/Common/AlternativeBeamInput';

function CreateIncidentForm() {
  const [incidentId, setIncidentId] = useState('');
  const [isIncidentIdError, setIsIncidentIdError] = useState(false);
  const [closedIncidentId, setClosedIncidentId] = useState('');
  const [isClosedIncidentIdError, setIsClosedIncidentIdError] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedService, setSelectedService] = useState('');
  const [isServiceError, setIsServiceError] = useState(false);
  const [incidentType, setIncidentType] = useState('');
  const [isIncidentTypeError, setIsIncidentTypeError] = useState(false);
  const [incidentState, setIncidentState] = useState('');
  const [isIncidentStateError, setIsIncidentStateError] = useState(false);
  const [startDateTime, setStartDateTime] = useState<string | null>(null);
  const [isStartDateTimeError, setIsStartDateTimeError] = useState(false);
  const [endDateTime, setEndDateTime] = useState<string | null>(null);
  const [isEndDateTimeError, setIsEndDateTimeError] = useState(false);
  const [isEndTimeLessError, setIsEndTimeLessError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [originalMessage, setOriginalMessage] = useState('');
  const [emailName, setEmailName] = useState('');
  const [duration, setDuration] = useState('');
  const [plannedDate, setPlannedDate] = useState('');
  const [plannedTime, setPlannedTime] = useState('');
  const [isMessageError, setIsMessageError] = useState(false);
  const [isValidLink, setIsValidLink] = useState(true);
  const [alternativeBeamName, setAlternativeBeamName] = useState('');
  const [isAlternativeBeamError, setIsAlternativeBeamError] = useState(false);
  const [isPlannedMaintenance, setIsPlannedMaintenance] = useState(false);
  const navigate = useNavigate();
  const abortControllerRef = useRef<AbortController | null>(null);

  const handleIdChange = (value: string) => {
    setIncidentId(value);
    if (value !== '' && /^\d+$/.test(value)) {
      setIsIncidentIdError(false);
    } else if (!/^\d+$/.test(value)) {
      setIsIncidentIdError(true);
    }
  };

  const handleClosedIdChange = (value: string) => {
    setClosedIncidentId(value);
    if (value === '' || /^\d+$/.test(value)) {
      setIsClosedIncidentIdError(false);
    } else if (!/^\d+$/.test(value)) {
      setIsClosedIncidentIdError(true);
    }
  };

  const handleBeamNameChange = (value: string) => {
    setAlternativeBeamName(value);
  }

  const handleServiceSelect = (product: string, service: string) => {
    setSelectedProduct(product);
    setSelectedService(service);
    if (product !== '' && service !== '') {
      setIsServiceError(false);
    }
  };

  const handleDropDownSelect = (type: string, value: string) => {
    setIsPlannedMaintenance(false);
    if (type === 'INCIDENT_TYPE') {
      if (value === 'Outage') {
        setIncidentType('Outage');
      } else if (value === 'Partial Outage') {
        setIncidentType('PartialOutage');
      } else if (value === 'Planned Maintenance') {
        setIncidentType('PlannedMaintenance');
        setClosedIncidentId('');
        setAlternativeBeamName('');
        setIsPlannedMaintenance(true);
      }

      if (value !== '') {
        setIsIncidentTypeError(false);
      }
    } else if (type === 'INCIDENT_STATE_CREATE') {
      setIncidentState(value);

      if (value !== '') {
        setIsIncidentStateError(false);
      }
    }
  };

  const handleStartDateTimeChange = (value: string | null) => {
    setStartDateTime(value);
    if (value !== null) {
      setIsStartDateTimeError(false);
    }
  };

  const handleEndDateTimeChange = (value: string | null) => {
    setEndDateTime(value);
    if (
      (incidentState === '' || incidentState === 'Resolved') &&
      value !== null
    ) {
      setIsEndDateTimeError(false);
    }

    if (value !== null && startDateTime !== null && value > startDateTime) {
      setIsEndTimeLessError(false);
    }
  };

  const frameMessage = (
    message: string,
    emailName?: string,
    startDateTime?: string | null,
    endDateTime?: string | null,
    duration?: string,
    plannedDate?: string,
    plannedTime?: string,
    alternativeBeamName?: string
  ) => {
    message = message
      .replace(
        /\$\{service\}/g,
        emailName && emailName !== '' ? emailName : '${service}'
      )
      .replace(/\$\{startTime\}/g, startDateTime ?? '${startTime}')
      .replace(/\$\{endTime\}/g, endDateTime ?? '${endTime}')
      .replace(
        /\$\{duration\}/g,
        duration && duration !== '' ? duration : '${duration}'
      )
      .replace(
        /\$\{plannedDate\}/g,
        plannedDate && plannedDate !== '' ? plannedDate : '${plannedDate}'
      )
      .replace(
        /\$\{plannedTime\}/g,
        plannedTime && plannedTime !== '' ? plannedTime : '${plannedTime}'
      )
      .replace(/\$\{alternativeBeamName\}/g, alternativeBeamName ?? '${alternativeBeamName}')
      .replace(/<p>\s*<br>\s*<\/p>/g, '');

    return message;
  };

  const isAbsoluteUrl = (url: string): boolean => {
    return url.startsWith('http://') || url.startsWith('https://');
  };

  const extractUrlsAndValidate = (html: string) => {
    const urlPattern = /href="([^"]+)"/g;

    let match;
    let isValidLink = true;
    while ((match = urlPattern.exec(html)) !== null) {
      const url = match[1];
      const isValid = isAbsoluteUrl(url);
      if (!isValid) {
        isValidLink = false;
      }
    }
    return isValidLink;
  };

  const handleMessageChange = (value: string) => {
    setOriginalMessage(() => value.trim());

    const framedMessage = frameMessage(
      value,
      emailName,
      startDateTime,
      endDateTime,
      duration,
      plannedDate,
      plannedTime,
      alternativeBeamName
    );

    if (framedMessage.length > 0 && framedMessage.trim() !== '') {
      setIsMessageError(false);
    }

    if (extractUrlsAndValidate(framedMessage)) {
      setIsValidLink(true);
    }
  };

  const reset = () => {
    setIsLoading(false);
    setIncidentId('');
    setClosedIncidentId('');
    setSelectedProduct('');
    setSelectedService('');
    setIncidentType('');
    setIncidentState('');
    setStartDateTime(null);
    setEndDateTime(null);
    setOriginalMessage('');
    setFormKey((prevKey) => prevKey + 1);
    setIsIncidentIdError(false);
    setIsServiceError(false);
    setIsIncidentTypeError(false);
    setIsIncidentStateError(false);
    setIsStartDateTimeError(false);
    setIsEndDateTimeError(false);
    setIsEndTimeLessError(false);
    setIsMessageError(false);
    setIsValidLink(true);
    setAlternativeBeamName('');
  };

  const isFormValidationError = () => {
    let hasError = false;
    const framedMessage = frameMessage(
      originalMessage,
      emailName,
      startDateTime,
      endDateTime,
      duration,
      plannedDate,
      plannedTime,
      alternativeBeamName
    );

    if (incidentId === '' || !/^\d+$/.test(incidentId)) {
      setIsIncidentIdError(true);
      hasError = true;
    }

    if (selectedProduct === '' || selectedService === '') {
      setIsServiceError(true);
      hasError = true;
    }

    if (startDateTime === null) {
      setIsStartDateTimeError(true);
      hasError = true;
    }

    if (incidentState === 'Resolved' && endDateTime === null) {
      setIsEndDateTimeError(true);
      hasError = true;
    }

    if (
      endDateTime != null &&
      startDateTime !== null &&
      endDateTime <= startDateTime
    ) {
      setIsEndTimeLessError(true);
      hasError = true;
    }

    if (incidentType === '') {
      setIsIncidentTypeError(true);
      hasError = true;
    }

    if (incidentState === '') {
      setIsIncidentStateError(true);
      hasError = true;
    }

    if (framedMessage.length === 0 || framedMessage.trim() === '') {
      setIsMessageError(true);
      hasError = true;
    }

    if (!extractUrlsAndValidate(framedMessage)) {
      setIsValidLink(false);
      hasError = true;
    }

    return hasError;
  };

  const backToHome = () => {
    navigate(RouterPath.PRIVATE_ROUTE.incidentManagement);
  };

  const handleCreate = async () => {
    setIsLoading(true);
    setIsIncidentIdError(false);
    setIsClosedIncidentIdError(false);
    setIsServiceError(false);
    setIsStartDateTimeError(false);
    setIsEndDateTimeError(false);
    setIsIncidentTypeError(false);
    setIsIncidentStateError(false);
    setIsEndTimeLessError(false);
    setIsMessageError(false);
    setIsValidLink(true);
    setIsAlternativeBeamError(false);

    let message = await frameMessage(
      originalMessage,
      emailName,
      startDateTime,
      endDateTime,
      duration,
      plannedDate,
      plannedTime,
      alternativeBeamName
    );

    const incident: createRequest = {
      incidentId: incidentId,
      product: selectedProduct,
      service: selectedService,
      type: incidentType,
      incidentState: incidentState,
      startTimeInUTC: startDateTime,
      ...(endDateTime !== null && { endTimeInUTC: endDateTime }),
      ...(message !== '' && { message: message }),
      ...(closedIncidentId !== '' && { closedIncidentId: closedIncidentId }),
      ...(alternativeBeamName !== '' && { alternativeBeamName: alternativeBeamName }),
    };

    // send API request to create incident and handle success and failures using toasts
    try {
      // let createIncidentResponse = await createIncident(incident);
      let createIncidentResponse = await toast.promise(
        createIncident(incident),
        {
          pending: 'Incident creation in progress...',
        }
      );
      if (createIncidentResponse.status == 200) {
        toast.success(
          createIncidentResponse?.message ?? 'Incident created successfully'
        );
        logEvent('NEW_INCIDENT', 'USER_ACTIVITY', {
          incidentID: incident.incidentId,
          service: incident.service,
          product: incident.product,
          incidentType: incident.type,
          actions: 'Create',
          resource: 'Incidents'
        });
        setTimeout(() => {
          backToHome();
        }, 5000);
      } else {
        toast.error(
          createIncidentResponse?.data ?? createIncidentResponse.data
        );
        logEvent('NEW_INCIDENT', 'EXCEPTION', {
          incidentID: incident.incidentId,
          service: incident.service,
          product: incident.product,
          incidentType: incident.type,
          actions: 'Create',
          resource: 'Incidents',
          error: createIncidentResponse?.data ? `"${createIncidentResponse?.data}"` : 'Failed to create incident'
        });
      }
    } catch (error) {
      toast.error(String(error));
    } finally {
      setIsLoading(false);
    }
  };

  const isFormValid = () => {
    // Check for presence of mandatory values
    const hasMandatoryValues =
      incidentId &&
      selectedProduct &&
      selectedService &&
      incidentType &&
      incidentState &&
      startDateTime;

    return hasMandatoryValues;
  };

  const initiateCreate = () => {
    const hasError = isFormValidationError();
    if (!hasError) {
      Swal.fire({
        title: `<h4>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
        icon: 'info',
        html: `<p>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_CREATE}<br/>`,
        showCancelButton: true,
        confirmButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_CANCEL_TEXT,
        cancelButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_DISCARD_TEXT,
        preConfirm: async () => {
          try {
            Swal.close();
            handleCreate();
          } catch (error) {
            console.error(error);
          }
        },
      });
    }
  };

  const initiateCancel = () => {
    Swal.fire({
      title: `<h4>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'error',
      html: `<p>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_CLOSE}<br/>`,
      showCancelButton: true,
      confirmButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_CANCEL_TEXT,
      cancelButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_DISCARD_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          navigate(RouterPath.PRIVATE_ROUTE.incidentManagement);
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  const calculateDuration = (startTime: string, endTime: string) => {
    let startDate = new Date(startTime);
    let endDate = new Date(endTime);
    let duration = endDate.getTime() - startDate.getTime();

    let seconds = Math.floor(duration / 1000);
    let minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);

    seconds = seconds % 60;
    minutes = minutes % 60;
    let durationString = minutes + ' minutes';
    if (hours > 0) {
      const hourString = hours == 1 ? ' hour ' : ' hours ';
      const minuteString = minutes == 1 ? ' minute' : ' minutes';
      durationString =
        hours + hourString + (minutes > 0 ? minutes + minuteString : '');
    }
    return durationString;
  };

  const getMessage = async () => {
    if (
      selectedProduct != '' &&
      selectedService != '' &&
      incidentType != '' &&
      incidentState != ''
    ) {
      // Abort the previous request if it exists
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      // Create a new controller for the new request
      const controller = new AbortController();
      abortControllerRef.current = controller;

      let messageType = details[incidentState][incidentType];
      if (closedIncidentId !== '') {
        messageType = details['Transition'][incidentType];
      }
      if (alternativeBeamName !== '') {
        messageType = 'Disaster_Recovery';
      }
      const messageResponse = await fetchServiceMessage(
        selectedProduct,
        selectedService,
        messageType,
        controller
      );
      if (!messageResponse) {
        return; // Exit early if request was aborted
      }
      if (messageResponse && messageResponse.status !== 200) {
        toast.error('Failed to fetch message template');
        logEvent('GET_MESSAGE', 'EXCEPTION', {
          product: selectedProduct,
          service: selectedService,
          incidentType: messageType,
          actions: 'Retrieve',
          resource: 'Services',
          error: messageResponse?.data
            ? `"${messageResponse?.data}"`
            : 'Failed to fetch message template',
        });
        return;
      }
      let emailNameValue = messageResponse.data.emailName;
      setEmailName(() => emailNameValue);
      const message = messageResponse.data.template?.message ? messageResponse.data.template.message : '';
      if (message === '') {
        toast.warning(`Template doesn't exist. Provide your own template in the message box.`);
      }
      setOriginalMessage(() => message);
    }
  };

  // Trigger `getMessage` whenever relevant inputs change
  useEffect(() => {
    getMessage();
  }, [
    selectedProduct,
    selectedService,
    incidentType,
    incidentState,
    closedIncidentId,
    alternativeBeamName,
  ]);

  useEffect(() => {
    if (startDateTime !== null && endDateTime !== null) {
      let durationValue = calculateDuration(startDateTime, endDateTime);
      setDuration(() => durationValue);
    }

    if (startDateTime !== null) {
      const startTimeDetails = startDateTime.split(' ');
      const plannedDate = startTimeDetails[0];
      const plannedTime = startTimeDetails[1];
      setPlannedDate(() => plannedDate);
      setPlannedTime(() => plannedTime);
    }
  }, [startDateTime, endDateTime]);

  return (
    <>
      <div className='main-tab-container' key={formKey}>
        <h1 className='heading'>Create Incident</h1>
        <div>
          <div className='create-incident-card'>
            <IncidentIdInput
              onIdChange={handleIdChange}
              isError={isIncidentIdError}
              isDisabled={false}
            />
            <AccordionInput
              onServiceSelect={handleServiceSelect}
              isError={isServiceError}
              isDisabled={false}
              isSearchFilter={false}
            />
            <DropDownInput
              type='INCIDENT_TYPE'
              onSelect={handleDropDownSelect}
              isError={isIncidentTypeError}
              isDisabled={false}
            />
            <DropDownInput
              type='INCIDENT_STATE_CREATE'
              onSelect={handleDropDownSelect}
              isError={isIncidentStateError}
              isDisabled={false}
            />
            <StartDateTimeInput
              onDateTimeChange={handleStartDateTimeChange}
              isError={isStartDateTimeError}
              value={startDateTime}
              isDisabled={false}
            />
            <EndDateTimeInput
              onDateTimeChange={handleEndDateTimeChange}
              isError={isEndDateTimeError}
              isEndTimeLessError={isEndTimeLessError}
              value={endDateTime}
              isDisabled={false}
            />
            <MessageBox
              value={originalMessage}
              previewMessage={frameMessage(
                originalMessage,
                emailName,
                startDateTime,
                endDateTime,
                duration,
                plannedDate,
                plannedTime,
                alternativeBeamName
              )}
              onMessageChange={handleMessageChange}
              macroVariables={CREATE_INCIDENT_MACRO_VARIBALES}
              isError={isMessageError}
              isValidLink={isValidLink}
            />
            <IncidentIdInput
              onIdChange={handleClosedIdChange}
              isError={isClosedIncidentIdError}
              value={closedIncidentId}
              isDisabled={isPlannedMaintenance}
              type='closedIncident'
            />
            <AlternativeBeamInput
              onBeamChange={handleBeamNameChange}
              isError={isAlternativeBeamError}
              value={alternativeBeamName}
              isDisabled={isPlannedMaintenance}
            />
          </div>
          <div
            className='d-flex justify-content-center'
            style={{ marginTop: '40px' }}
          >
            <Button
              variant='secondary'
              style={{ marginRight: '10px' }}
              onClick={initiateCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              variant='secondary'
              style={{ marginRight: '10px' }}
              onClick={reset}
              disabled={isLoading}
            >
              Reset
            </Button>
            <Button
              variant='primary'
              onClick={initiateCreate}
              disabled={!isFormValid() || isLoading}
            >
              <i
                className='modus-icons material-icons left-icon'
                aria-hidden='true'
              >
                save_disk
              </i>
              Create
            </Button>
          </div>
          <br></br>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default CreateIncidentForm;
