import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
  executeAuthorization,
  getTokens,
  getUser,
  refreshToken,
} from '../../services/auth.service';
import {
  clearLocalStorage,
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from '../../utils/LocalStorage.utils';
import Environment from '../../config';
import { currentTime } from '../../utils/Auth.utils';
import { useEffect, useRef, useState } from 'react';
import Loading from 'components/Common/Loading';
import { clearSessionAndRedirect } from 'utils/Error.utils';

const WithAuth = () => {
  const location: any = useLocation();
  useEffect(() => {
    const accessToken = getItemFromLocalStorage('accessToken');
    if(accessToken === 'undefined') {
      clearSessionAndRedirect();
    }
    tokenExpiryCheck();
  }, [location]);
  const accessToken = getItemFromLocalStorage('accessToken');
  const authDetails = getItemFromLocalStorage('auth');
  if (!accessToken && !authDetails) {
    return <HandleSession />;
  } else {
    return <Outlet />;
  }
};
const tokenExpiryCheck = () => {
  const tokenExpiryTime: string | null = getItemFromLocalStorage('expiresIn');
  if (tokenExpiryTime && parseInt(tokenExpiryTime) - currentTime() < 120) {
    debouncedRefreshToken();
  }
};
const debounce = <T extends (...args: any[]) => void>(
  func: T,
  delay: number
): ((...args: Parameters<T>) => void) => {
  let debounceTimer: NodeJS.Timeout;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>): void {
    const context = this as ThisParameterType<T>;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

const debouncedRefreshToken = debounce(() => refreshToken(), 3000);

export function GetAccessToken() {
  const [isFetching, setIsFetching] = useState(true);
  const requestInitiated = useRef(false);
  let location = useLocation();
  useEffect(() => {
    if (requestInitiated.current) return; // Prevents duplicate requests
    requestInitiated.current = true;
  (async () => {
    let authCode = getAuthCodeFromUrl(location.search);
    let tokensResponse = await getTokens(authCode);
    if (tokensResponse?.error) {
      window.alert(tokensResponse?.error ?? 'Error fetching tokens.');
      window.location.href = '/';
      return;
    }
    setItemInLocalStorage('refreshToken', tokensResponse.refresh_token);
    setItemInLocalStorage('accessToken', tokensResponse.access_token);
    setItemInLocalStorage(
      'expiresIn',
      (currentTime() + tokensResponse.expires_in).toString()
    );
    let userInfoResponse = await getUser(tokensResponse.access_token);
    setItemInLocalStorage('auth', JSON.stringify(userInfoResponse[0]));
    setItemInLocalStorage('role', userInfoResponse[1]);
    setItemInLocalStorage('justFetch', 'true');
    setIsFetching(false);
    const redirectUrl =
      userInfoResponse[1].role === 'viewer'
        ? `${Environment.applicationHomeUrl}/viewer`
        : Environment.applicationHomeUrl;
    window.location.href = redirectUrl;
  })();
},[location.search]);
  return (
    <div
      className="d-flex justify-content-center"
      style={{ width: '100%', marginTop: '20px' }}
    >
      {isFetching && <Loading />}
    </div>
  );
}

function HandleSession() {
  (async () => {
    await executeAuthorization();
  })();
  return null;
}

function getAuthCodeFromUrl(locationString: string) {
  const codeRegex = /[?&]code=([^&]+)/;
  const match = codeRegex.exec(locationString);
  const authCodeValue = match && decodeURIComponent(match[1]);
  setItemInLocalStorage('authCode', authCodeValue ?? '');
  return authCodeValue ?? '';
}

export function Logout() {
  const Styles = {
    container: {
      height: '100vh',
    },
  };

  const navigate = useNavigate();
  useEffect(() => {
    clearLocalStorage();
    navigate('/');
  }, []);

  return (
    <div
      className="d-flex align-items-center justify-content-center w-100"
      style={Styles.container}
    >
      <Loading />
    </div>
  );
}

export default WithAuth;
