const USERMANAGEMENT_BASE_URL =
  'https://cloud.api.trimble.com/tap-informer/users/v1';
const INCIDENTMANAGEMENT_BASE_URL =
  'https://cloud.api.trimble.com/tap-informer/v1';
const SERVICEMANAGEMENT_BASE_URL =
  'https://cloud.api.trimble.com/tap-informer/services/v1';
const GROUPMANAGEMENT_BASE_URL =
  'https://cloud.api.trimble.com/tap-informer/groups/v1';
const EMAILMANAGEMENT_BASE_URL = 
  'https://cloud.api.trimble.com/tap-informer/mailer/v1';
const APP_BASE_URL =
  'https://tap-incident-management.positioningservices.trimble.com';
const TID_BASE_URL = 'https://id.trimble.com';
const STATUS_SIGNUP_PAGE_URL =
  'https://status-signup.positioningservices.trimble.com/';
const STATUSCAST_BASEURL = 'https://status.positioningservices.trimble.com';
const STATUSCAST_TOKEN = process.env.REACT_APP_STATUSCAST_TOKEN;

export const env = {
  clientId: '0bfee611-aac1-4e7f-9e05-00dd494c5f50',
  applicationName: 'tap-informer-user-ui-prod',
  tenantDomain: 'Trimble.com',
  scope: 'openid',
  redirectUrl: `${APP_BASE_URL}/redirect`,
  logoutUrl: `${TID_BASE_URL}/oauth/logout`,
  userManagementUrl: `${USERMANAGEMENT_BASE_URL}`,
  authCodeUrl: `${TID_BASE_URL}/oauth/authorize`,
  accessTokenUrl: `${TID_BASE_URL}/oauth/token`,
  userInfoUrl: `${TID_BASE_URL}/oauth/userinfo`,
  applicationHomeUrl: `${APP_BASE_URL}`,
  incidentManagementUrl: `${INCIDENTMANAGEMENT_BASE_URL}`,
  serviceManagementUrl: `${SERVICEMANAGEMENT_BASE_URL}`,
  groupManagementUrl: `${GROUPMANAGEMENT_BASE_URL}`,
  emailManagementUrl: `${EMAILMANAGEMENT_BASE_URL}`,
  domoDatasetId: '0deec983-51e7-4c45-8424-6048cfa05614',
  domoClientId: '93bcf1e2-ed09-44a1-ba27-814f18ee0079',
  domoClientSecret:
    'd967bc573e563e0a696016d393a785358eb4957459edf49843d6914b155b6242',
  statusSignupPageUrl: STATUS_SIGNUP_PAGE_URL,
  statusCastBaseUrl: STATUSCAST_BASEURL,
  statusCastToken: STATUSCAST_TOKEN,
};
