export const START_TIME_CONSTANTS = {
  input: 'Start Time in UTC',
  label: 'Start time in UTC',
  error: 'Please select a start time',
};

export interface IStartDateTimeInputProps {
  onDateTimeChange?: (value: string | null) => void;
  isError: boolean;
  value?: string | null | undefined;
  isDisabled: boolean;
}
