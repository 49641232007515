import { generateCodeVerifier, getCodeChallenger } from "../utils/Auth.utils";
import { getItemFromLocalStorage, setItemInLocalStorage } from "../utils/LocalStorage.utils";
import Environment from "../config"
import { currentTime } from "../utils/Auth.utils";
import { fetchUsers } from "./userMgmt.service";
import logEvent from "utils/TrimbleInsightsLogger.utils";

export const executeAuthorization = () => {
    window.location.href = getAuthCodeUrl()
}

const getAuthCodeUrl = () => {
    let authCodeUrl = Environment.authCodeUrl
    authCodeUrl += "?client_id=" + Environment.clientId
    authCodeUrl += "&response_type=code"
    authCodeUrl += "&scope=openid " + Environment.applicationName
    authCodeUrl += "&redirect_uri=" + Environment.redirectUrl
    authCodeUrl += "&code_challenge=" + getCodeChallenger(generateCodeVerifier())
    authCodeUrl += "&code_challenge_method=S256"
    return authCodeUrl;
}

export const getTokens = async (authorizationCode: string) => {
    let parameters = {
        "grant_type": "authorization_code",
        "code": authorizationCode,
        "tenantDomain": Environment.tenantDomain,
        "redirect_uri": Environment.redirectUrl,
        "client_id": Environment.clientId,
        "code_verifier": getItemFromLocalStorage("codeVerifier"),
        "code_challenge": getCodeChallenger(generateCodeVerifier()),
        "code_challenge_method": "S256"
    }
    const formBody = Object.entries(parameters).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
    const response = await fetch(Environment.accessTokenUrl, {
        headers: new Headers(
            {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        ),
        method: 'POST',
        body: formBody
    })
    const responseData = await response.json();
    return responseData
}

export const logoutUser = async () => {
    window.open(await getLogoutUrl(), "_self");
    // clearLocalStorage()
}

const getLogoutUrl = () => {
    let authCodeUrl = Environment.logoutUrl
    authCodeUrl += "?id_token_hint=" + getItemFromLocalStorage("accessToken")
    authCodeUrl += "&post_logout_redirect_uri=" + Environment.applicationHomeUrl+"/logout"
    localStorage.clear();
    return authCodeUrl;

    
}

export const getUser = async (access_token: string) => {
  let response = await fetch(Environment.userInfoUrl, {
    headers: new Headers({
      Authorization: 'Bearer ' + access_token,
      'Content-Type': 'application/json',
    }),
    method: 'GET',
  });

  const responseData = await response.json();
  const email = responseData.email;
  const data = {
    firstName: responseData.given_name,
    lastName: responseData.family_name,
  };

  const userExists = await fetchUsers({email});

  if(userExists.status !== 200) {
    logEvent('GET_USER', 'EXCEPTION', {
      actions: 'Retrieve',
      resource: 'Users',
      error: userExists?.data
        ? `"${userExists?.data}"`
        : 'Failed to fetch user access details',
    });
  }

  const role =
    userExists.data && userExists.data.length > 0
      ? userExists.data[0].role
      : 'viewer';

  return [{ email, data }, role];
}

export const refreshToken = async () => {
    let parameters = {
        "grant_type": "refresh_token",
        "refresh_token": getItemFromLocalStorage("refreshToken"),
        "client_id": Environment.clientId,
        "code_verifier": getItemFromLocalStorage("codeVerifier"),
        "code_challenge": getCodeChallenger(generateCodeVerifier()),
        "code_challenge_method": "S256"
    }
    const formBody = Object.entries(parameters).map(([key, value]) => encodeURIComponent(key) + '=' + encodeURIComponent(value)).join('&')
    const response = await fetch(Environment.accessTokenUrl, {
        method: 'POST',
        body: formBody,
    })
    const responseData = await response.json();
    setItemInLocalStorage("refreshToken", responseData.refresh_token)
    setItemInLocalStorage("accessToken", responseData.access_token)
    setItemInLocalStorage("expiresIn", (currentTime() + responseData.expires_in).toString())
}