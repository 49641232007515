export const EDIT_LEVEL_CONSTANTS = {
  EMAIL: {
    label: 'Email',
    key: 'userEmailId',
    validationMessage: 'Please enter a valid email',
    error_length: 'Email should not exceed 250 characters',
  },
  LEVEL: {
    label: 'Level',
    key: 'updatedLevel',
    drop_down_default: '-Select a level-',
    validationMessage: 'Please select a level',
  },
  LEVEL_LIST: [
    {
      name: 'First Internal Escalation (L team)',
      code: 'First Internal Escalation (L team)',
    },
    {
      name: 'Second Internal Escalation (VP)',
      code: 'Second Internal Escalation (VP)',
    },
  ],
  MODIFY_LEVEL: {
    label: 'Modify Level',
  },

  MODIFY_LEVEL_API_ERROR: 'Something went wrong',
  MODIFY_LEVEL_SUCCESS: 'Contact has been updated successfully',
  MODIFY_LEVEL_ERROR: 'Contact updation failed',
  MODIFY_LEVEL_UNAUTHORIZED: 'Unauthorized to update the level',
};

export interface IEditLevelProps {
  value: any;
  modalClose: any;
  UpdateData: any;
  setIsUpdating: any;
}
