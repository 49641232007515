import { useEffect } from "react";
import logEvent from "utils/TrimbleInsightsLogger.utils";

function ViewerPage() {
  useEffect(() => {
    logEvent('LOGIN_ATTEMPT', 'USER_ACTIVITY', {
      actions: 'Login',
      resource: 'Portal',
      error: 'Access Denied'
  });
  }, []);
  return (
    <div className="main-tab-container">
        <div className="access-page">
            <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" className="mi-solid mi-lock" viewBox="0 0 24 24">
              <path d="M16.96 8.92V7.21c0-2.61-1.91-4.94-4.51-5.19A5.003 5.003 0 0 0 6.96 7v1.92h-1c-1.1 0-2 .9-2 2v9.07c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-9.07c0-1.1-.9-2-2-2zM11.97 17c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2m2.98-8.08h-6v-1.8c0-1.45.98-2.78 2.4-3.06 1.92-.37 3.6 1.09 3.6 2.94z" />
            </svg>
            <h3 className="mt-2">You dont have access to this page.</h3>
            <h3>Please contact administrator.</h3>
        </div>
    </div>
  );
}
export default ViewerPage;