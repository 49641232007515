import {
  Navbar,
  Nav,
  Dropdown,
  Button,
  NavItem,
} from '@trimbleinc/modus-react-bootstrap';
import { TrimbleLogo } from './../../../../constants/Project.constants';
import './styles.scss';
import { logoutUser } from '../../../../services/auth.service';
import { AppNavBarProps } from './AppNavBar.constants';
import { getItemFromLocalStorage } from '../../../../utils/LocalStorage.utils';
import { useState } from 'react';
import HelpModal from './components/HelpGuide';

const AppNavBar = (props: AppNavBarProps) => {
  const [showHelpGuide, setShowHelpGuide] = useState(false);
  const handleClose = () => setShowHelpGuide(false);
  const handleShow = () => setShowHelpGuide(true);
  const { toggleMenuCollapse } = props;
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="sm"
        id="navbar"
        className="navbar navbar-blue nav navbar-expand-lg modus-header app-navbar"
      >
        <Button
          variant="text-dark"
          id="menuButton"
          size="lg"
          className="btn-icon-only  app-navbar-icon ml-2"
          data-modus-item="menu-btn"
          aria-label='menu'
          onClick={() => toggleMenuCollapse()}
        >
          <i className="modus-icons" aria-hidden="true">
            menu
          </i>
        </Button>
        <Navbar.Brand className=" mr-auto ml-2">
          <a href="/dashboard">
            <img
              src={TrimbleLogo.TrimbleLogoRev}
              width="107"
              height="25"
              className="img-fluid d-none d-sm-block"
              alt="home"
            />
            <img
              src={TrimbleLogo.TrimbleIconRev}
              className="d-block d-sm-none"
              height="25"
              width="25"
              alt="home"
            />
          </a>
        </Navbar.Brand>
        <div className="d-flex">
          <NavItem
            className={`${showHelpGuide ? 'bg-trimble-blue' : 'px-2'} nav-item-help`}
            style={{ cursor: 'pointer' }}
            id="help"
            onClick={handleShow}
          >
            <div className="d-flex mt-1 ">
              <i
                className="modus-icons"
                style={{
                  color: 'white',
                  width: '20px',
                  height: '20px',
                  position: 'relative',
                  top: '2px'
                }}
                aria-hidden="true"
              >
                help_outlined
              </i>

              <span className="px-2 text-white"
                style={{
                  width: '50px',
                  position: 'relative',
                  top: '2px'
                }}>
                Help
              </span>
            </div>
          </NavItem>

          <Dropdown className="mr-2">
            <Dropdown.Toggle
              variant="primary"
              className="navbar-blue"
              bsPrefix="border-0"
              aria-label='user-account'
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                fill="currentColor"
                className="mi-solid mi-person-account"
                viewBox="0 0 24 24"
              >
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2m0 4c2.07 0 3.75 1.68 3.75 3.75S14.07 13.5 12 13.5s-3.75-1.68-3.75-3.75S9.93 6 12 6m0 14c-2.84 0-5.34-1.5-6.76-3.74.13-.07.27-.14.43-.21 1.22-.56 3.47-1.37 6.33-1.37 2.87 0 5.12.81 6.33 1.37.16.07.3.15.43.22A7.99 7.99 0 0 1 12 20" />
              </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
              <Dropdown.ItemText>
                {JSON.parse(getItemFromLocalStorage('auth')!)?.data.firstName}{' '}
                {JSON.parse(getItemFromLocalStorage('auth')!)?.data.lastName}
              </Dropdown.ItemText>
              <Dropdown.ItemText>
                {JSON.parse(getItemFromLocalStorage('auth')!)?.email}
              </Dropdown.ItemText>
              <Dropdown.Divider></Dropdown.Divider>
              <Dropdown.Item className="logout" onClick={logoutUser}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </Navbar>
      <HelpModal show={showHelpGuide} handleClose={handleClose} />
    </>
  );
};

export default AppNavBar;
