import React from "react";
import ReactQuill from "react-quill";
import QuillToolbar, { modules, formats } from "./QuillToolbar";
import "react-quill/dist/quill.snow.css";

export const Editor = ({ id, value, onChange, variableMacroOptions, placeholder="Enter the message here" }: any) => {
  const toolbarId = `toolbar-${id}`;
  const [state, setState] = React.useState({ value: '' });
  const handleChange = (value: any) => {
    setState({ value });
  };
  const isSubject = id.includes("SubEditor");

  // If no toolbar should be shown, return an empty module for toolbar
  const editorModules = !isSubject ? modules(toolbarId) : { toolbar: false };

  return (
    <div className={`text-editor ${isSubject ? 'text-editor--sub' : ''}`}>
      {!isSubject && (
        <QuillToolbar
          toolbarId={toolbarId}
          variableMacroOptions={variableMacroOptions}
        />
      )}
      <ReactQuill
        theme="snow"
        value={value}
        id={id}
        onChange={onChange}
        placeholder={placeholder}
        modules={editorModules}
        formats={formats}
        className="text-editor__quill"
      />
    </div>
  );
};

export default Editor;