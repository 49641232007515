import {
  Button,
  Form,
  FormControl,
  InputGroup,
} from '@trimbleinc/modus-react-bootstrap';

function EmailSearchBox({ inputValue, onEmailIdChange, searchEmail }: any) {
  // Function to handle input changes
  const handleInputChange = (event: any) => {
    const { value } = event.target;

    onEmailIdChange(value);
  };

  const handleSearch = () => {
    searchEmail();
  };

  return (
    <Form className="search-box">
      <Form.Group controlId="formBasicEmail">
      <div className="input-with-icon-left">
        <InputGroup className="mb-2">
          <FormControl
            placeholder="Search Email Address"
            value={inputValue}
            onChange={handleInputChange}
          />
          <div className="input-icon">
            <i className="modus-icon material-icons">search</i>
          </div>
        
          <InputGroup.Append>
            <Button
              variant="outline-secondary"
              style={{
                borderLeft: '1px solid #e4e4e9',
                borderRight: '1px solid #e4e4e9',
                borderTop: '1px solid #e4e4e9',
                padding: '0 10px',
              }}
              onClick={() => [onEmailIdChange(''), handleSearch()]}
            >
              <i
                className="modus-icons"
                aria-hidden="true"
                style={{ fontSize: '0.9rem', color: 'black' }}
              >
                close
              </i>
            </Button>
          </InputGroup.Append>
          <InputGroup.Append>
            <Button
              variant="text-primary"
              className="mr-2"
              style={{ border: '1px #004f83 solid', marginLeft: '5px' }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputGroup.Append>
        </InputGroup>
        </div>
      </Form.Group>
    </Form>
  );
}

export default EmailSearchBox;
