import { Col, Form, Row } from '@trimbleinc/modus-react-bootstrap';
import { ALTERNATIVE_BEAM_CONSTANTS, IAlternativeBeamInputProps } from "./AlternativeBeamInput.constants";


function AlternativeBeamInput({
    onBeamChange,
    isError,
    value,
    isDisabled,
    label
}: IAlternativeBeamInputProps) {

    return (
        <Form>
            <Row className="form-row">
                <Col md={6}>
                    <h4 className="form-label" style={{ marginTop: "6px" }}>
                        {label ? label : ALTERNATIVE_BEAM_CONSTANTS.alterNativeBeamInput}
                        <span style={{ color: '#6a6e79', fontSize: '12px' }}>
                            {ALTERNATIVE_BEAM_CONSTANTS.optional}
                        </span>
                    </h4>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control
                            as="input"
                            placeholder={ALTERNATIVE_BEAM_CONSTANTS.alterNativeBeamPlaceholder}
                            className={`input-border ${isError ? 'is-invalid' : ''}`}
                            style={
                                isDisabled
                                    ? {
                                        background: '#dadce7',
                                        fontWeight: 'bolder',
                                        opacity: '1.0',
                                    }
                                    : {}
                            }
                            onChange={(e) => onBeamChange && onBeamChange(e.target.value)}
                            value={value}
                            disabled={isDisabled}
                        />
                        {isError && (
                            <Form.Control.Feedback
                                type="invalid"
                                style={{ display: 'block' }}
                            >
                                {ALTERNATIVE_BEAM_CONSTANTS.error}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
}

export default AlternativeBeamInput;
