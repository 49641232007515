import TreeNode from "./components/TreeNode/TreeNode"
import { ITreeNodeProps } from "./components/TreeNode/TreeNode.constants";

const DragTree = ({getServices, data}:ITreeNodeProps) => {

  return (
    <div>
      {data ? (
        <TreeNode getServices={getServices} data={data} />
      ) :null}
    </div>
  );
}

export default DragTree