export const LIST_SCREEN_CONSTANTS = {
    UPDATE: "Modify Role",
    ALERT_CONFIRM_TEXT:"Are you sure ?",
    ALERT_CONFIRM_EMAIL:"Do you really want to delete this user ? ",
    DELETE_ROLE_API_ERROR: "Something went wrong",
    DELETE_ROLE_SUCCESS:"User deleted successfully",
    DELETE_ROLE_ERROR:"User deletion failed",
    DELETE_ROLE_UNAUTHORIZED:"Unauthorized to delete the user",
    DELETE_CONFIRM_TEXT:"Delete",
    DELETE_CANCEL_TEXT:"Cancel",
    MODIFY_ROLE_LABEL:"Modify Role",
    DELETE_USER_LABEL:"Delete User",
    MODAL_TITLE:"Update",
    ROLE_LIST : [
        {name:"All Roles",code:''},
        {name: "Admin", code: "admin"},
        {name: "Super User", code: "superuser"},
        {name: "User",code: "user"}
    ],
}
export interface IGlobalFilterPanel {
    globalFilter: any;
    setGlobalFilter: any;
}
export interface IListScreenProps {
    addedUserEmail: string;
  }