import { useState } from "react";
import AddUser from "./components/AddUser";
import ListScreen from "./components/ListScreen";
import { ToastContainer , toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function UserManagement() {

    const [addedUserEmail, setAddedUserEmail] = useState<string>('');

    return (
        <>
            <div className="user-management-container main-tab-container">
                <AddUser setAddedUserEmail={setAddedUserEmail}/>
                <br />
                <ListScreen addedUserEmail={addedUserEmail}/>
            </div>
            <ToastContainer/>
        </>
    );
}

export default UserManagement;