export const ALTERNATIVE_BEAM_CONSTANTS = {
    alterNativeBeamInput: 'Alternative Beam Name',
    alterNativeBeamPlaceholder: 'Alternative Beam Name',
    error: 'Please enter a valid Alternative Beam Name.',
    optional: ' (Optional)'
  };
  
  export interface IAlternativeBeamInputProps {
    onBeamChange?: (id: string) => void;
    isError: boolean;
    value?: string;
    isDisabled: boolean;
    label?: string;
  };