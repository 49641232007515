export const INPUT_EMAIL_CONSTANTS = {
  input: 'Email Address',
  placeholder: 'Enter the email address',
  error: 'Please enter a valid Email Address.',
};

export interface IInputEmailProps {
  onEmailChange?: (id: string) => void;
  isError: boolean;
  value?: string;
  isDisabled: boolean;
  label?: string;
};