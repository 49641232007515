import { toast } from 'react-toastify';
import { fetchIncidentsCount } from '../../../../services/incidentManagement.service';
import { useEffect, useState } from 'react';
import { IncidentStats, STATS_CONSTANTS } from './Stats.constants';
import Loading from 'components/Common/Loading';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function Stats({ onLoadSuccess }:any) {
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchStats = async () => {
    try {
      setIsLoading(true);
      const response = await fetchIncidentsCount();

      if (response.status == 200) {
        setData(response.data);
        setIsLoading(false);
        onLoadSuccess();
      } else if (response.status == 401) {
        toast.error('Unauthorized');
        clearSessionAndRedirect();
      } else {
        toast.error('Fetch stats failed');
        logEvent('GET_STATS', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Incidents',
          error: response?.data
            ? `"${response?.data}"`
            : 'Fetch stats failed',
        });
      }
    } catch (error) {
      toast.error('Fetch stats failed');
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  return (
    <div>
      <h2>Number of Ongoing Incidents</h2>
      {isLoading ? (
        <Loading />
      ) : (
        <div
          className="d-flex flex-wrap justify-content-between"
          style={{ marginBottom: '20px' }}
        >
          {Object.keys(STATS_CONSTANTS).map((key) => (
            <div key={key} className="d-flex flex-column stats-card">
              <h3 style={{ fontSize: '20px' }}>
                {STATS_CONSTANTS[key as keyof IncidentStats]}
              </h3>

              <h4 style={{ fontSize: '30px' }}>
                {data[key as keyof IncidentStats]}
              </h4>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default Stats;
