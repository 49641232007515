export type Service = {
  children: Service[];
  displayName: string;
  emailName: string;
  isHidden: boolean;
  product: string;
  service: string;
  isMainComponent: boolean;
  statuscastId: number;
  statuscastGroupId: number;
};

export interface Node {
  nodeId: number;
  label: string;
  product: string;
  service: string;
  emailName: string;
  isHidden: boolean;
  isMainComponent: boolean;
  statuscastId: number;
  statuscastGroupId: number;
  children?: Node[];
}

export const ACCORDION_CONSTANTS = {
  input: 'Affected Service',
  error: 'Please select a service',
};

export interface IAccordionInputProps {
  onServiceSelect?: (product: string, service: string) => void;
  isError: boolean;
  value?: string;
  isDisabled: boolean;
  isSearchFilter: boolean;
}
