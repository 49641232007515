import { useEffect, useState } from 'react';
import { Col, Form, Row } from '@trimbleinc/modus-react-bootstrap';
import { details, IDropDownProps } from './DropDownInput.constants';

function DropDownInput({
  type,
  onSelect,
  isError,
  value,
  isDisabled,
}: IDropDownProps) {
  const selectedType = details[type];

  const [selectedValue, setSelectedValue] = useState<string>(
    value &&
      (type == 'INCIDENT_TYPE_RESOLVED' ||
        type == 'INCIDENT_STATE_RESOLVED' ||
        isDisabled)
      ? value
      : selectedType.disabledOptions
  );

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(event.target.value);
    if (onSelect) {
      onSelect(type, event.target.value);
    }
  };

  useEffect(() => {
    if (
      value &&
      (type === 'INCIDENT_TYPE_UPDATE' || type === 'INCIDENT_TYPE_RESOLVED')
    ) {
      if (value === 'PartialOutage') {
        value = 'Partial Outage';
      } else if (value === 'PlannedMaintenance') {
        value = 'Planned Maintenance';
      } else {
        value = 'Outage';
      }
      setSelectedValue(value);
    }
  });

  return (
    <Form>
      <Row className='form-row'>
        <Col md={6}>
          <h4 className='form-label' style={{ marginTop: '6px' }}>
            {selectedType.input}
          </h4>
        </Col>
        <Col md={6}>
          <Form.Group controlId='exampleForm.SelectCustom'>
            <Form.Control
              as='select'
              custom
              className='input-border'
              value={selectedValue}
              onChange={handleSelectChange}
              disabled={isDisabled}
              style={
                isDisabled
                  ? {
                      background: '#dadce7',
                      fontWeight: 'bold',
                      opacity: '1.0',
                    }
                  : selectedType.disabledOptions === selectedValue
                  ? {
                      color: '#adb5bd',
                    }
                  : {}
              }
            >
              <option disabled value={selectedType.disabledOptions}>
                {selectedType.disabledOptions}
              </option>
              {selectedType.options.map((option: any, index: any) => (
                <option
                  key={index}
                  value={option}
                  style={{ color: '#6a6e79' }}
                >
                  {option}
                </option>
              ))}
            </Form.Control>
            {isError && (
              <Form.Control.Feedback
                type='invalid'
                style={{ display: 'block' }}
              >
                {selectedType.error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

export default DropDownInput;
