import {
  Button,
  Card,
  DataTable,
  Modal,
} from '@trimbleinc/modus-react-bootstrap';
import EmailSearchBox from 'components/Common/EmailSearchBox';
import PaginationButton from 'components/Common/PaginationButton';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  IListExternalContactsProps,
  LIST_EXTERNAL_CONTACTS_CONSTANTS,
} from './ListExternalContactsConstants';
import ServiceFilter from './components/ServiceFilter';
import Loading from 'components/Common/Loading';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { getItemFromLocalStorage } from 'utils/LocalStorage.utils';
import {
  deleteExternalContact,
  fetchExternalContact,
} from 'services/groupManagement.service';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import { isNotAdmin } from 'utils/RoleCheck.utils';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';
import { Selected } from './components/ServiceFilter/component/MultipleSearchTree/MultipleSearchTree.constants';
import Environment from '../../../../../../../../../config';

const PreviewServices = ({ value }: any) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  return (
    <div className='button-group'>
      <div className='button-text-container' style={{ marginLeft: '-10px' }}>
        <Button variant='text-primary' size='sm' onClick={handleShow}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='gray'
            className='mi-solid mi-preview'
            viewBox='0 0 24 24'
          >
            <path d='M9 4h12v4H9ZM3 4h4v4H3Zm0 12h12v4H3Zm14 0h4v4h-4ZM3 10h8v4H3Zm10 0h8v4h-8Z' />
          </svg>
          <span className='button-text'>
            {LIST_EXTERNAL_CONTACTS_CONSTANTS.VIEW_SERVICES}
          </span>
        </Button>
      </div>
      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          placeholder=''
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          closeButton
        >
          <Modal.Title>
            {LIST_EXTERNAL_CONTACTS_CONSTANTS.VIEW_SERVICES_LABEL}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className='scroll-list-services'>
          <ol>
            {value.original.services.serviceNames.map((service: any) => (
              <li>{service}</li>
            ))}
          </ol>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const Action = ({
  value,
  setIsUpdating,
  currentPage,
  emailId,
  selectedServiceId,
  fetchExtContacts,
  services,
  requestId,
}: any) => {
  const navigate = useNavigate();
  const initiateDelete = () => {
    //delete function
    let response: any = null;
    Swal.fire({
      title: `<h4>${LIST_EXTERNAL_CONTACTS_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'error',
      html: `<p>${LIST_EXTERNAL_CONTACTS_CONSTANTS.ALERT_CONFIRM_EMAIL}<br/>
              ${value.original.emailAddress}<p>`,
      showCancelButton: true,
      confirmButtonText: LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_CONFIRM_TEXT,
      cancelButtonText: LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_CANCEL_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          setIsUpdating(true);
          response = await deleteExternalContact(value.original.emailAddress);
        } catch (error) {
          toast.error(LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_EXT_API_ERROR);
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (response && response.status == 200) {
          fetchExtContacts(requestId, currentPage, selectedServiceId, emailId);
          toast.success(
            response?.data ??
              LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_EXT_SUCCESS
          );
          logEvent('DELETE_CONTACT', 'USER_ACTIVITY', {
            actions: 'Delete',
            resource: 'External_Contacts',
          });
        }
        if (response.status == 400 || response.status == 500) {
          toast.error(
            response.data ?? LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_EXT_ERROR
          );
          logEvent('DELETE_CONTACT', 'EXCEPTION', {
            actions: 'Delete',
            resource: 'External_Contacts',
            error: response?.data
              ? `"${response?.data}"`
              : LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_EXT_ERROR,
          });
        }
        if (response.status == 401) {
          toast.error(LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_EXT_UNAUTHORIZED);
        }
      } else if (result.isDismissed) {
        navigate(RouterPath.PRIVATE_ROUTE.contactManagement);
      }
    });
  };

  const handleUpdate = () => {
    navigate(
      `${RouterPath.PRIVATE_ROUTE.contactManagement}/` +
        value.original.services.statusCastSubscriberId,
      {
        state: {
          data: value.original.services,
          allServices: services,
        },
      }
    );
  };

  const isDisabled =
    JSON.parse(getItemFromLocalStorage('auth')!).email !==
    value.original.emailAddress;
  return (
    <>
      <div className='button-group'>
        <div className='button-text-container' style={{ marginLeft: '-10px' }}>
          <Button
            variant='text-primary'
            size='sm'
            onClick={handleUpdate}
            disabled={!isDisabled || isNotAdmin()}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className='mi-outline mi-pencil'
              viewBox='0 0 24 24'
            >
              <path d='M3.1 17.15c-.1.1-.15.22-.15.36v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.76 9.99l-3.75-3.75L3.1 17.15Zm11.25-6.8-8.5 8.5a.485.485 0 0 1-.7 0c-.2-.2-.2-.51 0-.71l8.5-8.5c.2-.2.51-.2.71 0s.2.51 0 .71Zm6.31-4.67-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41Z' />
            </svg>
            <span className='button-text'>
              {LIST_EXTERNAL_CONTACTS_CONSTANTS.MODIFY_EXT_LABEL}
            </span>
          </Button>
        </div>

        <div className='button-text-container'>
          <Button
            variant='text-primary'
            size='sm'
            onClick={initiateDelete}
            disabled={!isDisabled || isNotAdmin()}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className='mi-outline mi-delete'
              viewBox='0 0 24 24'
            >
              <path d='M13.5 10c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-3 0c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-4.49 8.99c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12Zm2-10h8v10h-8v-10Zm9.99-5h-2.08c-.27 0-.52-.11-.71-.29l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1Z' />
            </svg>
            <span className='button-text'>
              {LIST_EXTERNAL_CONTACTS_CONSTANTS.DELETE_USER_LABEL}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

function ListExternalContacts({
  services,
  setServiceIds,
}: IListExternalContactsProps) {
  const [emailId, setEmailId] = useState('');
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoaded, setIsLoaded] = useState(true);
  const [isSearchEmail, setIsSearchEmail] = useState(false);
  const [selectedServiceId, setSelectedServiceId] = useState<string>('0');
  const [isUpdating, setIsUpdating] = useState(false);
  const [noEmailFound, setNoEmailFound] = useState(false);
  const [externalnDataList, setExternalnDataList] = useState<any[]>([]);
  const [requestIdNumber, setRequestIdNumber] = useState(0);

  const latestRequestId = useRef(0);
  const [subscribersCount, setSubscribersCount] = useState(0);

  const onEmailIdChange = (newEmailId: any) => {
    setEmailId(() => newEmailId);
  };

  const handleNext = () => {
    setIsLoaded(false);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setIsLoaded(false);
      setCurrentPage(currentPage - 1);
    }
  };

  const searchEmail = () => {
    setCurrentPage(0);
    setIsSearchEmail(!isSearchEmail);
  };

  const handleServiceSelect = (serviceId: Selected[]) => {
    const selectedServiceIdParam = serviceId.map(
      (service: Selected) => service.selectedServiceId
    );

    const convertedServiceId = selectedServiceIdParam;

    const selectedServiceIdQueryParam = selectedServiceIdParam.join(',');

    if (selectedServiceIdQueryParam !== selectedServiceId) {
      setIsLoaded(false);
    }

    setSelectedServiceId(selectedServiceIdQueryParam);
    setServiceIds(convertedServiceId);
  };

  const convertDataToTableFormat = (data: any) => {
    return data.map((user: any) => {
      return {
        emailAddress: user.email,
        services: user,
      };
    });
  };

  const fetchExtContacts = async (
    requestId: any,
    page: number,
    statuscastIdQuery: string,
    email: string
  ) => {
    try {
      setIsLoaded(false);
      let usersResponse = await fetchExternalContact(
        5,
        page,
        statuscastIdQuery,
        email
      );
      if (usersResponse.status == 200) {
        if (requestId == latestRequestId.current) {
          setIsLoaded(true);
          setSubscribersCount(usersResponse.data.subscribersCount);
          if (
            usersResponse.data.subscribers &&
            usersResponse.data.subscribers.length > 0
          ) {
            setNoEmailFound(false);

            setExternalnDataList(
              convertDataToTableFormat(usersResponse.data.subscribers)
            );
            setHasNext(usersResponse.data.hasNextPage);
          } else {
            setCurrentPage(0);
            setHasNext(false);
            setNoEmailFound(true);
            setExternalnDataList([]);
          }
          setIsUpdating(false);
        }
      }
      if (usersResponse.status == 400 || usersResponse.status == 500) {
        toast.error(usersResponse?.data ?? 'Failed to fetch external contacts');
        logEvent('GET_CONTACT', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'External_Contacts',
          error: usersResponse?.data
            ? `"${usersResponse?.data}"`
            : 'Failed to fetch external contacts',
        });
      }
      if (usersResponse.status == 401) {
        toast.error('Unauthorized to access users');
        clearSessionAndRedirect();
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Email Address',
        accessor: 'emailAddress',
        width: 300,
      },
      {
        Header: 'Subscribed Services',
        accessor: 'services',
        width: 200,
        Cell: ({ row }: any) => <PreviewServices value={row} />,
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: 400,
        Cell: ({ row }: any) => (
          <Action
            value={row}
            setIsUpdating={setIsUpdating}
            currentPage={currentPage}
            emailId={emailId}
            selectedServiceId={selectedServiceId}
            fetchExtContacts={fetchExtContacts}
            services={services}
            requestId={requestIdNumber}
          />
        ),
      },
    ],
    [
      setIsUpdating,
      currentPage,
      emailId,
      selectedServiceId,
      fetchExtContacts,
      services,
      requestIdNumber,
    ] // Add dependencies here
  );

  useEffect(() => {
    const requestId = ++latestRequestId.current;
    setRequestIdNumber(requestId);
    fetchExtContacts(requestId, currentPage, selectedServiceId, emailId);
  }, [currentPage, selectedServiceId, isSearchEmail]);

  return (
    <>
      <Card className='shadow'>
        <div>
          <div className='grid-container-ext'>
            <div className='search-box-email grid-items-esc'>
              <EmailSearchBox
                inputValue={emailId}
                onEmailIdChange={onEmailIdChange}
                searchEmail={searchEmail}
              />
            </div>
            <div className='grid-items-ext'>
              <ServiceFilter
                onServiceSelect={handleServiceSelect}
                services={services}
              />
            </div>
            <div className='grid-items-ext'>
              <PaginationButton
                currentPage={currentPage}
                hasNext={hasNext}
                onPrevious={handlePrevious}
                onNext={handleNext}
                isContacts={true}
              />
            </div>
            <div className='grid-items-ext'>
              <h4
                style={{
                  marginLeft: '5px',
                  marginTop: '7px',
                  whiteSpace: 'nowrap',
                }}
              >
                Count - {subscribersCount}
              </h4>
            </div>
          </div>
          {!isLoaded || isUpdating ? (
            <Loading />
          ) : noEmailFound ? (
            <div>
              <h5>
                <br></br>No Contacts Found!
              </h5>
            </div>
          ) : (
            <div className='fixed-header-datatable'>
              <DataTable
                id='escalation_contacts'
                columns={columns}
                hover
                data={externalnDataList}
                style={{ maxHeight: '400px' }}
                disablePagination
                resizeColumns
              ></DataTable>
            </div>
          )}
        </div>
      </Card>
      <p style={{ marginTop: '10px' }}>
        <strong>Note:</strong> To modify your service subscription, visit the{' '}
        <a href={Environment.statusSignupPageUrl} target='_blank'>
          Status Signup Page
        </a>
      </p>
    </>
  );
}

export default ListExternalContacts;
