export const CREATE_FORM_CONSTANTS = {
  ALERT_CONFIRM_TEXT: 'Are you sure ?',
  ALERT_CONFIRM_CLOSE: 'Do you really want to discard creation? ',
  CONFIRM_CLOSE_CANCEL_TEXT: 'Yes',
  CONFIRM_CLOSE_DISCARD_TEXT: 'No',
  NAME: {
    LABEL: 'Name',
    KEY: 'name',
    ERROR_TEXT: 'Please enter the service name',
    PLACEHOLDER: 'Enter the service name',
  },
  EMAIL_NAME: {
    LABEL: 'Email Name',
    KEY: 'emailName',
    ERROR_TEXT: 'Please enter the email name',
    PLACEHOLDER: 'Enter the email name',
  },
  DESCRIPTION: {
    LABEL: 'Description',
    KEY: 'description',
    ERROR_TEXT: 'Please enter the description',
    PLACEHOLDER: 'Enter the description',
  },
  PRODUCT_NAME: {
    LABEL: 'Product Trigger',
    KEY: 'product',
    ERROR_TEXT: 'Please enter the product trigger',
    PLACEHOLDER: 'Enter the 4me product trigger',
  },
  SERVICE_NAME: {
    LABEL: 'Service Trigger',
    KEY: 'service',
    ERROR_TEXT: 'Please enter the service trigger',
    PLACEHOLDER: 'Enter the 4me service trigger',
  },
  IS_HIDDEN: {
    LABEL: 'Is Hidden',
    KEY: 'isHidden',
    ERROR_TEXT: 'Please select the hidden status',
    TOOLTIP: 'The hidden services are not displayed in the status page',
  },
  LANGUAGE: {
    LABEL: 'Language',
    KEY: 'language',
    ERROR_TEXT: 'Please enter the language',
    DROP_DOWN_DEFAULT: '-Select language-',
    ADD_LANGUAGE_BUTTON: 'Configure template',
    PLACEHOLDER: 'Enter the language',
  },
  PEER_PRIORITY: {
    LABEL: 'Peer Priority',
    KEY: 'peerPriority',
    ERROR_TEXT: 'Please enter the peer priority',
    TOOLTIP: 'The order in which the services are displayed in the status page',
    PLACEHOLDER: 'Enter the peer priority',
  },
  IS_MAIN_COMPONENT: {
    LABEL: 'Is Main Parent',
    KEY: 'isMainComponent',
    ERROR_TEXT: 'Please confirm whether its a main parent service',
  },
  MAIN_COMPONENT: {
    LABEL: 'Main Parent Name',
    KEY: 'mainComponentName',
    ERROR_TEXT: 'Please select the main parent service',
    DROP_DOWN_DEFAULT: '-Select main parent service-',
  },
  LANGUAGE_LIST: [
    { name: 'English', code: 'en' },
    { name: 'German', code: 'de' },
    { name: 'French', code: 'fr' },
    { name: 'Italian', code: '	it' },
  ],
  COUNT_VALIDATION: {
    ERROR_TEXT: 'The value must be < 100',
    DECIMAL_TEXT: 'Please enter a number with 2 decimal places',
    VALUE: 99,
    WHOLE_NUMBER_TEXT: 'Please enter a non-decimal value greater than 0',
  },
  DELETE_LANGUAGE: {
    LABEL: 'Delete Language',
    KEY: 'isDeletedLanguage',
  },
};
