export const END_TIME_CONSTANTS = {
  input: 'End Time in UTC',
  label: 'End time in UTC',
  error: 'End time is required',
  endtimeLessError: 'End time should be greater than start time',
};

export interface IEndDateTimeInputProps {
  onDateTimeChange?: (value: string | null) => void;
  isError: boolean;
  isEndTimeLessError: boolean;
  value?: string | null | undefined;
  isDisabled: boolean;
}