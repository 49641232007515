import React, { useEffect, useState } from 'react';
import {
  IMultipleSearchTreeProps,
  Selected,
  Node,
} from './MultipleSearchTree.constants';
import { Accordion, Card, Col, Row } from '@trimbleinc/modus-react-bootstrap';
import ServiceChips from '../../../ExternalContactUtility/components/ServiceChips';
import TreeNode from '../../../ExternalContactUtility/components/TreeNode';

function MultipleSearchTree({
  data,
  handleNodeSelect,
  statuscastServiceId,
  handleSelectedServices,
}: IMultipleSearchTreeProps) {
  const getSelectedLabels = (nodes: Node[]): Selected[] => {
    const selectedLabels: Selected[] = [];

    const traverseNodes = (nodesToTraverse: Node[]) => {
      for (const node of nodesToTraverse) {
        if (node.isSelected) {
          selectedLabels.push({ selectedServiceId: node.statuscastId });
        }
        if (node.children) {
          traverseNodes(node.children);
        }
      }
    };

    traverseNodes(nodes);

    return selectedLabels;
  };

  const resetSelectedState = (nodes: Node[]): void => {
    for (const node of nodes) {
      if (node.isSelected) {
        updateNodeCheckbox(data, node.nodeId, false);
      }
      if (node.children) {
        resetSelectedState(node.children);
      }
    }
  };

  const handleCheckboxChange = (nodeId: number, checked: boolean) => {
    if (data) {
      let updatedData = updateNodeCheckbox(data, nodeId, checked);
      if (nodeId === 0) {
        resetSelectedState(data);
        updatedData = updateNodeCheckbox(data, nodeId, true);
      } else {
        updatedData = updateNodeCheckbox(data, 0, false);
      }

      let selectedLabels = getSelectedLabels(updatedData);
      if (selectedLabels.length === 0) {
        updatedData = updateNodeCheckbox(data, 0, true);
        selectedLabels = getSelectedLabels(updatedData);
      }
      handleSelectedServices(selectedLabels);
      handleNodeSelect(updatedData);
    }
  };

  function updateNodeCheckbox(
    nodes: Node[],
    nodeId: number,
    checked: boolean
  ): Node[] {
    const updatedNodes = nodes.map((node) => {
      if (node.nodeId === nodeId) {
        node.isSelected = checked;
        if (node.children) {
          node.children = updateChildrenCheckbox(node.children, checked);
          if (!node.isMainComponent) {
            updateIntermediateState(node);
          }
        }
      } else if (node.children) {
        node.children = updateNodeCheckbox(node.children, nodeId, checked);
        if (!node.isMainComponent) {
          updateIntermediateState(node);
        }
      }
      return node;
    });

    return updatedNodes;
  }

  function updateChildrenCheckbox(children: Node[], checked: boolean): Node[] {
    return children.map((child) => {
      child.isSelected = checked;
      child.isDisabled = checked;
      if (checked) {
        child.isIndeterminate = !checked;
      }
      if (child.children) {
        child.children = updateChildrenCheckbox(child.children, checked);
      }
      return child;
    });
  }

  function updateIntermediateState(node: Node) {
    if (!node.children) {
      return;
    }

    let selectedCount = 0;
    let indeterminateCount = 0;

    for (const child of node.children) {
      if (child.isSelected) {
        selectedCount++;
      } else if (child.isIndeterminate) {
        indeterminateCount++;
      }
    }

    if (selectedCount === node.children.length) {
      node.isIndeterminate = false;
      node.isSelected = true;
      for (const child of node.children) {
        child.isDisabled = true;
      }
    } else if (selectedCount > 0 || indeterminateCount > 0) {
      node.isIndeterminate = true;
      node.isSelected = false;
    } else {
      node.isIndeterminate = false;
      node.isSelected = false;
    }
  }

  const findNode = (node: Node[], id: number): Node | null => {
    let foundNode: Node | null = null;
    node.forEach((n) => {
      if (n.nodeId === id) {
        foundNode = n;
      } else if (n.children) {
        foundNode = findNode(n.children, id);
      }
    });
    return foundNode;
  };

  const findNodeByID = (id: number, nodes: Node[]): Node | undefined => {
    for (const node of nodes) {
      if (node.statuscastId === id) {
        return node;
      } else if (node.children) {
        const foundNode = findNodeByID(id, node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (data && statuscastServiceId && statuscastServiceId.length > 0) {
      statuscastServiceId.forEach((id) => {
        const nodeToCheck = findNodeByID(id, data);
        if (nodeToCheck) {
          handleCheckboxChange(nodeToCheck.nodeId, true);
        }
      });
    }
  }, []);

  return (
    <>
      {data.map((node) => (
        <TreeNode
          key={node.nodeId}
          node={node}
          onCheckboxChange={handleCheckboxChange}
        />
      ))}
    </>
  );
}

export default MultipleSearchTree;
