import { Col, Form, Row } from "@trimbleinc/modus-react-bootstrap";
import { IInputEmailProps, INPUT_EMAIL_CONSTANTS } from "./InputEmailConstants";
function InputEmail({
  onEmailChange,
  isError,
  value,
  isDisabled,
  label,
}: IInputEmailProps) {
  return (
    <Form>
      <Row className="form-row">
        <Col md={6}>
          <h4 className="form-label" style={{ marginTop: '6px' }}>
            {label ? label : INPUT_EMAIL_CONSTANTS.input}
          </h4>
        </Col>
        <Col md={6}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control
              as="input"
              placeholder={INPUT_EMAIL_CONSTANTS.placeholder}
              className={`input-border ${isError ? 'is-invalid' : ''}`}
              style={
                isDisabled
                  ? {
                      background: '#dadce7',
                      fontWeight: 'bolder',
                      opacity: '1.0',
                    }
                  : {}
              }
              onChange={(e) => onEmailChange && onEmailChange(e.target.value)}
              value={value}
              disabled={isDisabled}
            />
            {isError && (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: 'block' }}
              >
                {INPUT_EMAIL_CONSTANTS.error}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
}

export default InputEmail;
