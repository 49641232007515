import { Button, Col, Form } from "@trimbleinc/modus-react-bootstrap";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getConfig, modifyConfig } from "services/configuration.service";
import { clearSessionAndRedirect } from "utils/Error.utils";
import logEvent from "utils/TrimbleInsightsLogger.utils";
import { ESCALATION_CONFIG_CONSTANTS } from "./EscalationConfiguration.constants";
import Loading from "components/Common/Loading";

function EscalationConfiguration() {
    const { register, handleSubmit, formState, formState: { errors }, watch } = useForm({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [escalationConfiguration, setEscalationConfiguration] = useState<any>();
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const fetchEscalationConfiguration = async () => {
        try {
            setIsLoaded(false);
            let escConfig = await getConfig('escalationTimer');
            setIsLoaded(true);
            if (escConfig.status == 200) {
                const { escalationLevel1Timer, escalationLevel2Timer } = escConfig.data[0];
                setEscalationConfiguration({ escalationLevel1Timer, escalationLevel2Timer });
            }
            if (escConfig.status == 400 || escConfig.status == 500) {
                toast.error(escConfig?.data ?? "Failed to fetch esc config")
                logEvent('GET_ESC_CONFIG', 'EXCEPTION', {
                    actions: 'Retrieve',
                    resource: 'Config',
                    error: escConfig?.data ? `"${escConfig?.data}"` : 'Failed to fetch esc config'
                });
            }
            if (escConfig.status == 401) {
                toast.error("Unauthorized to access users")
                clearSessionAndRedirect()
            }
        }
        catch (error) {
            toast.error("Something went wrong")
        }
    };

    const onSubmit = async (data: any) => {
        try {
            let notificationDurations = {} as any;
            if (data.escalationLevel1Timer !== escalationConfiguration.escalationLevel1Timer) {
                notificationDurations.escalationLevel1NotificationDuration = data.escalationLevel1Timer;
            }
            if (data.escalationLevel2Timer !== escalationConfiguration.escalationLevel2Timer) {
                notificationDurations.escalationLevel2NotificationDuration = data.escalationLevel2Timer;
            }
            setIsLoaded(false);
            let updateResponse = await modifyConfig('escalationTimer', notificationDurations);
            setIsLoaded(true);
            if (updateResponse.status === 200) {
                toast.success(updateResponse?.message ?? ESCALATION_CONFIG_CONSTANTS.SUCCESS_MESSAGE);
                fetchEscalationConfiguration();
                logEvent('MODIFY_ESC_CONFIG', 'USER_ACTIVITY', {
                    actions: 'Update',
                    resource: 'Config'
                });
            }
            if (updateResponse.status == 409 || updateResponse.status == 400 || updateResponse.status == 500) {
                toast.error(updateResponse?.data ?? ESCALATION_CONFIG_CONSTANTS.FAILURE_MESSAGE);
                logEvent('MODIFY_ESC_CONFIG', 'EXCEPTION', {
                    actions: 'Update',
                    resource: 'Config',
                    error: updateResponse?.data ? `"${updateResponse?.data}"` : ESCALATION_CONFIG_CONSTANTS.FAILURE_MESSAGE
                });
            }
            if (updateResponse.status == 401) {
                toast.error(updateResponse?.data ?? "Unauthorized to access configurations")
                clearSessionAndRedirect()
            }

        } catch (error) {
            toast.error("Something went wrong");
        }
    };

    const watchedValues = watch();
    useEffect(() => {
        if (escalationConfiguration) {
            const hasChanges = JSON.stringify(watchedValues) !== JSON.stringify(escalationConfiguration);
            setIsSaveButtonEnabled(hasChanges); // Enable Save button if form values are different from initial values
        }
    }, [watchedValues, escalationConfiguration]);

    useEffect(() => {
        if (Object.keys(formState.errors)?.length == 0) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formState])

    useEffect(() => {
        fetchEscalationConfiguration();
    }, []);

    return (
        <>
            <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '10px' }}>
                {!isLoaded ?
                    (<div className="loading-overlay">
                        <Loading /></div>) :
                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Form.Row className="form-row">
                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {ESCALATION_CONFIG_CONSTANTS.ESC1.LABEL}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    aria-label="esc1"
                                    min="0"
                                    defaultValue={escalationConfiguration.escalationLevel1Timer}
                                    {...register(ESCALATION_CONFIG_CONSTANTS.ESC1.KEY, {
                                        required: { value: true, message: ESCALATION_CONFIG_CONSTANTS.ESC1.ERROR_TEXT },
                                        valueAsNumber: true,
                                        validate: value => value >= 0 || "Please enter a positive value",
                                    })}
                                ></Form.Control>
                                {errors?.[ESCALATION_CONFIG_CONSTANTS.ESC1.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[ESCALATION_CONFIG_CONSTANTS.ESC1.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {ESCALATION_CONFIG_CONSTANTS.ESC2.LABEL}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    aria-label="esc1"
                                    min="0"
                                    defaultValue={escalationConfiguration.escalationLevel2Timer}
                                    {...register(ESCALATION_CONFIG_CONSTANTS.ESC2.KEY, {
                                        required: { value: true, message: ESCALATION_CONFIG_CONSTANTS.ESC2.ERROR_TEXT },
                                        valueAsNumber: true,
                                        validate: value => value >= 0 || "Please enter a positive value",
                                    })}
                                ></Form.Control>
                                {errors?.[ESCALATION_CONFIG_CONSTANTS.ESC2.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[ESCALATION_CONFIG_CONSTANTS.ESC2.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Form.Row>
                        <Button
                            variant="primary"
                            className="mr-2 float-right-on-desktop save-button"
                            type="submit"
                            disabled={!isSaveButtonEnabled || !isFormValid}
                        >
                            Save
                        </Button>
                    </Form>
                }
            </div >
        </>
    );
}
export default EscalationConfiguration;