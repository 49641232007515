import SHA256 from 'crypto-js/sha256';
import encBase64 from 'crypto-js/enc-base64';
import { setItemInLocalStorage } from './LocalStorage.utils';
import { PROJECT_CONSTANTS } from '../constants/Project.constants';
import { generate } from 'randomstring';

export const generateCodeVerifier = () => {
  let codeVerifier = generate({
    length: getLength(),
    charset: PROJECT_CONSTANTS.CODE_VERIFIER_CHARSET,
  });
  setItemInLocalStorage('codeVerifier', codeVerifier);
  return codeVerifier;
};

export const getCodeChallenger = (codeVerifier: string) => {
  const hash = SHA256(codeVerifier);
  const codeChallenger = encBase64
    .stringify(hash)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');
  return codeChallenger;
};

const getLength = () => {
  return Math.floor(Math.random() * (128 - 43)) + 43;
};

export function getAuth() {
  const val: any = localStorage.getItem('auth');
  if (val) {
    return JSON.parse(val);
  } else {
    return null;
  }
}

export const currentTime = () => {
  return Math.floor(Date.now() / 1000);
};
