import { Spinner } from '@trimbleinc/modus-react-bootstrap';

const Loading = () => {
  return (
    <div className="px-3 loading-component">
      <div className="d-flex justify-content-center">
        <Spinner
          className="mt-02"
          animation="border"
          variant="primary"
          role="status"
          size="sm"
        />
        <div className="h3" style={{ marginLeft: '5px' }}>
          Loading...
        </div>
      </div>
    </div>
  );
};
export default Loading;
