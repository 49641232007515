export const ADD_USER_CONSTANTS = {
    EMAIL: {
        label: 'Email',
        key: 'userEmailId',
        validationMessage: 'Please enter a valid trimble email address',
        error_length: 'Email should not exceed 250 characters',
        placeholder: 'Enter the email address',
    },
    ROLE: {
        label: 'Role',
        key: 'role',
        drop_down_default : "-Select a role-",
        validationMessage: 'Please select a role',
    },
    ROLE_LIST : [
        {name: "Admin", code: "admin"},
        {name: "Super User", code: "superuser"},
        {name: "User",code: "user"}
    ],
    ADD_USER:{
        label: 'Add User',
    }
}
export interface IAddUserProps {
    setAddedUserEmail : React.Dispatch<React.SetStateAction<string>>;
}