import { getItemFromLocalStorage } from "utils/LocalStorage.utils";
import Environment from "./../../../config";
declare var trimbleInsightsLogger: any;

function InitLogger() {
    trimbleInsightsLogger.init({
        headers: {
            domoDatasetId: Environment.domoDatasetId,
            clientId: Environment.domoClientId,
            clientSecret: Environment.domoClientSecret,
        },
        interval: 1000,
        trackEvents: {},
        isNpsEnabled: false,
        consoleLogLevel: 'warn',
        datasetProperties: {
            emailID: 'string',
            incidentID: 'string',
            service: 'string',
            product: 'string',
            updatedName: 'string',
            updatedDisplayName: 'string',
            role: 'string',
            level: 'string',
            displayNames: 'string',
            contactEmail: 'string',
            updatedProduct: 'string',
            updatedService: 'string',
            updatedPeerPriority: 'string',
            actions: 'string',
            resource: 'string',
            error: 'string',
            updatedIsHidden: 'string',
            incidentType: 'string',
            templateLanguage: 'string',
            deletedLanguages: 'string',
            newOutage: 'string',
            outageFirstUpdate: 'string',
            outageSecondUpdate: 'string',
            resolvedOutage: 'string',
            newPlannedMaintenance: 'string',
            plannedMaintenanceUpdate: 'string',
            resolvedPlannedMaintenance: 'string',
            newPartialOutage: 'string',
            partialOutageFirstUpdate: 'string',
            partialOutageSecondUpdate: 'string',
            resolvedPartialOutage: 'string',
            transitionToOutage: 'string',
            transitionToPartialOutage: 'string',
            outageFirstEscalation: 'string',
            outageSecondEscalation: 'string',
            partialOutageFirstEscalation: 'string',
            partialOutageSecondEscalation: 'string',
            falseAlarm: 'string',
          },
    });
    trimbleInsightsLogger.setGlobalAttributes({
        emailID: JSON.parse(getItemFromLocalStorage('auth')!)?.email,
    });
    return null;
}

export default InitLogger;