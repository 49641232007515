import {
  Button,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from '@trimbleinc/modus-react-bootstrap';
import { useState } from 'react';
import './styles.scss';
import { useNavigate } from 'react-router-dom';
import Loading from '../Loading';
import { isNotAdmin, isAdmin } from 'utils/RoleCheck.utils';
import RouterPath from 'config/routerPath';

export const TreeLayout = ({
  item,
  title,
  arrow,
  context,
  children,
  getServices,
  data,
  handleDelete,
  handleRestore,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleEditClick = (item: any) => {
    navigate(
      `${RouterPath.PRIVATE_ROUTE.serviceManagement}/` + item.statuscastId,
      { state: { item } }
    );
  };

  const handleCreateService = (item: any) => {
    navigate(RouterPath.PRIVATE_ROUTE.createService, { state: { item, data } });
  };

  const InteractiveComponent = context.isRenaming ? 'div' : 'button';

  return (
    <>
      {isLoading && (
        <div className='overlay'>
          <Loading />
        </div>
      )}
      <div className='tree-card-node'>
        <li {...context.itemContainerWithChildrenProps} className='listItem'>
          <div className='node-component'>
            <InteractiveComponent
              {...context.itemContainerWithoutChildrenProps}
              {...context.interactiveElementProps}
              type='button'
              draggable={
                isAdmin() &&
                (item.isDeletion == undefined || item.isDeletion === 'Stopped')
              }
              disabled={
                item.isDeletion === 'Waiting' ||
                item.isDeletion === 'InProgress' ||
                item.isDeletion === 'Failed'
              }
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                background: 'none',
                border: 'none',
                textAlign: 'left',
                cursor: 'pointer',
                padding: '8px',
                fontSize: '15px',
              }}
              className='interactive-component'
            >
              {arrow}
              <span style={{ marginLeft: '15px' }}>{title}</span>
            </InteractiveComponent>
            <div className='button-container'>
              <Button
                variant='text-secondary'
                size='sm'
                onClick={() => handleCreateService(item)}
                disabled={
                  isNotAdmin() ||
                  item.isDeletion === 'Waiting' ||
                  item.isDeletion === 'InProgress' ||
                  item.isDeletion === 'Failed'
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  fill='currentColor'
                  className='mi-outline mi-add'
                  viewBox='0 0 24 24'
                >
                  <path d='M19 11h-6V5c0-.55-.45-1-1-1s-1 .45-1 1v6H5c-.55 0-1 .45-1 1s.45 1 1 1h6v6c0 .55.45 1 1 1s1-.45 1-1v-6h6c.55 0 1-.45 1-1s-.45-1-1-1' />
                </svg>
              </Button>
              <Button
                variant='text-secondary'
                size='sm'
                onClick={() => handleEditClick(item)}
                disabled={
                  isNotAdmin() ||
                  item.isDeletion === 'Waiting' ||
                  item.isDeletion === 'InProgress' ||
                  item.isDeletion === 'Failed'
                }
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  fill='currentColor'
                  className='mi-outline mi-pencil'
                  viewBox='0 0 24 24'
                >
                  <path d='M3.1 17.15c-.1.1-.15.22-.15.36v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.76 9.99l-3.75-3.75L3.1 17.15Zm11.25-6.8-8.5 8.5a.485.485 0 0 1-.7 0c-.2-.2-.2-.51 0-.71l8.5-8.5c.2-.2.51-.2.71 0s.2.51 0 .71Zm6.31-4.67-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41Z' />
                </svg>
              </Button>

              {item.isDeletion === 'Waiting' ? (
                <OverlayTrigger
                  key='overlay1'
                  placement='top'
                  overlay={<Tooltip id='btntooltip'>Restore Service</Tooltip>}
                >
                  <Button
                    variant='text-primary'
                    size='sm'
                    onClick={() => handleRestore(item)}
                    disabled={isNotAdmin()}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      fill='currentColor'
                      className='mi-solid mi-time-slot-reserved'
                      viewBox='0 0 24 24'
                    >
                      <path d='M18.39 19.09c-.19 0-.38-.07-.53-.22a.745.745 0 0 1 0-1.06 8.2 8.2 0 0 0 2.4-5.81c0-4.55-3.7-8.25-8.25-8.25-.41 0-.75-.34-.75-.75s.34-.75.75-.75c5.38 0 9.75 4.37 9.75 9.75 0 2.59-1.01 5.03-2.83 6.87-.15.15-.34.22-.53.22ZM18.5 12a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0m-3.97 1.47-1.78-1.78V8c0-.41-.34-.75-.75-.75s-.75.34-.75.75v4.31l2.22 2.22c.15.15.34.22.53.22s.38-.07.53-.22c.29-.29.29-.77 0-1.06' />
                    </svg>
                  </Button>
                </OverlayTrigger>
              ) : item.isDeletion === 'InProgress' ? (
                <Spinner
                  animation='border'
                  role='status'
                  variant='primary'
                  size='sm'
                  style={{ marginRight: '12px', marginLeft: '14px', marginBottom: '-3px' }}
                >
                  <span className='sr-only'>Loading...</span>
                </Spinner>
              ) : item.isDeletion === 'Failed' ? (
                <OverlayTrigger
                  key='overlay1'
                  placement='top'
                  overlay={<Tooltip id='btntooltip'>Deletion Failed</Tooltip>}
                >
                  <Button variant='text-primary' size='sm'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      fill='orange'
                      className='mi-outline mi-alert'
                      viewBox='0 0 24 24'
                    >
                      <path d='M12 15.02c.36 0 .62-.26.66-.7l.39-5.04s.01-.1.01-.14c0-.63-.45-1.15-1.06-1.15s-1.06.51-1.06 1.15v.14l.4 5.04c.04.44.28.7.66.7m10.59 2.6L14.6 3.61c-.54-.95-1.51-1.51-2.6-1.51s-2.06.57-2.6 1.51L1.41 17.62c-.54.94-.53 2.06.01 2.99s1.51 1.49 2.59 1.49h15.97c1.08 0 2.05-.56 2.59-1.49s.55-2.05.01-2.99Zm-1.74 1.99c-.09.15-.34.5-.86.5H4.01c-.52 0-.78-.35-.86-.5a.97.97 0 0 1 0-1L11.14 4.6c.26-.45.69-.5.87-.5s.61.05.87.5l7.99 14.01a1 1 0 0 1 0 1ZM12 16.64c-.67 0-1.21.54-1.21 1.19s.54 1.21 1.21 1.21 1.21-.54 1.21-1.21-.54-1.19-1.21-1.19' />
                    </svg>
                  </Button>
                </OverlayTrigger>
              ) : (
                <Button
                  variant='text-primary'
                  size='sm'
                  onClick={() => handleDelete(item)}
                  disabled={isNotAdmin()}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    fill='currentColor'
                    className='mi-outline mi-delete'
                    viewBox='0 0 24 24'
                  >
                    <path d='M13.5 10c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-3 0c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-4.49 8.99c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12Zm2-10h8v10h-8v-10Zm9.99-5h-2.08c-.27 0-.52-.11-.71-.29l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1Z' />
                  </svg>
                </Button>
              )}
            </div>
          </div>
          <span
            style={{
              marginLeft: '20px',
              width: 'calc(100% - 25px)',
            }}
          >
            {children}
          </span>
        </li>
      </div>
      <div className='node-spacing'> </div>
    </>
  );
};
