import { toast, ToastContainer } from 'react-toastify';
import ExternalContactUtility from '../ExternalContactUtility';
import { useEffect, useState } from 'react';
import { Service } from './CreateExternalContactConstants';
import { useLocation } from 'react-router-dom';
import { fetchAllServices } from 'services/serviceManagement.service';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import Loading from 'components/Common/Loading';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function CreateExternalContact() {
  const location = useLocation();
  const [data, setData] = useState<Service[]>([]);
  const [isloaded, setIsLoaded] = useState(false);

  const fetchService = async () => {
    try {
      const serviceResponse = await fetchAllServices();
      if (serviceResponse.status === 200) {
        setData(() => serviceResponse.data);
      } else if (serviceResponse.status == 401) {
        toast.error('Unauthorized to access services');
        clearSessionAndRedirect();
      } else {
        toast.error('Failed to fetch Services');
        logEvent('GET_ALL_SERVICES', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Services',
          error: serviceResponse?.data ? `"${serviceResponse?.data}"` : 'Failed to fetch services'
        });
      }
      setIsLoaded(true);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    const details = location.state;
    if (details?.data) {
      setData(() => details.data);
    } else {
      fetchService();
    }
    setIsLoaded(true);
  }, []);

  return (
    <div className="main-tab-container">
      {isloaded && data.length > 0 ? (
        <>
          <h1 className="heading">Create External Contact</h1>
          <ExternalContactUtility data={data} />
          <ToastContainer />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default CreateExternalContact;
