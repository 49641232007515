import {
  Accordion,
  Card,
  Col,
  Form,
  Row,
} from '@trimbleinc/modus-react-bootstrap';
import ServiceTree from '../../../features/IncidentManagement/components/CreateIncident/components/CreateIncidentForm/components/ServiceTree';
import { useState, useEffect, useRef } from 'react';
import { Node, Service } from './AccordionInput.constants';
import { fetchAllServices } from '../../../services/serviceManagement.service';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import {
  ACCORDION_CONSTANTS,
  IAccordionInputProps,
} from './AccordionInput.constants';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function AccordionInput({
  onServiceSelect,
  isError,
  value,
  isDisabled,
  isSearchFilter,
}: IAccordionInputProps) {
  const [services, setServices] = useState<Node[]>([]);
  const [isLoadedService, setIsLoadedService] = useState(false);
  const [selectedServiceLabel, setSelectedServiceLabel] = useState<string>(
    value ? value : isSearchFilter ? 'All Services' : '-Select a service-'
  );
  const [isExpand, setIsExpand] = useState(false);

  const fetchService = async () => {
    try {
      const serviceResponse = await fetchAllServices();
      if (serviceResponse.status === 200) {
        const convertedServices = await convertToTreeItems(
          serviceResponse.data
        );
        setIsLoadedService(true);
        setServices(convertedServices);
      } else if (serviceResponse.status == 401) {
        toast.error('Unauthorized to access services');
        clearSessionAndRedirect();
      } else {
        toast.error('Failed to fetch Services');
        logEvent('GET_ALL_SERVICE', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Services',
          error: serviceResponse?.data
            ? `"${serviceResponse?.data}"`
            : 'Failed to fetch Services',
        });
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    fetchService();
  }, []);

  const convertToTreeItems = async (serviceData: Service[]) => {
    let nodeIdCounter = 0;

    const convertItem = (serviceItem: Service): Node | null => {
      nodeIdCounter++;
      const treeItem: Node = {
        nodeId: nodeIdCounter,
        label: serviceItem.displayName,
        product: serviceItem.product,
        service: serviceItem.service,
        emailName: serviceItem.emailName,
        isHidden: serviceItem.isHidden,
        isMainComponent: serviceItem.isMainComponent,
        statuscastId: serviceItem.statuscastId,
        statuscastGroupId: serviceItem.statuscastGroupId,
      };

      if (serviceItem.children && serviceItem.children.length > 0) {
        treeItem.children = serviceItem.children
          .map(convertItem)
          .filter((child) => child !== null) as Node[];
      }

      return treeItem;
    };

    const treeItems = serviceData
      .map(convertItem)
      .filter((item) => item !== null) as Node[];

    if (isSearchFilter) {
      treeItems.unshift({
        nodeId: 0,
        label: 'All Services',
        product: '',
        service: '',
        emailName: '',
        isHidden: false,
        isMainComponent: false,
        statuscastId: 0,
        statuscastGroupId: 0,
      });
    }

    return treeItems;
  };

  const handleNodeSelect = (selectedNode: Node) => {
    if (selectedNode) {
      if (!isSearchFilter && selectedNode.isMainComponent) {
        toast.error(`${selectedNode.label} cannot be selected`);
      } else {
        setSelectedServiceLabel(selectedNode.label);
        if (onServiceSelect) {
          onServiceSelect(selectedNode.product, selectedNode.service);
        }
      }
      setIsExpand(false); // Close the accordion
    }
  };

  const handleAccordionClick = () => {
    setIsExpand(!isExpand);
  };

  const baseElementRef = useRef<HTMLDivElement>(null);
  const targetElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const baseElement = baseElementRef.current;
    const targetElement = targetElementRef.current;

    if (baseElement && targetElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        // Use requestAnimationFrame to batch updates
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          const baseHeight = baseElement.offsetHeight;
          const newHeight = `${baseHeight + 33}px`;

          if (targetElement.style.height !== newHeight) {
            targetElement.style.height = newHeight;
          }
        });
      });

      resizeObserver.observe(baseElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <div>
      {isSearchFilter === false ? (
        <Row className='form-row'>
          <Col md={6}>
            <h4 className='form-label' style={{ marginTop: '6px' }}>
              {ACCORDION_CONSTANTS.input}
            </h4>
          </Col>
          <Col md={6}>
            <div className='accordion' ref={targetElementRef}>
              <Accordion
                activeKey={isExpand ? '0' : ''}
                className='accordion-sm'
              >
                <div className='card-dropdown'>
                  <Card
                    className={`accordion-layout ${isExpand ? 'expanded' : ''}`}
                    style={
                      isExpand
                        ? { boxShadow: '0 13px 20px rgba(0, 0, 0, 0.2)' }
                        : {}
                    }
                  >
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey='0'
                      className='accordion-header'
                      onClick={handleAccordionClick}
                    >
                      <h6
                        className='mb-0'
                        id='collapsible-group-item-1'
                        ref={baseElementRef}
                        style={
                          isDisabled ||
                          selectedServiceLabel === '-Select a service-'
                            ? {
                                fontWeight: 'normal',
                                color: '#adb5bd',
                                marginLeft: '6px',
                                fontSize: '12.5px',
                              }
                            : {
                                fontWeight: 'normal',
                                color: '#6c757d',
                                marginLeft: '6px',
                                fontSize: '12.5px',
                              }
                        }
                      >
                        {selectedServiceLabel}
                      </h6>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                      <Card.Body className='custom-scroll'>
                        <div className='custom-card-bg'>
                          {isLoadedService ? (
                            <ServiceTree
                              serviceTree={services}
                              onSelectNode={handleNodeSelect}
                            />
                          ) : (
                            <div className='loading-card'>
                              <Loading />
                            </div>
                          )}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </div>
              </Accordion>
            </div>
            {isError && (
              <Form.Control.Feedback
                type='invalid'
                style={{
                  display: 'block',
                  marginTop: '-12px',
                  marginBottom: '15px',
                }}
              >
                {ACCORDION_CONSTANTS.error}
              </Form.Control.Feedback>
            )}
          </Col>
        </Row>
      ) : (
        <div>
          <div className='accordion' ref={targetElementRef}>
            <Accordion activeKey={isExpand ? '0' : ''} className='accordion-sm'>
              <div className='card-dropdown-filter'>
                <Card
                  className={`accordion-layout ${isExpand ? 'expanded' : ''}`}
                  style={
                    isExpand
                      ? { boxShadow: '0 13px 20px rgba(0, 0, 0, 0.2)' }
                      : {}
                  }
                >
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey='0'
                    className='accordion-header-filter accordion-search'
                    onClick={handleAccordionClick}
                  >
                    <h6
                      className='mb-0'
                      id='collapsible-group-item-1'
                      ref={baseElementRef}
                      style={
                        isDisabled
                          ? { background: '#dadce7', fontWeight: 'normal' }
                          : {
                              fontWeight: 'normal',
                              color: '#6c757d',
                              marginRight: '10px',
                            }
                      }
                    >
                      {selectedServiceLabel}
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey='0'>
                    <Card.Body className='custom-scroll'>
                      <div className='custom-card-bg'>
                        {isLoadedService ? (
                          <ServiceTree
                            serviceTree={services}
                            onSelectNode={handleNodeSelect}
                          />
                        ) : (
                          <div className='loading-card'>
                            <Loading />
                          </div>
                        )}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </div>
            </Accordion>
          </div>
        </div>
      )}
    </div>
  );
}

export default AccordionInput;
