import { Button } from '@trimbleinc/modus-react-bootstrap';

function ErrorPage() {
  return (
    <div className="main-tab-container error-page">
      <div className="error-container">
        <div className="content-container">
          <div className="text-container">
            <h1>404: Page Not Found</h1>
            <p>
              The page you are looking for has either moved, no longer exists,
              or can’t be found. Try going back to the previous page.
            </p>
            <Button
              className="text-container button"
              variant="primary"
              onClick={() => (window.location.href = '/')}
            >
              Go to Homepage
            </Button>
          </div>
          <div className="image-container">
            <img
              src="https://images.ctfassets.net/9k5dj5b59lqq/61TbDvTjzTTHuRoSQNb3dn/6efaeabacb66da7b1fcbea31e3a9a33c/404-image.png?fm=avif&h=800&q=90"
              alt="404 Error"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
