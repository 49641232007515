import {
  Button,
  Col,
  Form,
  Modal,
  Row,
} from '@trimbleinc/modus-react-bootstrap';
import { MESSAGE_CONSTANTS } from './MessageBox.constants';
import { IMessageBoxProps } from './MessageBox.constants';
import Editor from '../SummerNote/Editor';
import { useState } from 'react';

function MessageBox({
  value,
  previewMessage,
  onMessageChange,
  macroVariables,
  isError,
  isValidLink,
}: IMessageBoxProps) {
  const [showPreviewMessage, setShowPreviewMessage] = useState(false);

  const handleClose = () => setShowPreviewMessage(false);
  const handleShow = () => setShowPreviewMessage(true);

  const handleEditorChange = (value: any) => {
    onMessageChange(value.toString());
  };

  return (
    <Row className="form-row">
      <Col md={6}>
        <h4 className="form-label" style={{ marginTop: '6px' }}>
          {MESSAGE_CONSTANTS.input}
        </h4>
      </Col>
      <Col md={6}>
        <div className="d-flex" style={{ border: '1px solid #6a6e79' }}>
          <Editor
            id={'CreateIncidentFormEditor'}
            value={value || ''}
            onChange={(value: any) => handleEditorChange(value)}
            variableMacroOptions={macroVariables}
          />
        </div>
        {isError && (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {MESSAGE_CONSTANTS.errorMessage}
          </Form.Control.Feedback>
        )}

        {!isValidLink && (
          <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
            {MESSAGE_CONSTANTS.notValidLink}
          </Form.Control.Feedback>
        )}

        <div className="d-flex justify-content-end">
          <Button
            style={{ marginTop: '15px', marginBottom: '15px' }}
            variant="tertiary"
            size="sm"
            onClick={handleShow}
            disabled={previewMessage === '' ? true : false}
          >
            Preview Message
          </Button>

          <Modal show={showPreviewMessage} onHide={handleClose} centered>
            <Modal.Header
              closeButton
              placeholder=""
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
            >
              <Modal.Title>Preview Message</Modal.Title>
            </Modal.Header>
            <Modal.Body
              dangerouslySetInnerHTML={{ __html: previewMessage }}
            ></Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Col>
    </Row>
  );
}

export default MessageBox;
