import { Modal } from '@trimbleinc/modus-react-bootstrap';
import {IPropTypesHelpModal} from './HelpGuide.constants';
import HelpCarousel from './component';
import './styles.scss';
const HelpGuide = ({ show, handleClose }: IPropTypesHelpModal) => {
  return (
    <Modal show={show} onHide={handleClose} centered size="xl">
      <Modal.Header
        closeButton
        placeholder=""
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
      >
        <Modal.Title>Help Guide</Modal.Title>
      </Modal.Header>
      <Modal.Body className='help-modal'>
        <HelpCarousel />
      </Modal.Body>
    </Modal>
  );
};

export default HelpGuide;
