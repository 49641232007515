import { useForm } from "react-hook-form";
import { Button, Form, Col } from "@trimbleinc/modus-react-bootstrap";
import { useEffect, useState } from "react";
import { EDIT_CONSTANTS, IEditProps } from "./Edit.constants";
import { toast } from 'react-toastify';
import { updateRole } from "../../../../../services/userMgmt.service";
import Loading from "../../../../../components/Common/Loading";
import logEvent from "utils/TrimbleInsightsLogger.utils";


function Edit({ value, modalClose, updateUserList , setIsUpdating}: IEditProps) {

    const { register, handleSubmit, formState, formState: { errors } } = useForm({});
    const [isFormValid, setIsFormValid] = useState<boolean>(false)

    useEffect(() => {
        if (Object.keys(formState.errors)?.length == 0) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formState])

    const onSubmit = async (data: any) => {
        try {
            modalClose()
            setIsUpdating(true)
            let response = await updateRole(data)
            setIsUpdating(false)
            if (response.status == 200) {
                updateUserList()
                toast.success(response?.data ?? EDIT_CONSTANTS.MODIFY_ROLE_SUCCESS)
                logEvent('MANAGE_USER', 'USER_ACTIVITY', {
                  contactEmail: data.userEmailId,
                  role: data.updatedRole,
                  actions:'Update',
                  resource: 'Users'
                });
            }
            if (response.status == 400 || response.status == 500){
                toast.error(response.data ?? EDIT_CONSTANTS.MODIFY_ROLE_ERROR)
                logEvent('MANAGE_USER', 'EXCEPTION', {
                  contactEmail: data.userEmailId,
                  role: data.updatedRole,
                  actions:'Update',
                  resource: 'Users',
                  error: response?.data ? `"${response?.data}"` : EDIT_CONSTANTS.MODIFY_ROLE_ERROR
                });
            }
            if (response.status == 401) {
                toast.error(EDIT_CONSTANTS.MODIFY_ROLE_UNAUTHORIZED)
            }
        }
        catch {
            toast.error(EDIT_CONSTANTS.MODIFY_ROLE_API_ERROR)
        }
    }

    return (
      <>
        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Row>
            <Form.Group as={Col}>
              <Form.Label htmlFor="inlineFormInputName2" srOnly>
                {EDIT_CONSTANTS.EMAIL.label}
              </Form.Label>
              <Form.Control
                type="input"
                aria-label="email"
                size="lg"
                defaultValue={value.original.userEmail}
                readOnly
                {...register(EDIT_CONSTANTS.EMAIL.key, {
                  required: {
                    value: true,
                    message: EDIT_CONSTANTS.EMAIL.validationMessage,
                  },
                  maxLength: {
                    value: 250,
                    message: EDIT_CONSTANTS.EMAIL.error_length,
                  },
                  pattern: {
                    value:
                    /^[A-Z0-9._%+-]+@trimble\.com$/i,
                    message: EDIT_CONSTANTS.EMAIL.validationMessage,
                  },
                })}
              ></Form.Control>
              {errors?.[EDIT_CONSTANTS.EMAIL.key]?.message && (
                <Form.Control.Feedback
                  type="invalid"
                  className="field-error-message"
                >
                  {errors[EDIT_CONSTANTS.EMAIL.key]?.message?.toString()}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label htmlFor="inlineFormInputName2" srOnly>
                {EDIT_CONSTANTS.ROLE.label}
              </Form.Label>
              <Form.Control
                as="select"
                custom
                defaultValue={
                  value.original.role.toLowerCase()
                }
                size="lg"
                {...register(EDIT_CONSTANTS.ROLE.key, {
                  required: {
                    value: true,
                    message: EDIT_CONSTANTS.ROLE.validationMessage,
                  },
                })}
              >
                <option disabled value="">
                  {EDIT_CONSTANTS.ROLE.drop_down_default}
                </option>
                {EDIT_CONSTANTS.ROLE_LIST.map((value) => (
                  <option value={value.code} key={value.code}>
                    {value.name}
                  </option>
                ))}
              </Form.Control>
              {errors?.[EDIT_CONSTANTS.ROLE.key]?.message && (
                <Form.Control.Feedback
                  type="invalid"
                  className="field-error-message"
                >
                  {errors[EDIT_CONSTANTS.ROLE.key]?.message?.toString()}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </Form.Row>
          <Button
            variant="primary"
            className="mr-2 float-right"
            type="submit"
            disabled={!isFormValid}
          >
            {EDIT_CONSTANTS.MODIFY_ROLE.label}
          </Button>
        </Form>
      </>
    );
}

export default Edit;