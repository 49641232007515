export enum MenuType {
    INCIDENTS = "incidents",
    SERVICES = "services",
    USER = "users",
    DASHBOARD = "dashboard",
    CONTACTS = "contacts",
}

export type SideBarProps = {
    toggleMenuCollapse: (value: boolean) => void;
}