import { Quill } from 'react-quill';
import './styles.scss';

const CustomUndo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
    <path
      className="ql-stroke"
      d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
    />
  </svg>
);

// Redo button icon component for Quill editor
const CustomRedo = () => (
  <svg viewBox="0 0 18 18">
    <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
    <path
      className="ql-stroke"
      d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
    />
  </svg>
);

// Undo and redo functions for Custom Toolbar
function undoChange(this: {
  quill: any;
  undo: () => void;
  redo: (this: { undo: () => void; redo: () => void }) => void;
}) {
  this.quill.history.undo();
}
function redoChange(this: { quill: any; undo: () => void; redo: () => void }) {
  this.quill.history.redo();
}

function handleMacroInsertion(
  this: {
    quill: any;
    undo: (this: {
      quill: any;
      undo: () => void;
      redo: (this: { undo: () => void; redo: () => void }) => void;
    }) => void;
    redo: (this: { quill: any; undo: () => void; redo: () => void }) => void;
    macro: (value: string | any[]) => void;
  },
  value: string | any[]
) {
  const quill = this.quill;
  const range = quill.getSelection();
  if (range) {
    quill.insertText(range.index, value);
    quill.setSelection(range.index + value.length);
  }
}

// Add sizes to whitelist and register them
const Size = Quill.import('formats/size');
Size.whitelist = ['extra-small', 'small', 'medium', 'large'];
Quill.register(Size, true);

// Add fonts to whitelist and register them
const Font = Quill.import('formats/font');
Font.whitelist = [
  'arial',
  'comic-sans',
  'courier-new',
  'georgia',
  'helvetica',
  'lucida',
];
Quill.register(Font, true);

// Modules object for setting up the Quill editor
export const modules = (toolbarId: string) => ({
  toolbar: {
    container: `#${toolbarId}`,
    handlers: {
      undo: undoChange,
      redo: redoChange,
      macro: handleMacroInsertion,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
});

// Formats objects for setting up the Quill editor
export const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
  'code-block',
  'macroOptions',
];
const macroOptions = [
  { value: '${service}', label: 'service' },
  { value: '${product}', label: 'product' },
  { value: '${plannedTime} UTC', label: 'plannedTime' },
  { value: '${plannedDate}', label: 'plannedDate' },
  { value: '${startTime} UTC', label: 'startTime' },
  { value: '${endTime} UTC', label: 'endTime' },
  { value: '${existingStartTime} UTC', label: 'existingStartTime' },
  { value: '${escalationTimer}', label: 'escalationTimer' },
  { value: '${duration}', label: 'duration' },
  { value: '${alternativeBeamName}', label: 'alternativeBeamName' },
];
// Quill Toolbar component
export const QuillToolbar = ({ toolbarId, variableMacroOptions }: any) => (
  <div id={toolbarId} className="d-flex justify-content-start flex-wrap">
    <span className="format-space">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
    </span>
    <span className="format-space">
      <button className="ql-link" />
    </span>
    <span className="format-space">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span>
    <span className="ql-formats">
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="format-space" title="Select a macro">
      <select className="ql-macro">
        {variableMacroOptions
          ? variableMacroOptions.map((option: any) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))
          : macroOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
      </select>
    </span>
  </div>
);

export default QuillToolbar;
