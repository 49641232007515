import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchUpcomingPlannedMaintenance } from '../../../../../../services/incidentManagement.service';
import Loading from '../../../../../../components/Common/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from '@trimbleinc/modus-react-bootstrap';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import { OPEN_INCIDENTS_CONSTANTS } from '../../OpenIncidents.constants';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function ListPlannedMaintenance({ onMaintenanceLoadSuccess }: any) {
  const [plannedMaintenance, setPlannedMaintenance] = useState<any[]>([]);
  const [isLoadedPM, setIsLoadedPM] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const fetchUpcomingPM = async () => {
    try {
      const plannedMaintenanceResponse =
        await fetchUpcomingPlannedMaintenance();

      if (plannedMaintenanceResponse.status == 200) {
        setIsLoadedPM(true);
        setPlannedMaintenance(plannedMaintenanceResponse.data);
        onMaintenanceLoadSuccess();
      } else if (plannedMaintenanceResponse.status == 401) {
        toast.error('Unauthorized');
        clearSessionAndRedirect();
      } else {
        toast.error('Failed to fetch Planned Maintenance');
        logEvent('GET_PLANNED_MAINTENANCE', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Incidents',
          error: plannedMaintenanceResponse?.data
            ? `"${plannedMaintenanceResponse?.data}"`
            : 'Failed to fetch Planned Maintenance',
        });
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    fetchUpcomingPM();
  }, []);

  const handleViewIncidents = (incidentId: string, maintenance: any) => {
    let baseURL = location.pathname.split('/')[1];

    navigate(`${RouterPath.PRIVATE_ROUTE.incidentManagement}/` + incidentId, {
      state: {
        baseUrl: {
          url: baseURL,
        },
        incidentDetails: {
          incidentId: maintenance.incidentId,
          type: maintenance.type,
          incidentState: maintenance.incidentState,
          product: maintenance.product,
          service: maintenance.service,
          startTimeInUTC: maintenance.startTimeInUTC,
          endTimeInUTC: maintenance.endTimeInUTC,
          escalationLevel: maintenance.escalationLevel,
          isFalseAlarm: maintenance.isFalseAlarm,
          hourlyUpdateMailCount: maintenance.hourlyUpdateMailCount,
        },
      },
    });
  };
  return (
    <div className="planned-maintenance-container">
      <h3>Upcoming Planned Maintenance</h3>
      <div>
        {isLoadedPM ? (
          plannedMaintenance.length > 0 ? (
            <div style={{ marginBottom: '-4px' }}>
              {plannedMaintenance.map((maintenance, index) => (
                <div
                  key={index}
                  className="d-flex flex-row plannedMaintenanceCard"
                >
                  <span className="info-content">
                    <i
                      className="modus-icons"
                      aria-hidden="true"
                      style={{ color: '#ffe900' }}
                    >
                      clock
                    </i>
                  </span>
                  <div>
                    <span>
                      <strong>IncidentId: </strong>
                      {maintenance.incidentId}
                    </span>{' '}
                    |{' '}
                    <span>
                      <strong>Service: </strong>
                      {maintenance.service}
                    </span>{' '}
                    |{' '}
                    <span>
                      <strong>Start Time: </strong>
                      {maintenance.startTimeInUTC} UTC
                    </span>{' '}
                  </div>
                  <span className="edit-button-pm">
                    <OverlayTrigger
                      key="overlay1"
                      placement="top"
                      overlay={<Tooltip id="btntooltip">Edit</Tooltip>}
                    >
                      <i
                        className="modus-icons"
                        aria-hidden="true"
                        onClick={() => {
                          handleViewIncidents(
                            maintenance.incidentId,
                            maintenance
                          );
                        }}
                      >
                        file_edit
                      </i>
                    </OverlayTrigger>
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="empty-PM">
              <p>
                There are no upcoming maintenances, Click{' '}
                <a href={RouterPath.PRIVATE_ROUTE.createIncident}>here</a> to get started.
              </p>
            </div>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default ListPlannedMaintenance;
