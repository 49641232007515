import { getItemFromLocalStorage } from "utils/LocalStorage.utils";
import { fetchReq } from "./http.service";
import serviceUrls from "./serviceUrls";

const {mailerURL} = serviceUrls;

export const fetchNotificationTemplate = async () => {
    const url = `${mailerURL}/api/notificationtemplate`;
    return await fetchReq(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      },
    });
  }
  
  export const updateNotificationTemplate = async (data: any) => {
    const url = `${mailerURL}/api/notificationtemplate`;
    return await fetchReq(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
  }