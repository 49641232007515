import { DraggingPosition, DraggingPositionBetweenItems, DraggingPositionItem, DraggingPositionRoot, TreeItem, TreeItemIndex } from "react-complex-tree";
import { ITreeItem } from "../TreeNode/TreeNode.constants";

const findItemById = (data: ITreeItem[], id: TreeItemIndex): any => {
    for (const item of data) {
        if (item.statuscastId.toString() === id) {
            return { ...item };
        }
        if (item.children && item.children.length > 0) {
            const found = findItemById(item.children, id);
            if (found) {
                return found;
            }
        }
    }
}
export const getUpdateDetails = (updatedItems: TreeItem<any>[], target: DraggingPosition, originalData: ITreeItem[]) => {

    const updateDetails: any = {};
    let parentItemDetails: ITreeItem;

    const draggedItemIndex = updatedItems[0].index;
    const draggedItemDetails: ITreeItem = findItemById(originalData, draggedItemIndex);
    const modifyService = draggedItemDetails.displayName

    updateDetails['service'] = draggedItemDetails.service;
    updateDetails['product'] = draggedItemDetails.product;
    updateDetails['statuscastServiceID'] = draggedItemDetails.statuscastId;
    updateDetails['parentStatuscastServiceID'] = draggedItemDetails.statuscastServiceParentId;
    updateDetails['peerPriority'] = draggedItemDetails.peerPriority;

    const updateDetailsWithParent = (parent: any) => {
        parentItemDetails = findItemById(originalData, parent);
        parentItemDetails.isMainComponent ? updateDetails['updatedProduct'] = draggedItemDetails.service
            : updateDetails['updatedProduct'] = parentItemDetails.service;

        updateDetails['updatedParentStatuscastServiceID'] = parentItemDetails.statuscastId;
        updateDetails['updatedParentStatuscastGroupID'] = parentItemDetails.statuscastGroupId;

        if (target.targetType === 'between-items') {
            let updatedPeerPriority = target.childIndex;

            if (parentItemDetails.statuscastId != draggedItemDetails.statuscastServiceParentId && target.linePosition === 'top') {
                updateDetails['updatedPeerPriority'] = 1;
            }
            else if (parentItemDetails.statuscastId != draggedItemDetails.statuscastServiceParentId && target.linePosition === 'bottom') {
                updateDetails['updatedPeerPriority'] = target.childIndex + 1;
            }
            // else if (parentItemDetails.statuscastId != draggedItemDetails.statuscastServiceParentId) {
            //     if (parentItemDetails && parentItemDetails.children && parentItemDetails.children.length > 0) {
            //         const lastChild = parentItemDetails.children[parentItemDetails.children.length - 1];
            //         updateDetails['updatedPeerPriority'] = lastChild.peerPriority + 1;
            //     }
            //     else {
            //         updateDetails['updatedPeerPriority'] = target.childIndex;
            //     }
            // }
            else if (updatedPeerPriority < draggedItemDetails.peerPriority)
                updateDetails['updatedPeerPriority'] = target.childIndex + 1;
            else
                updateDetails['updatedPeerPriority'] = target.childIndex;
        }

        else if (target.targetType === 'item' && parentItemDetails.statuscastId != draggedItemDetails.statuscastServiceParentId) {
            if (parentItemDetails && parentItemDetails.children && parentItemDetails.children.length > 0) {
                const lastChild = parentItemDetails.children[parentItemDetails.children.length - 1];
                updateDetails['updatedPeerPriority'] = lastChild.peerPriority + 1;
            }
            else
                updateDetails['updatedPeerPriority'] = 1;
        }
        else
            updateDetails['updatedPeerPriority'] = target.depth;
    };

    switch (target.targetType) {
        case 'item':
            const { targetItem: parent, parentItem: root } = target as DraggingPositionItem;
            if (draggedItemDetails.isMainComponent) {
                updateDetails['error'] = true;
                updateDetails['message'] = 'The main component cannot be dragged.';
                return { updateDetails, modifyService };
            }
            if (root === 'root') {
                const targetItemDetails = findItemById(originalData, parent);
                if (targetItemDetails) {
                    updateDetails['updatedProduct'] = targetItemDetails.service;
                    updateDetails['updatedParentStatuscastServiceID'] = targetItemDetails.statuscastId;
                    updateDetails['updatedParentStatuscastGroupID'] = targetItemDetails.statuscastGroupId;
                    if (targetItemDetails.children && targetItemDetails.children.length > 0) {
                        const lastChild = targetItemDetails.children[targetItemDetails.children.length - 1];
                        updateDetails['updatedPeerPriority'] = lastChild.peerPriority + 1;
                    } else {
                        updateDetails['updatedPeerPriority'] = 1;
                    }
                }
            } else {
                updateDetailsWithParent(parent);
            }
            break;

        case 'between-items':
            const { parentItem } = target as DraggingPositionBetweenItems;
            if (parentItem === 'root') {
                if (draggedItemDetails.isMainComponent) {
                    let updatedPeerPriority = target.childIndex;
                    if (updatedPeerPriority < draggedItemDetails.peerPriority)
                        updateDetails['updatedPeerPriority'] = target.childIndex + 1;
                    else
                        updateDetails['updatedPeerPriority'] = target.childIndex;
                } else {
                    updateDetails['error'] = true;
                    updateDetails['message'] = 'The child component cannot be made as a main component.';
                }
            }
            else if (draggedItemDetails.isMainComponent) {
                updateDetails['error'] = true;
                updateDetails['message'] = 'The main component cannot be moved as a child.';
            }
            else {
                updateDetailsWithParent(parentItem);
            }
            break;
        case 'root':
            const { targetItem } = target as DraggingPositionRoot;

            break;
        default:
            break;
    }
    return { updateDetails, modifyService };
};