import { createRequest } from '../features/IncidentManagement/components/CreateIncident/components/CreateIncidentForm/CreateIncidentForm.constants';
import { updateRequest } from '../features/ViewIncidents/components/ManageIncidents/ManageIncidents.constants';
import { getItemFromLocalStorage } from '../utils/LocalStorage.utils';
import { fetchReq } from './http.service';
import serviceUrls from './serviceUrls';

const { incidentsURL } = serviceUrls;

export const fetchUpcomingPlannedMaintenance = async () => {
  const url = `${incidentsURL}/api/incidents?plannedMaintenance=true`;
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const fetchUnresolvedIncidents = async (
  pageNumber: number,
  incidentId: string,
  selectedService: string,
  selectedProduct: string
) => {
  const url = `${incidentsURL}/api/incidents?unresolved=true&pageNumber=${pageNumber}&incidentId=${incidentId}&service=${selectedService}&product=${selectedProduct}&pageSize=5`;
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const fetchAllIncidentsfromBackend = async (
  pageNumber: number,
  incidentId: string,
  selectedService: string,
  selectedProduct: string,
  timestamp?: number,
) => {
  let url = `${incidentsURL}/api/incidents?pageNumber=${pageNumber}&incidentId=${incidentId}&service=${selectedService}&product=${selectedProduct}&pageSize=10`;
  if(timestamp) {
    url = `${url}&timestamp=${timestamp}`;
  }
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const fetchIncidentDetails = async (incidentId: string) => {
  const url = `${incidentsURL}/api/incidents?incidentId=${incidentId}`;
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
};

export const createIncident = async (incidentData: createRequest) => {
  const url = incidentsURL + '/api/incidents';
  return await fetchReq(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(incidentData),
  });
};

export const updateIncident = async (incidentData: updateRequest) => {
  const url = incidentsURL + '/api/incidents';
  return await fetchReq(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(incidentData),
  });
};

export const fetchIncidentsCount = async () => {
  const url = incidentsURL + '/api/getIncidentsCount';
  return await fetchReq(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
    },
  });
}