import { ToastContainer } from 'react-toastify';
import AllIncidents from './components/AllIncidents';
import CreateIncident from './components/CreateIncident';

function IncidentManagement() {
  return (
    <div className="main-tab-container">
      <h1 className="heading">Incident Management</h1>
      <span className="create-button-position">
        <CreateIncident />
      </span>
      <AllIncidents />
      <ToastContainer />
    </div>
  );
}

export default IncidentManagement;
