import {
  Button,
  Card,
  DataTable,
  Form,
  Modal,
} from '@trimbleinc/modus-react-bootstrap';
import EmailSearchBox from 'components/Common/EmailSearchBox';
import PaginationButton from 'components/Common/PaginationButton';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import {
  IListEscalationContactsProps,
  LIST_ESCALATION_CONTACTS,
} from './ListEscalationContactsConstants';
import { getItemFromLocalStorage } from 'utils/LocalStorage.utils';
import {
  deleteEscalationContact,
  fetchEscalationContact,
} from 'services/groupManagement.service';
import Loading from 'components/Common/Loading';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import EditLevel from './components/EditLevel';
import { isNotAdmin } from 'utils/RoleCheck.utils';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

const Action = ({
  value,
  setIsUpdating,
  currentPage,
  selectedLevel,
  emailId,
  fetchEscContacts,
}: any) => {
  const navigate = useNavigate();
  const initiateDelete = () => {
    //delete function
    let response: any = null;
    Swal.fire({
      title: `<h4>${LIST_ESCALATION_CONTACTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'error',
      html: `<p>${LIST_ESCALATION_CONTACTS.ALERT_CONFIRM_EMAIL}<br/>
              ${value.original.emailAddress}<p>`,
      showCancelButton: true,
      confirmButtonText: LIST_ESCALATION_CONTACTS.DELETE_CONFIRM_TEXT,
      cancelButtonText: LIST_ESCALATION_CONTACTS.DELETE_CANCEL_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          setIsUpdating(true);
          response = await deleteEscalationContact(value.original.emailAddress);
        } catch (error) {
          toast.error(LIST_ESCALATION_CONTACTS.DELETE_ROLE_API_ERROR);
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (response && response.status == 200) {
          fetchEscContacts(currentPage, selectedLevel, emailId);
          toast.success(
            response?.data ?? LIST_ESCALATION_CONTACTS.DELETE_ROLE_SUCCESS
          );
          logEvent('DELETE_CONTACT', 'USER_ACTIVITY', {
            actions:'Delete',
            resource: 'Escalation_Contacts'
          });
        }
        if (response.status == 400 || response.status == 500){
          toast.error(
            response.data ?? LIST_ESCALATION_CONTACTS.DELETE_ROLE_ERROR
          );
          logEvent('DELETE_CONTACT', 'EXCEPTION', {
            actions:'Delete',
            resource: 'Escalation_Contacts',
            error: response?.data ?  `"${response?.data}"` : LIST_ESCALATION_CONTACTS.DELETE_ROLE_ERROR
          });
        }
        if (response.status == 401) {
          toast.error(LIST_ESCALATION_CONTACTS.DELETE_ROLE_UNAUTHORIZED);
        }
      } else if (result.isDismissed) {
        navigate(RouterPath.PRIVATE_ROUTE.contactManagement);
      }
    });
  };

  //EDIT MODAL
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const UpdateData = () => {
    fetchEscContacts(currentPage, selectedLevel, emailId);
  };

  const isDisabled =
    JSON.parse(getItemFromLocalStorage('auth')!).email !==
    value.original.emailAddress;
  return (
    <>
      <div className="button-group">
        <div className="button-text-container" style={{ marginLeft: '-10px' }}>
          <Button
            variant="text-primary"
            size="sm"
            onClick={handleShow}
            disabled={!isDisabled || isNotAdmin()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="mi-outline mi-pencil"
              viewBox="0 0 24 24"
            >
              <path d="M3.1 17.15c-.1.1-.15.22-.15.36v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.76 9.99l-3.75-3.75L3.1 17.15Zm11.25-6.8-8.5 8.5a.485.485 0 0 1-.7 0c-.2-.2-.2-.51 0-.71l8.5-8.5c.2-.2.51-.2.71 0s.2.51 0 .71Zm6.31-4.67-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41Z" />
            </svg>
            <span className="button-text">
              {LIST_ESCALATION_CONTACTS.MODIFY_ROLE_LABEL}
            </span>
          </Button>
        </div>

        <div className="button-text-container">
          <Button
            variant="text-primary"
            size="sm"
            onClick={initiateDelete}
            disabled={!isDisabled || isNotAdmin()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="mi-outline mi-delete"
              viewBox="0 0 24 24"
            >
              <path d="M13.5 10c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-3 0c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-4.49 8.99c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12Zm2-10h8v10h-8v-10Zm9.99-5h-2.08c-.27 0-.52-.11-.71-.29l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1Z" />
            </svg>
            <span className="button-text">
              {LIST_ESCALATION_CONTACTS.DELETE_USER_LABEL}
            </span>
          </Button>
        </div>
      </div>

      <Modal show={show} centered onHide={handleClose}>
        <Modal.Header
          placeholder=""
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          closeButton
        >
          <Modal.Title>{LIST_ESCALATION_CONTACTS.MODAL_TITLE}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <EditLevel
            value={value}
            modalClose={handleClose}
            UpdateData={UpdateData}
            setIsUpdating={setIsUpdating}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

function ListEscalationContacts({
  addedContactEmail,
}: IListEscalationContactsProps) {
  const [emailId, setEmailId] = useState('');
  const [hasNext, setHasNext] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedLevel, setSelectedLevel] = useState<number>(0);
  const [escalationDataList, setEscalationDataList] = useState<any[]>([]);
  const [isSearchEmail, setIsSearchEmail] = useState(false);
  const [noEmailFound, setNoEmailFound] = useState(false);

  const onEmailIdChange = (newEmailId: any) => {
    setEmailId(() => newEmailId);
  };

  const handleNext = () => {
    setIsLoaded(false);
    setCurrentPage(currentPage + 1);
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setIsLoaded(false);
      setCurrentPage(currentPage - 1);
    }
  };

  const convertDataToTableFormat = (data: any) => {
    return data.map((user: any) => {
      return {
        emailAddress: user.email,
        levels: user.escalationLevel
          .sort()
          .map((level: number) => {
            if (level === 1) {
              return 'First Internal Escalation (L team)';
            } else if (level === 2) {
              return 'Second Internal Escalation (VP)';
            } else {
              return '';
            }
          })
          .join(', '),
      };
    });
  };

  const fetchEscContacts = async (
    page: number,
    level: number,
    email: string
  ) => {
    try {
      setIsLoaded(false);
      let usersResponse = await fetchEscalationContact(5, page, level, email);
      if (usersResponse.status == 200) {
        setIsLoaded(true);

        if (
          usersResponse.data.escalationSubscribers &&
          usersResponse.data.escalationSubscribers.length > 0
        ) {
          setNoEmailFound(false);

          setEscalationDataList(
            convertDataToTableFormat(usersResponse.data.escalationSubscribers)
          );
          setHasNext(usersResponse.data.hasNextPage);
        } else {
          setCurrentPage(0);
          setHasNext(false);
          setNoEmailFound(true);
          setEscalationDataList([]);
        }
        setIsUpdating(false);
      }
      if (usersResponse.status == 400 || usersResponse.status == 500) {
        toast.error(usersResponse?.data ?? 'Failed to fetch escalation contacts');
        logEvent('GET_CONTACT', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Escalation_Contacts',
          ...(level && { level: level }),
          error: usersResponse?.data ? `"${usersResponse?.data}"` : 'Failed to fetch escalation contacts',
        });
      }
      if (usersResponse.status == 401) {
        toast.error('Unauthorized to access users');
        clearSessionAndRedirect();
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Email Address',
        accessor: 'emailAddress',
        width: 350,
      },
      {
        Header: 'Escalation Level',
        accessor: 'levels',
        width: 300,
      },
      {
        Header: 'Action',
        accessor: 'action',
        width: 350,
        Cell: ({ row }: any) => (
          <Action
            value={row}
            setIsUpdating={setIsUpdating}
            currentPage={currentPage}
            selectedLevel={selectedLevel}
            emailId={emailId} // Assuming `emailId` is part of your row data
            fetchEscContacts={fetchEscContacts}
          />
        ),
      },
    ],
    [setIsUpdating, currentPage, selectedLevel, emailId, fetchEscContacts] // Add dependencies here
  );

  const handleSelectChange = (event: any) => {
    setCurrentPage(0);
    if (event.target.value === 'All Level') {
      setSelectedLevel(0);
    } else if (event.target.value === 'First Internal Escalation (L team)') {
      setSelectedLevel(1);
    } else {
      setSelectedLevel(2);
    }

  };

  const searchEmail = () => {
    setCurrentPage(0);
    setIsSearchEmail(!isSearchEmail);
  };

  useEffect(() => {
    fetchEscContacts(currentPage, selectedLevel, emailId);
  }, [currentPage, selectedLevel, isSearchEmail, addedContactEmail]);

  return (
    <Card className="shadow">
      <div>
        <div className="grid-container-esc">
          <div className="search-box-email grid-items-esc">
            <EmailSearchBox
              inputValue={emailId}
              onEmailIdChange={onEmailIdChange}
              searchEmail={searchEmail}
            />
          </div>
          <div className="grid-items-esc">
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Control as="select" custom onChange={handleSelectChange}>
                {LIST_ESCALATION_CONTACTS.DROPDOWN_OPTIONS.map(
                  (option: any, index: any) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  )
                )}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="grid-items-esc">
            <PaginationButton
              currentPage={currentPage}
              hasNext={hasNext}
              onPrevious={handlePrevious}
              onNext={handleNext}
              isContacts={true}
            />
          </div>
        </div>
        {!isLoaded || isUpdating ? (
          <Loading />
        ) : noEmailFound ? (
          <div>
            <h5>
              <br></br>No Contacts Found!
            </h5>
          </div>
        ) : (
          <div className="fixed-header-datatable">
            <DataTable
              id="escalation_contacts"
              columns={columns}
              hover
              data={escalationDataList}
              style={{ maxHeight: '400px' }}
              disablePagination
              resizeColumns
            ></DataTable>
          </div>
        )}
      </div>
    </Card>
  );
}

export default ListEscalationContacts;
