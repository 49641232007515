import './styles.scss'

const CustomArrowRenderer = (item: any) => {
    const hasChildren = item.item.children && item.item.children.length > 0;
    if (item.item.isFolder && hasChildren) {
      return (
        <>
          <div
            className={`rct-tree-item-arrow-isFolder rct-tree-item-arrow ${item.context.isExpanded ? 'rct-tree-item-arrow-expanded' : 'rct-tree-item-arrow-collapsed'}`}
            aria-hidden="true"
            tabIndex={-1}
            onClick={() => item.context.toggleExpandedState()}
            style={{ cursor: 'pointer' }}
          >
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 16 16"
              enableBackground="new 0 0 16 16"
              xmlSpace="preserve"
            >
              <g>
                <g>
                  {item.context.isExpanded ? (
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
                      className="rct-tree-item-arrow-path"
                    ></path>
                  ) : (
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                      className="rct-tree-item-arrow-path"
                    ></path>
                  )}
                </g>
              </g>
            </svg>
          </div>
        </>
      );
    }
    else if (item.item.isFolder && !hasChildren) {
      return (
        <div className="empty-children"></div>
      );
    }
  
    return null;
  };
  export default CustomArrowRenderer;