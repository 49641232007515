import ListIncidents from './components/ListIncidents';
import './styles.scss';
import ListPlannedMaintenance from './components/ListPlannedMaintenance';

function OpenIncidents({ onIncidentsLoadSuccess, onMaintenanceLoadSuccess }:any) {
  return (
    <div>
      <h2 style={{ marginBottom: '20px' }}>Open Incidents</h2>
      <div className="grid-container">
        <div>
          <ListIncidents onIncidentsLoadSuccess={onIncidentsLoadSuccess} />
        </div>
        <div className="content-component">
          <ListPlannedMaintenance
            onMaintenanceLoadSuccess={onMaintenanceLoadSuccess}
          />
        </div>
      </div>
    </div>
  );
}

export default OpenIncidents;
