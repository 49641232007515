import { Tab, Tabs } from '@trimbleinc/modus-react-bootstrap';
import EscalationContacts from './components/EscalationContacts';
import ExternalContacts from './components/EscalationContacts/ExternalContacts';

function TabContainer() {
  return (
    <div>
      <Tabs defaultActiveKey="extContacts" id="extContacts">
        <Tab eventKey="extContacts" title="EXTERNAL CONTACTS">
          <div className="py-3">
            <ExternalContacts />
          </div>
        </Tab>
        <Tab eventKey="escContacts" title="ESCALATION CONTACTS">
          <div className="py-3">
            <EscalationContacts />
          </div>
        </Tab>
      </Tabs>
    </div>
  );
}

export default TabContainer;
