import { Button, Col, Form } from '@trimbleinc/modus-react-bootstrap';
import {
  ADD_ESCALATION_CONTACTS,
  IAddEscalationContactsProps,
} from './AddEscalationContactsConstants';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { clearSessionAndRedirect } from './../../../../../../../../utils/Error.utils';
import { createEscalationContact } from 'services/groupManagement.service';
import { isNotAdmin } from 'utils/RoleCheck.utils';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function AddEscalationContacts({
  setAddedContactEmail,
}: IAddEscalationContactsProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors },
  } = useForm({
    defaultValues: {
      [ADD_ESCALATION_CONTACTS.LEVEL.key]: '',
    },
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const onSubmit = async (data: any) => {
    try {
      setIsSubmitting(true);
      // Convert emails to an array if it's not already
      const emailsArray = Array.isArray(data.emails)
        ? data.emails
        : [data.emails];

      // Convert escalationLevel to an array of numbers
      const escalationLevelArray = [Number(data.escalationLevel)];

      // Create a new data object with the transformed values
      const transformedData = {
        ...data,
        emails: emailsArray,
        escalationLevel: escalationLevelArray,
      };
      let userData = await createEscalationContact(transformedData);
      if (userData.status == 200) {
        toast.success(userData?.message ?? 'Email added successfully');
        setAddedContactEmail(data.emails);
        logEvent('NEW_CONTACT', 'USER_ACTIVITY', {
          level: transformedData.escalationLevel[0],
          actions:'Create',
          resource: 'Escalation_Contacts'
        });
        reset();
      }
      if (userData.status == 409 || userData.status == 400){
        toast.error(userData?.data ?? 'Failed to add Escalation Contact');
        logEvent('NEW_CONTACT', 'EXCEPTION', {
          level: transformedData.escalationLevel[0],
          actions:'Create',
          resource: 'Escalation_Contacts',
          error: userData?.data ?  `"${userData?.data}"` : 'Failed to add Escalation Contact'
        });
      }
      if (userData.status == 401) {
        toast.error(userData?.data ?? 'Unauthorized to access contacts page');
        clearSessionAndRedirect();
      }
      if (userData.status == 500) {
        reset();
        toast.error(userData?.data ?? 'Error while adding Escalation Contact');
        logEvent('NEW_CONTACT', 'EXCEPTION', {
          level: transformedData.escalationLevel[0],
          actions:'Create',
          resource: 'Escalation_Contacts',
          error: userData?.data ?  `"${userData?.data}"` : 'Failed to add Escalation Contact'
        });
      }
      setIsSubmitting(false);
    } catch {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    if (Object.keys(formState.errors)?.length == 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formState]);
  return (
    <div className="add-user">
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Form.Row>
          <Col xs={12} md={7} className="mb-3">
            <Form.Label htmlFor="inlineFormInputName2" srOnly>
              {ADD_ESCALATION_CONTACTS.EMAIL.label}
            </Form.Label>
            <Form.Control
              type="input"
              aria-label="email"
              size="lg"
              placeholder={ADD_ESCALATION_CONTACTS.EMAIL.placeholder}
              disabled={isNotAdmin()}
              {...register(ADD_ESCALATION_CONTACTS.EMAIL.key, {
                required: {
                  value: true,
                  message: ADD_ESCALATION_CONTACTS.EMAIL.validationMessage,
                },
                maxLength: {
                  value: 250,
                  message: ADD_ESCALATION_CONTACTS.EMAIL.error_length,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@trimble\.com$/i,
                  message: ADD_ESCALATION_CONTACTS.EMAIL.validationMessage,
                },
              })}
            ></Form.Control>
            {errors?.[ADD_ESCALATION_CONTACTS.EMAIL.key]?.message && (
              <Form.Control.Feedback
                type="invalid"
                className="field-error-message"
              >
                {errors[ADD_ESCALATION_CONTACTS.EMAIL.key]?.message?.toString()}
              </Form.Control.Feedback>
            )}
          </Col>

          <Col xs={12} md={3} className="mb-3">
            <Form.Control
              as="select"
              size="lg"
              custom
              disabled={isNotAdmin()}
              {...register(ADD_ESCALATION_CONTACTS.LEVEL.key, {
                required: {
                  value: true,
                  message: ADD_ESCALATION_CONTACTS.LEVEL.validationMessage,
                },
              })}
            >
              <option disabled value="">
                {ADD_ESCALATION_CONTACTS.LEVEL.drop_down_default}
              </option>
              {ADD_ESCALATION_CONTACTS.LEVEL_LIST.map((value) => (
                <option value={value.code} key={value.code}>
                  {value.name}
                </option>
              ))}
            </Form.Control>
            {errors?.[ADD_ESCALATION_CONTACTS.LEVEL.key]?.message && (
              <Form.Control.Feedback
                type="invalid"
                className="field-error-message"
              >
                {errors[ADD_ESCALATION_CONTACTS.LEVEL.key]?.message?.toString()}
              </Form.Control.Feedback>
            )}
          </Col>
          <Col xs={12} md={2} className="mb-3">
            <Button
              variant="primary"
              className="mb-2"
              type="submit"
              size="lg"
              disabled={!isFormValid || isSubmitting || isNotAdmin()}
            >
              {ADD_ESCALATION_CONTACTS.ADD_USER.label}
            </Button>
          </Col>
        </Form.Row>
      </Form>
    </div>
  );
}

export default AddEscalationContacts;
