export const MESSAGE_CONSTANTS = {
  input: 'Message',
  placeholder: 'Optional Message',
  errorMessage: 'Message is required',
  notValidLink: 'Provided Link is not valid',
};

export interface IMessageBoxProps {
  value: string | null;
  previewMessage: string;
  onMessageChange: (message: string) => void;
  macroVariables: any[];
  isError: boolean;
  isValidLink: boolean;
}
