import { Pagination } from '@trimbleinc/modus-react-bootstrap';

function PaginationButton({
  currentPage,
  hasNext,
  onPrevious,
  onNext,
  isContacts,
}: any) {
  return (
    <Pagination size={isContacts ? 'sm' : 'lg'}>
      <Pagination.Item
        className={
          currentPage === 0
            ? isContacts
              ? 'prev-button-disabled-contacts'
              : 'prev-button-disabled'
            : isContacts ? '' :'prev-button'
        }
        aria-label="Previous"
        onClick={() => onPrevious()}
        disabled={currentPage === 0} // Disable if currentPage is 0
      >
        <i className="modus-icons" aria-hidden="true">
          chevron_left
        </i>
      </Pagination.Item>
      <Pagination.Item
        aria-label="Next"
        onClick={() => onNext()}
        disabled={!hasNext} // Disable if no next page
        className={!hasNext ? 'next-button-disabled' : ''}
      >
        <i className="modus-icons" aria-hidden="true">
          chevron_right
        </i>
      </Pagination.Item>
    </Pagination>
  );
}

export default PaginationButton;
