import ListAllIncidents from './ListAllIncidents/ListAllIncidents';

function AllIncidents() {
  return (
    <div>
      <h1>All Incidents</h1>
      <ListAllIncidents />
    </div>
  );
}

export default AllIncidents;
