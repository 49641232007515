import AddService from "./components/AddService";
import DragTree from "./components/DragTree";
import { useEffect, useState } from "react";
import { fetchAllServices } from "services/serviceManagement.service";
import { toast } from "react-toastify";
import Loading from "components/Common/Loading";
import logEvent from "utils/TrimbleInsightsLogger.utils";
function ServiceManagement() {
  const [data, setData] = useState<[]>();
  const [isLoading, setIsLoading] = useState(false);
  const getServices = async () => {
    try {
      setIsLoading(true);
      let serviceResponse = await fetchAllServices()
      setIsLoading(false);
      if (serviceResponse.status == 200) {
        setData(serviceResponse.data)
      }
      if (serviceResponse.status == 400 || serviceResponse.status == 500) {
        logEvent('GET_ALL_SERVICES', 'EXCEPTION', {
          actions:'Retrieve',
          resource: 'Services',
          error: serviceResponse?.data ?  `"${serviceResponse?.data}"` : 'Failed to fetch services'
        });
        toast.error(serviceResponse?.data ?? "Failed to fetch services")
      }
      if (serviceResponse.status == 401) {
        toast.error("Unauthorized")
      }
    }
    catch (error) {
      toast.error("Something went wrong")
    }
  }

  useEffect(() => {
    getServices()
  }, [])

  return (
    <>
      <div className="main-tab-container">
        <h1 className="heading">Service Management</h1>
        {data && (
          <span className="add-service-position">
            <AddService data={data} />
          </span>
        )}
        {isLoading ? (
          <Loading />) :
          (
            <div className="tree-card">
              <DragTree getServices={getServices} data={data} />
            </div>
          )
        }
      </div>
    </>
  );
}

export default ServiceManagement;
