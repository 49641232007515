export interface ITreeNodeProps {
    getServices: () => Promise<void>;
    data: any;
}

export interface IExtendedTreeItem {
    index: string;
    isFolder: boolean;
    children: IExtendedTreeItem[];
    data: string;
    emailName: string;
    statuscastId: number;
    product: string;
    service: string;
    description: string;
    isHidden: boolean;
    statuscastGroupId: number;
    peerPriority: number;
    statuscastServiceParentId: number;
}

export interface ITreeItem {
  displayName: string;
  emailName: string;
  statuscastId: number;
  product?: string;
  service: string;
  isHidden?: boolean;
  statuscastGroupId: number;
  peerPriority: number;
  statuscastServiceParentId: number;
  children?: ITreeItem[];
  isMainComponent: boolean;
  isDeletion?: string;
  orchestrationId?: string;
}

export interface ITemplate {
    // Define the structure of ITemplate based on your requirements
  }

export const TREE_NODE_CONSTANTS = {
  UPDATE_INPROGRESS: 'Service updation in progress...',
  ALERT_CONFIRM_TEXT: 'Are you sure ?',
  ALERT_CONFIRM_EMAIL: 'Do you really want to delete the service ',
  ALERT_CONFIRM_EMAIL_NOTE:
    '[Note: Deleting this will delete the service and all its child services]',
  DELETE_API_ERROR: 'Something went wrong',
  DELETE_SERVICE_SUCCESS:
    'Service deletion initiated successfully! This action can be re-done within 15 minutes.',
  DELETE_ERROR: 'Service deletion failed',
  DELETE_UNAUTHORIZED: 'Unauthorized to delete the service',
  DELETE_CONFIRM_TEXT: 'Delete',
  CANCEL_TEXT: 'Cancel',
  RESTORE_CONFIRM_TEXT: 'Restore',
  RESTORE_SERVICE_SUCCESS: 'Service has been restored successfully!',
  RESTORE_ERROR: 'Service restoration failed',
  RESTORE_UNAUTHORIZED: 'Unauthorized to restore the service',
};