export type IncidentStats = {
  outageCount: number;
  partialOutageCount: number;
  plannedMaintenanceCount: number;
};

export const STATS_CONSTANTS: { [key in keyof IncidentStats]: string } = {
  outageCount: 'Outage',
  partialOutageCount: 'Partial Outage / Degradation',
  plannedMaintenanceCount: 'Planned Maintenance',
};
