import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchIncidentDetails } from '../../services/incidentManagement.service';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../components/Common/Loading';
import ErrorPage from '../../components/Common/ErrorPage';
import { IIncident, IService } from './ViewIncidents.constants';
import ResolvedIncidents from './components/ResolvedIncidents';
import ManageIncidents from './components/ManageIncidents';
import { fetchServiceDetails } from '../../services/serviceManagement.service';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function ViewIncidents() {
  const [baseURL, setBaseURL] = useState('incidents');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [incidentDetails, setIncidentDetails] = useState<IIncident[]>([]);
  const [isServiceFetching, setIsServiceFetching] = useState(false);
  const [serviceData, setServiceData] = useState<IService[]>([]);

  const location = useLocation();

  const fetchService = async (product: string, service: string) => {
    try {
      setIsServiceFetching(true);
      const serviceDetails = await fetchServiceDetails(product, service);
      if (serviceDetails.status == 401) {
        toast.error('Unauthorized');
      }
      if (serviceDetails.status != 200) {
        toast.error('Failed to fetch service details');
        logEvent('GET_SERVICE', 'EXCEPTION', {
          service: service,
          product: product,
          actions: 'Retrieve',
          resource: 'Services',
          error: serviceDetails?.data
            ? `"${serviceDetails?.data}"`
            : 'Failed to fetch service details',
        });
      } else {
        setServiceData(() => [serviceDetails.data]);
      }
    } catch (error) {
      toast.error('Failed to fetch service details');
      setIsServiceFetching(true);
    } finally {
      setIsServiceFetching(false);
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const Details = location.state && location.state.baseUrl;
      const IncidentDetails = location.state && location.state.incidentDetails;
      if (IncidentDetails) {
        setIncidentDetails(() => [IncidentDetails]);
        fetchService(IncidentDetails.product, IncidentDetails.service);
        setIsLoading(false);
      } else {
        const incidentId: string = location.pathname.split('/')[2];

        const incidentResponse = await fetchIncidentDetails(incidentId);

        if (incidentResponse.status === 200) {
          setIsLoading(false);
          if (incidentResponse.data.length === 0) {
            setIsError(true);
          } else {
            setIncidentDetails(incidentResponse.data);
            fetchService(
              incidentResponse.data[0].product,
              incidentResponse.data[0].service
            );
          }
        } else {
          toast.error('Failed to fetch Incident');
          logEvent('GET_INCIDENT', 'EXCEPTION', {
            incidentID: incidentId,
            actions: 'Retrieve',
            resource: 'Incidents',
            error: incidentResponse?.data
              ? `"${incidentResponse?.data}"`
              : 'Failed to fetch all Incident',
          });
        }
      }

      if (Details && Details.url) {
        const basePathURL = Details.url;
        if (basePathURL) {
          setBaseURL(basePathURL);
        }
      }
    } catch (error) {
      toast.error('Failed to fetch Incidents');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="main-tab-container">
        {isLoading || isServiceFetching ? (
          <Loading />
        ) : (
          <div>
            {isError ? (
              <ErrorPage />
            ) : (
              <div>
                {incidentDetails[0] == undefined ||
                serviceData[0] == undefined ? (
                  <Loading />
                ) : incidentDetails[0].incidentState === 'Resolved' ? (
                  <ResolvedIncidents
                    incidentDetails={incidentDetails[0]}
                    serviceDetails={serviceData[0]}
                  />
                ) : (
                  <ManageIncidents
                    incidentDetails={incidentDetails[0]}
                    serviceDetails={serviceData[0]}
                    parentURL={baseURL}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
}

export default ViewIncidents;
