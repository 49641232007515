import { env as devEnv } from "./dev.config";
import { env as stgEnv } from "./stg.config";
import { env as prodEnv } from "./prod.config";
import { env as localEnv } from "./local.config";

var appEnv: any;
const __ENVIRON__ = process.env.REACT_APP_ENV;
if (__ENVIRON__ === "production") {
  appEnv = prodEnv;
} else if (__ENVIRON__ === "staging") {
  appEnv = stgEnv;
} else if (__ENVIRON__ === "local") {
  appEnv = localEnv;
} else if (__ENVIRON__ === "development") {
  appEnv = devEnv;
}

export default appEnv;
