import { TreeView, TreeViewItem } from '@trimbleinc/modus-react-bootstrap';
import { Node } from './ServiceTree.constants';

interface ServiceTreeProps {
  serviceTree: Node[];
  onSelectNode: (selectedNode: Node) => void;
}

function ServiceTree({ serviceTree, onSelectNode }: ServiceTreeProps) {
  const buildTreeViewItems = (nodes: Node[]): React.ReactNode => {
    return nodes.map((node) => (
      <TreeViewItem
        key={node.nodeId}
        nodeId={node.nodeId}
        label={node.label}
        className="treeviewitem"
      >
        {node.children && buildTreeViewItems(node.children)}
      </TreeViewItem>
    ));
  };

  const onNodeSelectHandle = (
    event: React.SyntheticEvent,
    selectedNodes: number[],
    nodeInFocus: number
  ) => {
    const selectedNode = findNodeById(serviceTree, selectedNodes[0]);
    if (selectedNode) {
      onSelectNode(selectedNode);
    }
  };

  const findNodeById = (nodes: Node[], nodeId: number): Node | null => {
    for (let node of nodes) {
      if (node.nodeId === nodeId) {
        return node;
      }
      if (node.children) {
        const found = findNodeById(node.children, nodeId);
        if (found) {
          return found;
        }
      }
    }
    return null;
  };

  return (
    <div>
      <TreeView
        nodeId={0}
        id="borderless"
        size="sm"
        className="list-group-borderless treeview"
        onNodeSelect={onNodeSelectHandle}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        {buildTreeViewItems(serviceTree)}
      </TreeView>
    </div>
  );
}

export default ServiceTree;
