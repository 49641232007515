import './App.css'
import AppRoutes from './components/Common/Content/AppRoutes'

function App() {
  return (
    <div className="App">
    <AppRoutes />
    </div>
  )
}

export default App
