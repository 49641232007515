import ReactDOM from 'react-dom/client'
import App from './App'
import 'regenerator-runtime/runtime'
import './index.css'
import "bootstrap/dist/css/bootstrap.css";
import '@trimbleinc/modus-react-bootstrap/css/dist/modus-react-bootstrap.min.css';
import InitLogger from 'components/Common/InitLogger/InitLogger';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
  {localStorage.getItem('auth') && <InitLogger />}
  <App />
  </>
);
