import { Navbar, ListGroup} from '@trimbleinc/modus-react-bootstrap';
import { Link, useLocation } from "react-router-dom";
import './styles.scss'
import { useState } from 'react';
import { MenuType, SideBarProps } from './SideBar.constants';
import { ClassNames } from '@emotion/react';
import RouterPath from 'config/routerPath';

const SideBar = (props: SideBarProps) => {
    const location = useLocation();
    const getMenuType = () => {
        let path = location.pathname.split("/")[1];
        switch (true) {
            case path === "":
                return MenuType.DASHBOARD;
            case path.includes(MenuType.INCIDENTS.valueOf().toLowerCase()):
                return MenuType.INCIDENTS;
            case path.includes(MenuType.SERVICES.valueOf().toLowerCase()):
                return MenuType.SERVICES;
            case path.includes(MenuType.USER.valueOf().toLowerCase()):
                return MenuType.USER;
            case path.includes(MenuType.DASHBOARD.valueOf().toLowerCase()):
                return MenuType.DASHBOARD;
            case path.includes(MenuType.CONTACTS.valueOf().toLowerCase()):
                return MenuType.CONTACTS;
        }
    };

    const [menuType, setMenuType] = useState(getMenuType());
    const menuClick = (mentType: MenuType) => {
        setMenuType(mentType);
        props.toggleMenuCollapse(true);
    };

    return (
      <>
        <Navbar
          className="nav flex-column modus-sidebar app-sidebar"
          onMouseEnter={() => {
            props.toggleMenuCollapse(false);
          }}
          onMouseLeave={() => props.toggleMenuCollapse(true)}
        >
          <ListGroup className="app-sidebar-list">
            <ListGroup.Item
              active={menuType === MenuType.DASHBOARD ? true : false}
              onClick={() => menuClick(MenuType.DASHBOARD)}
              className="app-sidebar-listitem"
            >
              <Link
                className={`nav-link ${
                  menuType === MenuType.DASHBOARD ? 'active' : ''
                }`}
                to={RouterPath.PRIVATE_ROUTE.dashboard}
              >
                <span className="left-nav-icon">
                  <i className="modus-icons" aria-hidden="true">
                    dashboard
                  </i>
                </span>
                Dashboard
              </Link>
            </ListGroup.Item>

            <ListGroup.Item
              active={menuType === MenuType.INCIDENTS ? true : false}
              onClick={() => menuClick(MenuType.INCIDENTS)}
              className="app-sidebar-listitem"
            >
              <Link
                className={`nav-link ${
                  menuType === MenuType.INCIDENTS ? 'active' : ''
                }`}
                to={RouterPath.PRIVATE_ROUTE.incidentManagement}
              >
                <span className="left-nav-icon">
                  <i className="modus-icons" aria-hidden="true">
                    alert_outlined
                  </i>
                </span>
                Incidents
              </Link>
            </ListGroup.Item>

            <ListGroup.Item
              active={menuType === MenuType.SERVICES ? true : false}
              onClick={() => menuClick(MenuType.SERVICES)}
              className="app-sidebar-listitem"
            >
              <Link
                className={`nav-link ${
                  menuType === MenuType.SERVICES ? 'active' : ''
                }`}
                to={RouterPath.PRIVATE_ROUTE.serviceManagement}
              >
                <span className="left-nav-icon">
                  <i className="modus-icons" aria-hidden="true">
                    gears
                  </i>
                </span>
                Services
              </Link>
            </ListGroup.Item>

            <ListGroup.Item
              active={menuType === MenuType.CONTACTS ? true : false}
              onClick={() => menuClick(MenuType.CONTACTS)}
              className="app-sidebar-listitem"
            >
              <Link
                className={`nav-link ${
                  menuType === 'contacts' ? 'active' : ''
                }`}
                to={RouterPath.PRIVATE_ROUTE.contactManagement}
              >
                <span className="left-nav-icon">
                  <i className="modus-icons" aria-hidden="true">contacts</i>
                </span>
                Contacts
              </Link>
            </ListGroup.Item>

            <ListGroup.Item
              active={menuType === MenuType.USER ? true : false}
              onClick={() => menuClick(MenuType.USER)}
              className="app-sidebar-listitem"
            >
              <Link
                className={`nav-link ${menuType === 'users' ? 'active' : ''}`}
                to={RouterPath.PRIVATE_ROUTE.userManagement}
              >
                <span className="left-nav-icon">
                  <i className="modus-icons" aria-hidden="true">
                    people_group
                  </i>
                </span>
                Users
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Navbar>
      </>
    );

}
export default SideBar;

