import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Modal, OverlayTrigger, Row, Tooltip } from '@trimbleinc/modus-react-bootstrap';
import './styles.scss';
import Swal from 'sweetalert2';
import { CREATE_FORM_CONSTANTS } from './CreateServiceForm.constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { useForm } from "react-hook-form";
import AddLanguageModal from 'components/Common/AddLanguageModal/AddLanguageModal';
import { createService } from 'services/serviceManagement.service';
import Loading from 'components/Common/Loading';
import { domoColumns, transformMessageType } from 'constants/MessageType';
import { set } from 'date-fns';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function CreateServiceForm() {

  const navigate = useNavigate();
  const { register, handleSubmit, reset, unregister, trigger, setValue, formState, formState: { errors } } = useForm({
    defaultValues: {
      [CREATE_FORM_CONSTANTS.MAIN_COMPONENT.KEY]: "",
    }
  });

  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isMainComponent, setIsMainComponent] = useState(true);
  const [addLanguage, setAddLanguage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const modalRef = useRef<any>(null);


  const handleRadioChange = async (e: { target: { value: string; }; }) => {
    setIsMainComponent(e.target.value === "true");
    await trigger(CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.KEY);
    if (e.target.value === "true") {
      unregister(CREATE_FORM_CONSTANTS.MAIN_COMPONENT.KEY);
    }

  };

  const initiateCancel = () => {
    Swal.fire({
      title: `<h4>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'error',
      html: `<p>${CREATE_FORM_CONSTANTS.ALERT_CONFIRM_CLOSE}<br/>`,
      showCancelButton: true,
      confirmButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_CANCEL_TEXT,
      cancelButtonText: CREATE_FORM_CONSTANTS.CONFIRM_CLOSE_DISCARD_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          navigate(RouterPath.PRIVATE_ROUTE.serviceManagement);
        } catch (error) {
          toast.error('Something went wrong');
        }
      },
    });
  };

  const initiateReset = () => {
    setIsMainComponent(true);
    setTemplate([]);
    if (modalRef.current) {
      modalRef.current.clearEditorValues();
    }
    reset();
  }

  const defaultMessageTypes = [
    'New_Outage', 'Updating_Outage_1', 'Updating_Outage_2', 'Resolved_Outage',
    'New_PlannedMaintenance', 'Updating_PlannedMaintenance_1', 'Resolved_PlannedMaintenance',
    'New_PartialOutage', 'Updating_PartialOutage_1', 'Updating_PartialOutage_2', 'Resolved_PartialOutage',
    'Transition_Outage', 'Transition_PartialOutage', "Outage_ESC1", "Outage_ESC2", "PartialOutage_ESC1", "PartialOutage_ESC2", "falseAlarm","Disaster_Recovery"
  ];

  const onSubmit = async (data: any) => {
    try {
      if (data.isMainComponent === 'true' && (!template || template.length === 0)) {
        toast.error('Please configure templates for main component');
        return;
      }
      const cleanedData = {
        ...data,
        isHidden: data.isHidden === "true",
        isMainComponent: data.isMainComponent === "true",
        peerPriority: Number(data.peerPriority),
      };
      let requestBody;
      if (template && template.length > 0) {
        requestBody = {
          ...cleanedData,
          language: template[0].language,
          template: template
        };
      } else {
        requestBody = { ...cleanedData };
      }
      setIsLoading(true);
      let createResponse = await createService(requestBody)
      setIsLoading(false);
      if (createResponse.status == 200) {
        logEvent('NEW_SERVICE', 'USER_ACTIVITY', {
          service: requestBody.service,
          product: requestBody.product,
          actions: 'Create',
          resource: 'Services'
        });

        // Check if templates are present in the request body
        if (requestBody.template && requestBody.template.length > 0) {
          const domoData = {
            service: requestBody.service,
            product: requestBody.product,
            actions: 'Create',
            templateLanguage: requestBody.template[0].language
          };

          const typeMessages = requestBody.template[0].typeMessages;

          typeMessages.forEach((message: { type: string; message: string }) => {
            for (let [key, value] of Object.entries(domoColumns)) {
              if (message.type === value) {
                (domoData as any)[key] = `"${message.message.replace(/"/g, '""')}"`;
              }
            }
          });

          // Log the template addition event
          logEvent('NEW_TEMPLATE', 'USER_ACTIVITY', domoData);
        }
        Swal.fire({
          title: `<h4>Success!<h4>`,
          html: `<p>Service ${createResponse.data}</p>`,
          icon: 'success',
          preConfirm: () => { navigate(RouterPath.PRIVATE_ROUTE.serviceManagement) }
        });
      }
      if (createResponse.status == 400 || createResponse.status == 500) {
        toast.error(createResponse?.data ?? "Failed to fetch services")
        logEvent('NEW_SERVICE', 'EXCEPTION', {
          service: requestBody.service,
          product: requestBody.product,
          actions: 'Create',
          resource: 'Services',
          error: createResponse?.data ? `"${createResponse?.data}"` : 'Failed to fetch services'
        });
      }
      if (createResponse.status == 401) {
        toast.error("Unauthorized")
      }
    } catch (error) {
      toast.error('Error creating service');
    }
  }
  useEffect(() => {
    if (Object.keys(formState.errors)?.length == 0) {
      setIsFormValid(true)
    } else {
      setIsFormValid(false)
    }
  }, [formState])

  const location = useLocation();
  const [names, setNames] = useState<string[]>([]);
  const createData = async () => {
    try {
      const createDetails = location.state?.item;
      if (createDetails && createDetails?.children) {
        setIsMainComponent(false)
        setValue(CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.KEY, "false");
        const childrenLength = createDetails.children.length;
        const newDefaultPeerPriority = childrenLength + 1;
        setValue(CREATE_FORM_CONSTANTS.PEER_PRIORITY.KEY, newDefaultPeerPriority);
      }
      if (!createDetails?.isMainComponent) {
        setValue(CREATE_FORM_CONSTANTS.PRODUCT_NAME.KEY, createDetails?.service);
      }
      if (location.state?.data) {
        setNames(getMainComponent(location.state.data));
      }
      else if (location.state?.names) {
        setNames(location.state.names);
      }
    } catch (error) {
      toast.error('Failed to fetch default create data values');
    }
  };

  const getMainComponent = (data: any) => {
    let parentNames: any[] = [];
    parentNames = data.map((array: { product: any; }) => array.product);
    return parentNames;
  }

  useEffect(() => {
    createData();
  }, []);

  //LANGUAGE MODAL
  const [source, setSource] = useState('');
  const [langShow, setLangShow] = useState(false);
  const handleLangClose = () => {
    setLangShow(false);
    if (source === 'template') {
      setTemplate([]);
      if (modalRef.current) {
        modalRef.current.clearEditorValues();
      }
    }
  }
  const handleLangShow = () => {
    setLangShow(true);
  }
  const handleLangDelete = () => {
    setTemplate([]);
    if (modalRef.current) {
      modalRef.current.clearEditorValues();
    }
  }

  const handleAddLanguageClick = (source: string) => {
    setSource(source);
    setAddLanguage(!addLanguage);
    handleLangShow()
  }
  const [template, setTemplate] = useState<{ language: string; typeMessages: any; }[]>([]);
  const handleSaveTemplate = (rawMessage: any, language: string) => {
    const typeMessages = rawMessage.map((message: { subject: string, message: string; }) => ({
      ...message,
      subject: typeof message.subject === 'string' ? message.subject.replace(/<p>|<\/p>|<br>/g, '') : '',
      message: typeof message.message === 'string' ? message.message.replace(/<p>\s*<br>\s*<\/p>/g, '') : ''
    }));
    const newTemplate = {
      language,
      typeMessages,
    };
    setTemplate([newTemplate]);
    setLangShow(false);
  };

  const [previewShow, setPreviewShow] = useState(false);
  const handlePreviewClose = () => {
    setPreviewShow(false);
  }
  const handlePreviewShow = () => setPreviewShow(true);

  return (
    <>
      <div className="main-tab-container">
        {isLoading &&
          <div className="overlay">
            <Loading /></div>}
        <h1 className="heading">Create Service</h1>
        <div className="create-service">
          <Form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="create-service-card">
              <Row className="form-row">
                <Col md={6}>
                  <Form.Label><h4>{CREATE_FORM_CONSTANTS.NAME.LABEL}</h4></Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicName">
                    <Form.Control
                      as="input"
                      placeholder={CREATE_FORM_CONSTANTS.NAME.PLACEHOLDER}
                      className={`input-border`}
                      {...register(CREATE_FORM_CONSTANTS.NAME.KEY, {
                        required: { value: true, message: CREATE_FORM_CONSTANTS.NAME.ERROR_TEXT }
                      })}
                    />
                    {errors?.[CREATE_FORM_CONSTANTS.NAME.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.NAME.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={6}>
                  <Form.Label><h4>{CREATE_FORM_CONSTANTS.EMAIL_NAME.LABEL}</h4></Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicRegion">
                    <Form.Control
                      as="input"
                      placeholder={CREATE_FORM_CONSTANTS.EMAIL_NAME.PLACEHOLDER}
                      className={`input-border`}
                      {...register(CREATE_FORM_CONSTANTS.EMAIL_NAME.KEY, {
                        required: { value: true, message: CREATE_FORM_CONSTANTS.EMAIL_NAME.ERROR_TEXT }
                      })}
                    />
                    {errors?.[CREATE_FORM_CONSTANTS.EMAIL_NAME.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.EMAIL_NAME.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={6}>
                  <Form.Label><h4>{CREATE_FORM_CONSTANTS.DESCRIPTION.LABEL}</h4></Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicDescription">
                    <Form.Control
                      as="input"
                      placeholder={CREATE_FORM_CONSTANTS.DESCRIPTION.PLACEHOLDER}
                      className={`input-border`}
                      {...register(CREATE_FORM_CONSTANTS.DESCRIPTION.KEY, {
                        required: { value: true, message: CREATE_FORM_CONSTANTS.DESCRIPTION.ERROR_TEXT }
                      })}
                    />
                    {errors?.[CREATE_FORM_CONSTANTS.DESCRIPTION.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.DESCRIPTION.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={6}>
                  <Form.Label><h4>{CREATE_FORM_CONSTANTS.PRODUCT_NAME.LABEL}</h4></Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicCode">
                    <Form.Control
                      as="input"
                      placeholder={CREATE_FORM_CONSTANTS.PRODUCT_NAME.PLACEHOLDER}
                      className={`input-border`}
                      {...register(CREATE_FORM_CONSTANTS.PRODUCT_NAME.KEY, {
                        required: { value: true, message: CREATE_FORM_CONSTANTS.PRODUCT_NAME.ERROR_TEXT }
                      })}
                    />
                    {errors?.[CREATE_FORM_CONSTANTS.PRODUCT_NAME.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.PRODUCT_NAME.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={6}>
                  <Form.Label><h4>{CREATE_FORM_CONSTANTS.SERVICE_NAME.LABEL}</h4></Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicAdditionalCode">
                    <Form.Control
                      as="input"
                      placeholder={CREATE_FORM_CONSTANTS.SERVICE_NAME.PLACEHOLDER}
                      className={`input-border`}
                      {...register(CREATE_FORM_CONSTANTS.SERVICE_NAME.KEY, {
                        required: { value: true, message: CREATE_FORM_CONSTANTS.SERVICE_NAME.ERROR_TEXT }
                      })}
                    />
                    {errors?.[CREATE_FORM_CONSTANTS.SERVICE_NAME.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.SERVICE_NAME.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={6}>
                  <Form.Label>
                    <h4>{CREATE_FORM_CONSTANTS.PEER_PRIORITY.LABEL}
                      <OverlayTrigger
                        key="overlay2"
                        placement="right"
                        overlay={<Tooltip id="icontooltip">{CREATE_FORM_CONSTANTS.PEER_PRIORITY.TOOLTIP}</Tooltip>}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mi-outline mi-info-outlined" viewBox="0 0 24 24" style={{ marginLeft: '5px', marginBottom: '2px' }}>
                          <path d="M12 9.03c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0 13c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1" />
                        </svg>
                      </OverlayTrigger>
                    </h4>
                  </Form.Label>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="formBasicAdditionalCode">
                    <Form.Control
                      type="number"
                      placeholder={CREATE_FORM_CONSTANTS.PEER_PRIORITY.PLACEHOLDER}
                      min="1"
                      className={`input-border`}
                      {...register(CREATE_FORM_CONSTANTS.PEER_PRIORITY.KEY, {
                        max: { value: CREATE_FORM_CONSTANTS.COUNT_VALIDATION.VALUE, message: CREATE_FORM_CONSTANTS.COUNT_VALIDATION.ERROR_TEXT },
                        required: { value: true, message: CREATE_FORM_CONSTANTS.PEER_PRIORITY.ERROR_TEXT },
                        pattern: {
                          value: /^[1-9]*$/,
                          message: CREATE_FORM_CONSTANTS.COUNT_VALIDATION.WHOLE_NUMBER_TEXT
                        }
                      })}
                    />
                    {errors?.[CREATE_FORM_CONSTANTS.PEER_PRIORITY.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.PEER_PRIORITY.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={6}>
                  <Form.Label><h4>{CREATE_FORM_CONSTANTS.IS_HIDDEN.LABEL}
                    <OverlayTrigger
                      key="overlay2"
                      placement="right"
                      overlay={<Tooltip id="icontooltip">{CREATE_FORM_CONSTANTS.IS_HIDDEN.TOOLTIP}</Tooltip>}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="mi-outline mi-info-outlined" viewBox="0 0 24 24" style={{ marginLeft: '5px', marginBottom: '2px' }}>
                        <path d="M12 9.03c.55 0 1-.45 1-1s-.45-1-1-1-1 .45-1 1 .45 1 1 1M12 22c5.52 0 10-4.48 10-10S17.52 2 12 2 2 6.48 2 12s4.48 10 10 10m0-18c4.41 0 8 3.59 8 8s-3.59 8-8 8-8-3.59-8-8 3.59-8 8-8m0 13c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1" />
                      </svg>
                    </OverlayTrigger></h4></Form.Label>
                </Col>
                <Col md={6}>
                  <fieldset>
                    <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                      <Form.Check
                        type="radio"
                        label="True"
                        id="hidden-true"
                        value="true"
                        custom
                        className="mr-3"
                        {...register(CREATE_FORM_CONSTANTS.IS_HIDDEN.KEY, {
                          required: { value: true, message: CREATE_FORM_CONSTANTS.IS_HIDDEN.ERROR_TEXT }
                        })}
                      />
                      <Form.Check
                        type="radio"
                        label="False"
                        id="hidden-false"
                        value="false"
                        custom
                        {...register(CREATE_FORM_CONSTANTS.IS_HIDDEN.KEY, {
                          required: { value: true, message: CREATE_FORM_CONSTANTS.IS_HIDDEN.ERROR_TEXT }
                        })}
                      />
                    </Form.Group>
                    {errors?.[CREATE_FORM_CONSTANTS.IS_HIDDEN.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.IS_HIDDEN.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </fieldset>
                </Col>
              </Row>

              <Row className="form-row">
                <Col md={6}>
                  <Form.Label><h4>{CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.LABEL}</h4></Form.Label>
                </Col>
                <Col md={6}>
                  <fieldset>
                    <Form.Group controlId="formBasicAdditionalCode" className="d-flex">
                      <Form.Check
                        type="radio"
                        label="True"
                        id="main-true"
                        value="true"
                        custom
                        className="mr-3"
                        {...register(CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.KEY, {
                          required: { value: true, message: CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.ERROR_TEXT }
                        })}
                        onChange={handleRadioChange}
                      />
                      <Form.Check
                        type="radio"
                        label="False"
                        id="main-false"
                        value="false"
                        custom
                        {...register(CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.KEY, {
                          required: { value: true, message: CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.ERROR_TEXT }
                        })}
                        onChange={handleRadioChange}
                      />
                    </Form.Group>
                    {errors?.[CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.KEY]?.message && (
                      <Form.Control.Feedback type="invalid" className="field-error-message">
                        {errors[CREATE_FORM_CONSTANTS.IS_MAIN_COMPONENT.KEY]?.message?.toString()}
                      </Form.Control.Feedback>
                    )}
                  </fieldset>
                </Col>
              </Row>

              {!isMainComponent && ( // Render dropdown if isMainComponent is false
                <Row className="form-row">
                  <Col md={6}>
                    <Form.Label><h4>{CREATE_FORM_CONSTANTS.MAIN_COMPONENT.LABEL}</h4></Form.Label>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formBasicAdditionalCode">
                      <Form.Control
                        as="select"
                        custom
                        className={`input-border`}
                        {...register(CREATE_FORM_CONSTANTS.MAIN_COMPONENT.KEY, {
                          required: { value: true, message: CREATE_FORM_CONSTANTS.MAIN_COMPONENT.ERROR_TEXT },
                        })}
                      >
                        <option disabled value="">{CREATE_FORM_CONSTANTS.MAIN_COMPONENT.DROP_DOWN_DEFAULT}</option>
                        {names.map((name: string, index: number) => (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        ))}
                      </Form.Control>
                      {errors?.[CREATE_FORM_CONSTANTS.MAIN_COMPONENT.KEY]?.message && (
                        <Form.Control.Feedback type="invalid" className="field-error-message">
                          {errors[CREATE_FORM_CONSTANTS.MAIN_COMPONENT.KEY]?.message?.toString()}
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}

              {!template || template.length === 0 && (
                <Row className="form-row">
                  <Col md={6} />
                  <Col md={6}>
                    <Button style={{ marginTop: '9px' }} variant="tertiary" size="sm" onClick={() => handleAddLanguageClick('template')}>{CREATE_FORM_CONSTANTS.LANGUAGE.ADD_LANGUAGE_BUTTON}</Button>
                    <Form.Text id="currencyHelpBlock" className="drop-down-note" muted>
                      [NOTE: By default the templates from parent service will be used]
                    </Form.Text>
                  </Col>
                </Row>

              )}
              <AddLanguageModal
                ref={modalRef}
                langShow={langShow}
                handleLangClose={handleLangClose}
                defaultMessageTypes={defaultMessageTypes}
                onSaveTemplate={handleSaveTemplate}
              />

              {template && template.length > 0 && (
                <>
                  <Row className="form-row" style={{ marginTop: '20px' }}>
                    <Col md={6}>
                      <Form.Label><h4>New Language</h4></Form.Label>
                    </Col>
                    <Col md={6}>
                      <Button variant="text-primary" className="btn-icon-only mr-2" onClick={handlePreviewShow}>
                        <i className="modus-icons" aria-hidden="true">preview</i>
                      </Button>
                      <Button variant="text-primary" className="btn-icon-only mr-2" onClick={() => handleAddLanguageClick('edit-template')}>
                        <i className="modus-icons" aria-hidden="true">file_edit</i>
                      </Button>
                      <Button variant="text-danger" className="mr-2" onClick={handleLangDelete}>
                        Delete
                      </Button>
                    </Col>
                  </Row>
                </>
              )}

              <Modal size="xl" show={previewShow} centered onHide={handlePreviewClose}>
                <Modal.Header placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} closeButton>
                  <Modal.Title>New language preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-language">
                  {template.map((temp) => (
                    <div key={temp.language} style={{ marginBottom: '20px' }}>
                      <h5>Language: {temp.language}</h5>
                      {temp.typeMessages
                        .filter((values: { message: string, subject: string }) => values.message !== '' || values.subject !== '')
                        .map(({ type, subject, message }: { type: string, subject: string, message: string }) => {
                          const cleanedSubject = subject.replace(/<p>|<\/p>|<br>/g, '');
                          const cleanedMessage = message.replace(/<p>\s*<br>\s*<\/p>/g, '');
                          return (
                            <Row className='form-row' key={type}>
                              <Col md={12}>
                                <h3><strong>{transformMessageType(type)}</strong></h3>
                                <p><strong>Subject:</strong> {cleanedSubject}</p>
                                <p><strong>Message:</strong> {cleanedMessage}</p>
                              </Col>
                            </Row>
                          );
                        })}
                    </div>
                  ))}
                </Modal.Body>
              </Modal>
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ marginTop: '30px' }}
            >
              <Button
                variant="secondary"
                style={{ marginRight: '10px' }}
                onClick={initiateCancel}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                style={{ marginRight: '10px' }}
                onClick={initiateReset}
              >
                Reset
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={!isFormValid}
              >
                <i
                  className="modus-icons material-icons left-icon"
                  aria-hidden="true"
                >
                  save_disk
                </i>
                Create
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default CreateServiceForm;
