import { Button, Col, Form } from '@trimbleinc/modus-react-bootstrap';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { updateEscalationContact } from 'services/groupManagement.service';
import { EDIT_LEVEL_CONSTANTS, IEditLevelProps } from './EditLevelConstants';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function EditLevel({
  value,
  modalClose,
  UpdateData,
  setIsUpdating,
}: IEditLevelProps) {
  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm({});
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (Object.keys(formState.errors)?.length == 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [formState]);

  const onSubmit = async (data: any) => {
    try {
      modalClose();
      setIsUpdating(true);
      setIsSubmitting(true);
      // Convert emails to an array if it's not already
      const emailsArray = Array.isArray(data.userEmailId)
        ? data.userEmailId
        : [data.userEmailId];

      // Convert escalationLevel to an array of numbers
      let level: number = 0;
      if (data.updatedLevel === 'First Internal Escalation (L team)') {
        level = 1;
      } else {
        level = 2;
      }
      const escalationLevelArray = [level];

      // Create a new data object with the transformed values
      const transformedData = {
        emails: emailsArray,
        escalationLevel: escalationLevelArray,
      };
      let response = await updateEscalationContact(transformedData);

      if (response.status == 200) {
        UpdateData();
        toast.success(
          response?.data ?? EDIT_LEVEL_CONSTANTS.MODIFY_LEVEL_SUCCESS
        );
        logEvent('MANAGE_CONTACT', 'USER_ACTIVITY', {
          level: transformedData.escalationLevel[0],
          actions:'Update',
          resource: 'Escalation_Contacts'
        });
      }
      if (response.status == 400 || response.status == 500){
        toast.error(response.data ?? EDIT_LEVEL_CONSTANTS.MODIFY_LEVEL_ERROR);
        logEvent('MANAGE_CONTACT', 'EXCEPTION', {
          level: transformedData.escalationLevel[0],
          actions:'Update',
          resource: 'Escalation_Contacts',
          error: response?.data ?  `"${response?.data}"` : EDIT_LEVEL_CONSTANTS.MODIFY_LEVEL_ERROR
        });
      }
      if (response.status == 401) {
        toast.error(EDIT_LEVEL_CONSTANTS.MODIFY_LEVEL_UNAUTHORIZED);
      }
      setIsSubmitting(false);
    } catch (error) {
      toast.error(EDIT_LEVEL_CONSTANTS.MODIFY_LEVEL_API_ERROR);
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Label htmlFor="inlineFormInputName2" srOnly>
              {EDIT_LEVEL_CONSTANTS.EMAIL.label}
            </Form.Label>
            <Form.Control
              type="input"
              aria-label="email"
              size="lg"
              defaultValue={value.original.emailAddress}
              readOnly
              {...register(EDIT_LEVEL_CONSTANTS.EMAIL.key, {
                required: {
                  value: true,
                  message: EDIT_LEVEL_CONSTANTS.EMAIL.validationMessage,
                },
                maxLength: {
                  value: 250,
                  message: EDIT_LEVEL_CONSTANTS.EMAIL.error_length,
                },
                pattern: {
                  value: /^[A-Z0-9._%+-]+@trimble\.com$/i,
                  message: EDIT_LEVEL_CONSTANTS.EMAIL.validationMessage,
                },
              })}
            ></Form.Control>
            {errors?.[EDIT_LEVEL_CONSTANTS.EMAIL.key]?.message && (
              <Form.Control.Feedback
                type="invalid"
                className="field-error-message"
              >
                {errors[EDIT_LEVEL_CONSTANTS.EMAIL.key]?.message?.toString()}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group as={Col}>
            <Form.Label htmlFor="inlineFormInputName2" srOnly>
              {EDIT_LEVEL_CONSTANTS.LEVEL.label}
            </Form.Label>
            <Form.Control
              as="select"
              custom
              defaultValue={value.original.levels}
              size="lg"
              {...register(EDIT_LEVEL_CONSTANTS.LEVEL.key, {
                required: {
                  value: true,
                  message: EDIT_LEVEL_CONSTANTS.LEVEL.validationMessage,
                },
              })}
            >
              <option disabled value="">
                {EDIT_LEVEL_CONSTANTS.LEVEL.drop_down_default}
              </option>
              {EDIT_LEVEL_CONSTANTS.LEVEL_LIST.map((value) => (
                <option value={value.code} key={value.code}>
                  {value.name}
                </option>
              ))}
            </Form.Control>
            {errors?.[EDIT_LEVEL_CONSTANTS.LEVEL.key]?.message && (
              <Form.Control.Feedback
                type="invalid"
                className="field-error-message"
              >
                {errors[EDIT_LEVEL_CONSTANTS.LEVEL.key]?.message?.toString()}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </Form.Row>
        <Button
          variant="primary"
          className="mr-2 float-right"
          type="submit"
          disabled={!isFormValid || isSubmitting}
        >
          {EDIT_LEVEL_CONSTANTS.MODIFY_LEVEL.label}
        </Button>
      </Form>
    </>
  );
}

export default EditLevel;
