import { toast, ToastContainer } from 'react-toastify';
import ExternalContactUtility from '../ExternalContactUtility';
import Loading from '../../../../../../../../../../../components/Common/Loading';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import { PreviousData, Service } from './UpdateExternalContactConstants';
import { fetchAllServices } from './../../../../../../../../../../../services/serviceManagement.service';
import { fetchSpecificExternalContact } from './../../../../../../../../../../../services/groupManagement.service';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function UpdateExternalContact() {
  const location = useLocation();
  const [data, setData] = useState<Service[]>([]);
  const [isloaded, setIsLoaded] = useState(false);
  const [prevData, setPrevData] = useState<PreviousData[]>([]);

  const fetchData = async () => {
    try {
      const serviceId: string = location.pathname.split('/')[2];

      const [specificServiceResponse, serviceResponse] = await Promise.all([
        fetchSpecificExternalContact(serviceId),
        fetchAllServices(),
      ]);

      if (specificServiceResponse.status === 200) {
        setPrevData(() => specificServiceResponse.data.subscribers);
      } else if (specificServiceResponse.status === 401) {
        toast.error('Unauthorized to access services');
        clearSessionAndRedirect();
      } else {
        toast.error('Failed to fetch external contact');
        logEvent('GET_CONTACT', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'External_Contacts',
          error: serviceResponse?.data ? `"${serviceResponse?.data}"` : 'Failed to fetch external contact'
        });
      }

      if (serviceResponse.status === 200) {
        setData(() => serviceResponse.data);
      } else if (serviceResponse.status == 401) {
        toast.error('Unauthorized to access services');
        clearSessionAndRedirect();
      } else {
        toast.error('Failed to fetch Services');
        logEvent('GET_ALL_SERVICES', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Services',
          error: serviceResponse?.data ? `"${serviceResponse?.data}"` : 'Failed to fetch services'
        });
      }
      setIsLoaded(true);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    setIsLoaded(false);
    const details = location.state;
    if (details?.data) {
      setPrevData(() => [details.data]);
    }
    if (details?.allServices) {
      setData(() => details.allServices);
    } else {
      fetchData();
    }
    setIsLoaded(true);
  }, []);

  return (
    <div className="main-tab-container">
      {isloaded && data.length > 0 && prevData.length > 0 ? (
        <>
          <h1 className="heading">Manage External Contact</h1>
          <ExternalContactUtility data={data} markEntry={prevData[0]} />
          <ToastContainer />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}
export default UpdateExternalContact;
