import { getItemFromLocalStorage } from "utils/LocalStorage.utils";
import { fetchReq } from "./http.service";
import serviceUrls from "./serviceUrls";

const { incidentsURL } = serviceUrls;

export const getConfig = async (type: string) => {
    let url = `${incidentsURL}/api/platformconfigs`;
    if(type)
      url = `${url}?service=${type}`;
    return await fetchReq(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      },
    });
}

export const modifyConfig = async (type: string , data: any) => {
    let url = `${incidentsURL}/api/platformconfigs`;
    if(type)
      url = `${url}?service=${type}`;
    return await fetchReq(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
}