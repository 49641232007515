import { Chip } from '@trimbleinc/modus-react-bootstrap';
import { IChipinputComponentProps } from './ServiceChipsConstants';
import { useState } from 'react';

function ServiceChips({
  selected,
  onCheckboxChange,
}: IChipinputComponentProps) {
  const [chipOpenState, setChipOpenState] = useState<boolean[]>(
    new Array(selected.length).fill(true)
  );

  const toggleChip = (index: number, nodeId: number) => {
    setChipOpenState((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
    onCheckboxChange(nodeId, false);
  };

  return (
    <div>
      {selected.map((value, index) => (
        <Chip
          key={index}
          label={value.label}
          onClose={() => toggleChip(index, value.nodeId)}
          show={chipOpenState[index]}
          variant="solid"
          type="input"
          className="mr-2"
          closeIcon={
            <i className="modus-icons" aria-hidden="true">
              cancel_circle
            </i>
          }
          style={{ margin: '5px' }}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        ></Chip>
      ))}
    </div>
  );
}

export default ServiceChips;
