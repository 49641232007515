import { useEffect, useState } from "react";
import { toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import { fetchServiceDetails } from "services/serviceManagement.service";
import ManageService from "../ManageService/ManageService";
import Loading from "components/Common/Loading";
import logEvent from "utils/TrimbleInsightsLogger.utils";


function EditService({ value }: any) {
    const [serviceData, setServiceData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    const fetchService = async (product: string, service: string) => {
        try {
            const serviceDetails = await fetchServiceDetails(product, service);
            if (serviceDetails.status != 200) {
                toast.error('Failed to fetch service details');
                logEvent('GET_SERVICE', 'EXCEPTION', {
                    service: service,
                    product: product,
                    actions: 'Retrieve',
                    resource: 'Services',
                    error: serviceDetails?.data ? `"${serviceDetails?.data}"` : 'Failed to fetch service details'
                });
            } else {
                setServiceData(serviceDetails.data);
                setIsLoading(false);
            }
        } catch (error) {
            toast.error('Failed to fetch service details');
        }
    };

    const fetchData = async () => {
        try {
            const ServiceDetails = location.state && location.state.item;
            if (ServiceDetails) {
                fetchService(ServiceDetails.product, ServiceDetails.service);
            }

        } catch (error) {
            toast.error('Failed to fetch Service');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);



    return (
        <div className="main-tab-container">
            {isLoading && <Loading />}
            {!isLoading && (
                <>
                    {serviceData && (
                        <ManageService serviceData={serviceData} />
                    )}
                </>
            )}
        </div>
    );
}
export default EditService;