import { Form } from '@trimbleinc/modus-react-bootstrap';

function IncidentSearchBox({ inputValue, onIncidentIdChange }: any) {
  // Function to handle input changes
  const handleInputChange = (event: any) => {
    const { value } = event.target;
    // Regular expression to check if the input is an empty string or contains only numbers
    if (value.trim() === '' || /^[0-9]*$/.test(value.trim())) {
      onIncidentIdChange(value.trim()); // Directly call onIncidentIdChange with the new value
    }
  };

  return (
    <Form className="search-box">
      <Form.Group controlId="formBasicEmail">
        <div className="input-with-icon-left">
          <Form.Control
            as="input"
            size="lg"
            placeholder="Incident ID"
            value={inputValue} // Use the inputValue prop for the input's value
            onChange={handleInputChange} // Set the onChange event handler
          ></Form.Control>
          <div className="input-icon">
            <i className="modus-icon material-icons">search</i>
          </div>
        </div>
      </Form.Group>
    </Form>
  );
}

export default IncidentSearchBox;
