import { Accordion, Card } from '@trimbleinc/modus-react-bootstrap';
import { useState, useEffect, useRef } from 'react';
import { IServiceFilterProps, Node, Service } from './ServiceFilterConstants';
import { toast } from 'react-toastify';
import Loading from 'components/Common/Loading';
import ServiceTree from 'features/IncidentManagement/components/CreateIncident/components/CreateIncidentForm/components/ServiceTree';
import MultipleSearchTree from './component/MultipleSearchTree';
import { Selected } from './component/MultipleSearchTree/MultipleSearchTree.constants';

function ServiceFilter({ onServiceSelect, services }: IServiceFilterProps) {
  const [framedServices, setServices] = useState<Node[]>([]);
  const [isLoadedService, setIsLoadedService] = useState(false);
  const [selectedServiceLabel, setSelectedServiceLabel] =
    useState<string>('All Services');
  const [isExpand, setIsExpand] = useState(false);

  const setService = async () => {
    try {
      const convertedServices = await convertToTreeItems(services);
      setIsLoadedService(true);
      setServices(convertedServices);
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  useEffect(() => {
    setService();
  }, []);

  const convertToTreeItems = async (serviceData: Service[]) => {
    let nodeIdCounter = 1;

    const convertItem = (serviceItem: Service): Node | null => {
      nodeIdCounter++;
      const treeItem: Node = {
        nodeId: nodeIdCounter,
        label: serviceItem.displayName,
        statuscastId: serviceItem.statuscastId,
        statuscastGroupId: serviceItem.statuscastGroupId,
        isHidden: serviceItem.isHidden,
        isSelected: false,
        isDisabled: false,
        isIndeterminate: false,
        isMainComponent: serviceItem.isMainComponent,
      };

      if (serviceItem.children && serviceItem.children.length > 0) {
        treeItem.children = serviceItem.children
          .map(convertItem)
          .filter((child) => child !== null) as Node[];
      }

      return treeItem;
    };

    const treeItems = serviceData
      .map(convertItem)
      .filter((item) => item !== null) as Node[];

    treeItems.unshift({
      nodeId: 0,
      label: 'All Services',
      statuscastId: 0,
      statuscastGroupId: 0,
      isHidden: false,
      isSelected: false,
      isDisabled: false,
      isIndeterminate: false,
      isMainComponent: false,
    });

    return treeItems;
  };

  const handleServiceSelect = (selectedService: Selected[]) => {
    if (
      selectedService.length === 1 &&
      selectedService[0].selectedServiceId === 0
    ) {
      setSelectedServiceLabel('All Services');
    } else {
      const label = selectedService.length + ' Services Selected';
      setSelectedServiceLabel(label);
    }

    if (onServiceSelect) {
      onServiceSelect(selectedService);
    }
  };

  const handleNodeSelect = async (selectedNode: Node[]) => {
    if (selectedNode) {
      setServices(selectedNode);
    }
  };

  const handleAccordionClick = () => {
    setIsExpand(!isExpand);
  };

  const baseElementRef = useRef<HTMLDivElement>(null);
  const targetElementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const baseElement = baseElementRef.current;
    const targetElement = targetElementRef.current;

    if (baseElement && targetElement) {
      const resizeObserver = new ResizeObserver((entries) => {
        // Use requestAnimationFrame to batch updates
        window.requestAnimationFrame(() => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          const baseHeight = baseElement.offsetHeight;
          const newHeight = `${baseHeight + 33}px`;

          if (targetElement.style.height !== newHeight) {
            targetElement.style.height = newHeight;
          }
        });
      });

      resizeObserver.observe(baseElement);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, []);

  return (
    <div>
      <div className='accordion' ref={targetElementRef}>
        <Accordion defaultActiveKey='1' className='accordion-sm'>
          <div className='card-dropdown-filter'>
            <Card
              className={`accordion-layout ${isExpand ? 'expanded' : ''}`}
              style={
                isExpand ? { boxShadow: '0 13px 20px rgba(0, 0, 0, 0.2)' } : {}
              }
            >
              <Accordion.Toggle
                as={Card.Header}
                eventKey='0'
                className='accordion-header-filter-level accordion-search-level'
                onClick={handleAccordionClick}
              >
                <h6
                  className='mb-0'
                  id='collapsible-group-item-1'
                  ref={baseElementRef}
                  style={{
                    fontWeight: 'normal',
                    color: '#6a6e79',
                    marginRight: '10px',
                    marginLeft: '5px',
                  }}
                >
                  {selectedServiceLabel}
                </h6>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey='0'>
                <Card.Body className='custom-scroll'>
                  <div className='custom-card-bg'>
                    {isLoadedService ? (
                      <MultipleSearchTree
                        data={framedServices}
                        handleNodeSelect={handleNodeSelect}
                        statuscastServiceId={[0]}
                        handleSelectedServices={handleServiceSelect}
                      />
                    ) : (
                      <div className='loading-card'>
                        <Loading />
                      </div>
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Accordion>
      </div>
    </div>
  );
}

export default ServiceFilter;
