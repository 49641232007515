export const RADIO_INPUT_CONSTANTS = {
  falseAlarmInput: 'Is this a False Alarm?',
  hideResolveMessageInput: 'Hide Resolve Message',
  yes: 'Yes',
  no: 'No',
  falseAlarmId: 'falseAlarm',
  hideResolveMessageId: 'hideResolveMessage',
};

export interface IRadioInputProps {
  onRadioChange?: (value: boolean) => void;
  value?: boolean;
  isDisabled: boolean;
  type?: string;
}
