export const DROP_DOWN_CONSTANTS = {
  INCIDENT_TYPE: {
    input: 'Incident Type',
    options: ['Outage', 'Partial Outage', 'Planned Maintenance'], // Changed to array
    disabledOptions: '-Select a type-',
    error: 'Please select a incident type',
  },
  INCIDENT_STATE_CREATE: {
    input: 'Incident State',
    options: ['New', 'Resolved'], // Changed to array
    disabledOptions: '-Select a state-',
    error: 'Please select a incident state',
  },
  INCIDENT_STATE_UPDATE: {
    input: 'Incident State',
    options: ['Updating', 'Resolved'], // Changed to array
    disabledOptions: '-Select a state-',
    error: 'Please select a incident state',
  },
  INCIDENT_TYPE_UPDATE: {
    input: 'Incident Type',
    options: ['Outage', 'Partial Outage'], // Changed to array
    disabledOptions: '-Select a type-',
    error: 'Please select a incident type',
  },
  INCIDENT_TYPE_RESOLVED: {
    input: 'Incident Type',
    options: ['Outage', 'Partial Outage', 'Planned Maintenance'], // Changed to array
    disabledOptions: '-Select a type-',
    error: 'Please select a incident type',
  },
  INCIDENT_STATE_RESOLVED: {
    input: 'Incident State',
    options: ['New', 'Resolved'], // Changed to array
    disabledOptions: '-Select a state-',
    error: 'Please select a incident state',
  },
};

export interface IDropDownProps {
  type: string;
  onSelect?: (type: string, value: string) => void;
  isError: boolean;
  value?: string;
  isDisabled: boolean;
}

export var details: any = {};
details['INCIDENT_TYPE'] = DROP_DOWN_CONSTANTS.INCIDENT_TYPE;
details['INCIDENT_STATE_CREATE'] = DROP_DOWN_CONSTANTS.INCIDENT_STATE_CREATE;
details['INCIDENT_STATE_UPDATE'] = DROP_DOWN_CONSTANTS.INCIDENT_STATE_UPDATE;
details['INCIDENT_TYPE_UPDATE'] = DROP_DOWN_CONSTANTS.INCIDENT_TYPE_UPDATE;
details['INCIDENT_TYPE_RESOLVED'] = DROP_DOWN_CONSTANTS.INCIDENT_TYPE_RESOLVED;
details['INCIDENT_STATE_RESOLVED'] = DROP_DOWN_CONSTANTS.INCIDENT_STATE_RESOLVED;