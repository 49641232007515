import { Button, Col, Form, Modal, Row, Toast } from '@trimbleinc/modus-react-bootstrap';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import Editor from '../SummerNote/Editor';
import { CREATE_FORM_CONSTANTS } from 'features/ServiceManagement/components/AddService/components/CreateServiceForm/CreateServiceForm.constants';
import { ToastContainer, toast } from 'react-toastify';
import { getSubjectMacroOptions, transformMessageType } from 'constants/MessageType';

interface AddLanguageProps {
    langShow: boolean;
    handleLangClose: () => void;
    defaultMessageTypes: string[];
    onSaveTemplate: (template: any[], language:string) => void;
}

const AddLanguageModal = forwardRef<{
    clearEditorValues: () => void;
  }, AddLanguageProps>(({ langShow, handleLangClose, defaultMessageTypes, onSaveTemplate }: AddLanguageProps, ref) => {
   const [editorValues, setEditorValues] = useState<{ [key: string]: { subject: string, message: string } }>({});
    const [selectedLanguage, setSelectedLanguage] = useState<string>();

    useImperativeHandle(ref, () => ({
        clearEditorValues() {
          setEditorValues({});
          setSelectedLanguage('');
        }
      }));

      const handleEditorChange = (field: string, subField: string, value: string) => {
        setEditorValues(prevValues => ({
            ...prevValues,
            [field]: {
                ...prevValues[field],
                [subField]: value
            }
        }));
    };

    const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const language = e.target.value;
        setSelectedLanguage(language);
    }

    const handleSave = () => {
        const typeMessages = Object.keys(editorValues).map((type) => ({
            type: type,
            subject: editorValues[type].subject,
            message: editorValues[type].message
        }));
        if(selectedLanguage){
            onSaveTemplate(typeMessages, selectedLanguage);}
        else {
            toast.error('Please enter the language');}
    };

    const handleClose = () => {
        handleLangClose();
    };

    return (
        <>
            <Modal size="xl" show={langShow} centered onHide={handleClose}>
                <Modal.Header placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} closeButton>
                    <Modal.Title>Add Language</Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-language">
                    <Row style={{ marginTop: '10px' }} className="form-row">
                        <Col md={4}>
                            <Form.Label><h4>{CREATE_FORM_CONSTANTS.LANGUAGE.LABEL}</h4></Form.Label>
                        </Col>
                        <Col md={8}>
                            <Form.Group controlId="formBasicCode">
                                <Form.Control
                                    as="input"
                                    className={`input-border`}
                                    placeholder={CREATE_FORM_CONSTANTS.LANGUAGE.PLACEHOLDER}
                                    defaultValue= {selectedLanguage}
                                    onChange={handleLanguageChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    {defaultMessageTypes.map((type) => (
                        <Row className="form-row" key={type}>
                            <Col md={4}>
                                <Form.Label><h4>{transformMessageType(type)}</h4></Form.Label>
                            </Col>
                            <Col md={8}>
                            <Form.Group controlId={`formBasic${type}`}>
                                <Form.Label className="template-label"><h4>Subject:</h4></Form.Label>
                                <Editor
                                    id={`${type}SubjectEditor`}
                                    value={editorValues[type]?.subject || ''}
                                    onChange={(value: any) => handleEditorChange(type, 'subject', value)}
                                    variableMacroOptions={getSubjectMacroOptions(type)}
                                    placeholder="Enter the subject here"
                                />
                            </Form.Group>
                            <Form.Group controlId={`formBasic${type}`}>
                                <Form.Label className="template-label"><h4>Message:</h4></Form.Label>
                                <Editor
                                    id={`${type}TemEditor`}
                                    value={editorValues[type]?.message || ''}
                                    onChange={(value: any) => handleEditorChange(type, 'message', value)}
                                />
                            </Form.Group>
                            </Col>
                        </Row>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSave}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* <ToastContainer /> */}
        </>
    );
});

export default AddLanguageModal;
