export const ESCALATION_CONTACTS_CONSTANTS = {
  CONTACTS_DUMP_SHEET_URL:
    'https://docs.google.com/spreadsheets/d/1fPmUTPS_dduuS_rp-fbY-tqkcY1jkACnb0EDuU01uWY/edit',
};
export type Service = {
  children: Service[];
  displayName: string;
  emailName: string;
  isHidden: boolean;
  product: string;
  service: string;
  isMainComponent: boolean;
  statuscastId: number;
  statuscastGroupId: number;
};
