import { Outlet } from 'react-router-dom';
import ViewerPage from 'features/ViewerPage';
import Loading from 'components/Common/Loading';
import { useEffect, useState, useCallback } from 'react';
import {
  getItemFromLocalStorage,
  setItemInLocalStorage,
} from 'utils/LocalStorage.utils';
import { fetchUsers } from 'services/userMgmt.service';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

const CanAccess = ({ allowedRoles }: any) => {
  const [newRole, setNewRole] = useState(
    () => getItemFromLocalStorage('role') || ''
  );
  const [isLoading, setIsLoading] = useState(true);

  const fetchUser = useCallback(async () => {
    setIsLoading(true);
    const email = JSON.parse(getItemFromLocalStorage('auth')!)?.email;
    const justFetch = getItemFromLocalStorage('justFetch');
    
    if (justFetch && justFetch != 'true' && email && newRole !== '') {
      try {
        const userExists = await fetchUsers({email});
        if(userExists.status !== 200) {
          logEvent('GET_USER', 'EXCEPTION', {
            actions: 'Retrieve',
            resource: 'Users',
            error: userExists?.data
              ? `"${userExists?.data}"`
              : 'Failed to fetch user access details',
          });
        }
        const role =
          userExists.data && userExists.data.length > 0
            ? userExists.data[0].role
            : 'viewer';
        if (role !== newRole) {
          setItemInLocalStorage('role', role);
          setNewRole(role); // Update state only if the role is different
        }
      } catch (error) {
        console.error('Failed to fetch user', error);
      }
    }
    setItemInLocalStorage('justFetch', 'false');
    setIsLoading(false);
  }, [newRole]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  if (isLoading) {
    return (
      <div className="main-tab-container">
        {' '}
        <Loading />
      </div>
    );
  }

  return <>{allowedRoles.includes(newRole) ? <Outlet /> : <ViewerPage />}</>;
};

export default CanAccess;
