export const UPDATE_CONFIG_CONSTANTS = {
    UP1: {
        LABEL: "First Update (hours)",
        KEY: "update1Timer",
        ERROR_TEXT: "Please enter the first update frequency"
    },
    UP2: {
        LABEL: "Second Update (hours)",
        KEY: "update2Timer",
        ERROR_TEXT: "Please enter the second update frequency"
    },
    SUCCESS_MESSAGE: "Update configuration modified successfully",
    FAILURE_MESSAGE: "Failed to modify update configuration",
};