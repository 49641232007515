import {
  Accordion,
  Card,
  Col,
  Form,
  Row,
} from '@trimbleinc/modus-react-bootstrap';
import {
  IServiceSelectProps,
  SERVICE_SELECT_CONSTANTS,
} from './ServiceSelectConstants';
import ServiceChips from '../ServiceChips';
import TreeNode from '../TreeNode';
import { useEffect, useState } from 'react';
import { Selected } from '../../ExternalContactUtilityConsts';
import { Node } from '../../ExternalContactUtilityConsts';

function ServiceSelect({
  isError,
  data,
  handleNodeSelect,
  statuscastServiceId,
}: IServiceSelectProps) {
  const [isExpand, setExpand] = useState<boolean>(false);

  function handlePlaceHolder(): void {
    setExpand(!isExpand);
  }

  const getSelectedLabels = (nodes: Node[]): Selected[] => {
    const selectedLabels: Selected[] = [];

    const traverseNodes = (nodesToTraverse: Node[]) => {
      for (const node of nodesToTraverse) {
        if (node.isSelected && !node.isDisabled) {
          selectedLabels.push({ label: node.label, nodeId: node.nodeId });
        }
        if (node.children) {
          traverseNodes(node.children);
        }
      }
    };

    traverseNodes(nodes);

    return selectedLabels;
  };

  let selectedLabels: Selected[] = [];
  if (data) {
    selectedLabels = getSelectedLabels(data);
  }

  const handleCheckboxChange = (nodeId: number, checked: boolean) => {
    if (data) {
      const updatedData = updateNodeCheckbox(data, nodeId, checked);
      handleNodeSelect(updatedData);
    }
  };

  function updateNodeCheckbox(
    nodes: Node[],
    nodeId: number,
    checked: boolean
  ): Node[] {
    const updatedNodes = nodes.map((node) => {
      if (node.nodeId === nodeId) {
        node.isSelected = checked;
        if (node.children) {
          node.children = updateChildrenCheckbox(node.children, checked);
          if (!node.isMainComponent) {
            updateIntermediateState(node);
          }
        }
      } else if (node.children) {
        node.children = updateNodeCheckbox(node.children, nodeId, checked);
        if (!node.isMainComponent) {
          updateIntermediateState(node);
        }
      }
      return node;
    });

    return updatedNodes;
  }

  function updateChildrenCheckbox(children: Node[], checked: boolean): Node[] {
    return children.map((child) => {
      child.isSelected = checked;
      child.isDisabled = checked;
      if (checked) {
        child.isIndeterminate = !checked;
      }
      if (child.children) {
        child.children = updateChildrenCheckbox(child.children, checked);
      }
      return child;
    });
  }

  function updateIntermediateState(node: Node) {
    if (!node.children) {
      return;
    }

    let selectedCount = 0;
    let indeterminateCount = 0;

    for (const child of node.children) {
      if (child.isSelected) {
        selectedCount++;
      } else if (child.isIndeterminate) {
        indeterminateCount++;
      }
    }

    if (selectedCount === node.children.length) {
      node.isIndeterminate = false;
      node.isSelected = true;
      for (const child of node.children) {
        child.isDisabled = true;
      }
    } else if (selectedCount > 0 || indeterminateCount > 0) {
      node.isIndeterminate = true;
      node.isSelected = false;
    } else {
      node.isIndeterminate = false;
      node.isSelected = false;
    }
  }

  const findNode = (node: Node[], id: number): Node | null => {
    let foundNode: Node | null = null;
    node.forEach((n) => {
      if (n.nodeId === id) {
        foundNode = n;
      } else if (n.children) {
        foundNode = findNode(n.children, id);
      }
    });
    return foundNode;
  };

  const findNodeByID = (id: number, nodes: Node[]): Node | undefined => {
    for (const node of nodes) {
      if (node.statuscastId === id) {
        return node;
      } else if (node.children) {
        const foundNode = findNodeByID(id, node.children);
        if (foundNode) {
          return foundNode;
        }
      }
    }
    return undefined;
  };

  useEffect(() => {
    if (data && statuscastServiceId && statuscastServiceId.length > 0) {
      statuscastServiceId.forEach((id) => {
        const nodeToCheck = findNodeByID(id, data);
        if (nodeToCheck) {
          handleCheckboxChange(nodeToCheck.nodeId, true);
        }
      });
    }
  }, []);

  return (
    <div>
      <Row className="form-row">
        <Col md={6}>
          <h4 className="form-label" style={{ marginTop: '6px' }}>
            {SERVICE_SELECT_CONSTANTS.input}
          </h4>
        </Col>
        <Col md={6}>
          <div>
            <ServiceChips
              selected={selectedLabels}
              onCheckboxChange={handleCheckboxChange}
            />
            {selectedLabels.length > 0 ? (
              <p style={{ marginBottom: '7px' }}></p>
            ) : (
              <p style={{ margin: '0px 0px 0px' }}></p>
            )}
            <div className="accordion">
              <Accordion defaultActiveKey="1" className="accordion-sm">
                <Card
                  className={`accordion-layout-service ${
                    isExpand ? 'expanded' : ''
                  }`}
                  style={
                    isExpand
                      ? { boxShadow: '0 13px 20px rgba(0, 0, 0, 0.2)' }
                      : {}
                  }
                >
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="0"
                    className="accordion-header-service"
                    style={{
                      borderBottom: isExpand ? '1px solid #ffffff' : '',
                    }}
                    onClick={handlePlaceHolder}
                  >
                    <h6
                      className="mb-0"
                      id="collapsible-group-item-1"
                      style={{
                        fontWeight: 'normal',
                        color: isExpand ? '#ffffff' : '#6c757d',
                        marginLeft: '6px',
                        fontSize: '12.5px',
                      }}
                    >
                      {isExpand
                        ? 'service'
                        : SERVICE_SELECT_CONSTANTS.placeholder}
                    </h6>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body className="custom-scroll">
                      <div className="custom-card-bg">
                        {data.map((node) => (
                          <TreeNode
                            key={node.nodeId}
                            node={node}
                            onCheckboxChange={handleCheckboxChange}
                          />
                        ))}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </div>
          </div>
          {isError && (
            <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
              {SERVICE_SELECT_CONSTANTS.error}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
    </div>
  );
}

export default ServiceSelect;
