export const ESCALATION_CONFIG_CONSTANTS = {
    ESC1: {
        LABEL: "First Internal Escalation - L Team (hours)",
        KEY: "escalationLevel1Timer",
        ERROR_TEXT: "Please enter the first escalation frequency"
    },
    ESC2: {
        LABEL: "Second Internal Escalation - VP (hours)",
        KEY: "escalationLevel2Timer",
        ERROR_TEXT: "Please enter the second escalation frequency"
    },
    SUCCESS_MESSAGE: "Escalation configuration updated successfully",
    FAILURE_MESSAGE: "Failed to update escalation configuration",
};
