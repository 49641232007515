import { Button } from '@trimbleinc/modus-react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isUser } from 'utils/RoleCheck.utils';
import RouterPath from 'config/routerPath';

function CreateIncident() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RouterPath.PRIVATE_ROUTE.createIncident);
  };

  return (
    <Button
      variant="primary"
      size="lg"
      onClick={handleClick}
      style={{ borderRadius: '35px' }}
     disabled={isUser()}>
      <i className="modus-icons material-icons left-icon" aria-hidden="true">
        add
      </i>
      Create Incident
    </Button>
  );
}

export default CreateIncident;
