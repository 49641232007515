export const LIST_ESCALATION_CONTACTS = {
  UPDATE: 'Modify Role',
  ALERT_CONFIRM_TEXT: 'Are you sure ?',
  ALERT_CONFIRM_EMAIL: 'Do you really want to delete this contact ? ',
  DELETE_ROLE_API_ERROR: 'Something went wrong',
  DELETE_ROLE_SUCCESS: 'Contact deleted successfully',
  DELETE_ROLE_ERROR: 'Contact deletion failed',
  DELETE_ROLE_UNAUTHORIZED: 'Unauthorized to delete the Contact',
  DELETE_CONFIRM_TEXT: 'Delete',
  DELETE_CANCEL_TEXT: 'Cancel',
  MODIFY_ROLE_LABEL: 'Modify Level',
  DELETE_USER_LABEL: 'Delete Contact',
  MODAL_TITLE: 'Update Level',
  DROPDOWN_OPTIONS: [
    'All Level',
    'First Internal Escalation (L team)',
    'Second Internal Escalation (VP)',
  ],
};

export interface IListEscalationContactsProps {
  addedContactEmail: string;
}
