import { useState } from "react";
import AppNavBar from "./AppNavBar";
import SideBar from "./SideBar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

function Layout() {
    const [menuCollapse, setMenuCollapse] = useState(true)
    const toggleMenuCollapse = (value = !menuCollapse) => {
        setMenuCollapse(value);
    }
    const onMouseMove = (e: any) => {
        const root = document.documentElement;
        root.style.setProperty('--mouse-x', (e.clientX + 20) + 'px');
        root.style.setProperty('--mouse-y', (e.clientY + 20) + 'px');
    }
    return (
        <div className="modus-layout app-container" onMouseMove={onMouseMove}>
            <AppNavBar toggleMenuCollapse={toggleMenuCollapse} />
            <div
                className={`modus-body sidebar-${menuCollapse ? 'closed' : 'open'}`}
                data-modus-item="body"
                id="modusBody"
            >
                <SideBar toggleMenuCollapse={toggleMenuCollapse} />
                <Outlet />
            </div>
            <br></br>
            <br></br>
            <Footer />
        </div>
    )
}
export default Layout;