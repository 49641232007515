import React, { useEffect, useState, useMemo } from 'react'
import { DataTable, Form, Card, Button, Modal } from "@trimbleinc/modus-react-bootstrap"
import Swal from "sweetalert2";
import { toast } from 'react-toastify';
import { LIST_SCREEN_CONSTANTS, IListScreenProps } from './ListScreen.constants';
import Edit from './components';
import { deleteUser, fetchUsers } from "../../../../services/userMgmt.service";
import Loading from '../../../../components/Common/Loading';
import { useNavigate } from 'react-router-dom';
import { getItemFromLocalStorage } from 'utils/LocalStorage.utils';
import { isNotAdmin } from 'utils/RoleCheck.utils';
import logEvent from 'utils/TrimbleInsightsLogger.utils';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import EmailSearchBox from 'components/Common/EmailSearchBox';
import PaginationButton from 'components/Common/PaginationButton';

const Action = ({
    value,
    setIsUpdating,
    currentPage,
    selectedRole,
    emailId,
    fetchUserList
}: any) => {
    const navigate = useNavigate();
    const initiateDelete = () => { //delete function
        let response: any = null;
        Swal.fire({
            title: `<h4>${LIST_SCREEN_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
            icon: 'error',
            html:
                `<p>${LIST_SCREEN_CONSTANTS.ALERT_CONFIRM_EMAIL}<br/>
          ${value.original.userEmail}<p>`,
            showCancelButton: true,
            confirmButtonText: LIST_SCREEN_CONSTANTS.DELETE_CONFIRM_TEXT,
            cancelButtonText: LIST_SCREEN_CONSTANTS.DELETE_CANCEL_TEXT,
            preConfirm: async () => {
                try {
                    Swal.close();
                    setIsUpdating(true);
                    response = await deleteUser(value.original.userEmail);
                    setIsUpdating(false);
                } catch (error) {
                    toast.error(LIST_SCREEN_CONSTANTS.DELETE_ROLE_API_ERROR)
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                if (response && response.status == 200) {
                    updateUserList()
                    toast.success(response?.data ?? LIST_SCREEN_CONSTANTS.DELETE_ROLE_SUCCESS)
                    logEvent('DELETE_USER', 'USER_ACTIVITY', {
                        contactEmail: value.original.userEmail,
                        actions: 'Delete',
                        resource: 'Users'
                    });
                }
                if (response.status == 400 || response.status == 500) {
                    toast.error(response.data ?? LIST_SCREEN_CONSTANTS.DELETE_ROLE_ERROR)
                    logEvent('DELETE_USER', 'EXCEPTION', {
                        contactEmail: value.original.userEmail,
                        actions: 'Delete',
                        resource: 'Users',
                        error: response?.data ? `"${response?.data}"` : LIST_SCREEN_CONSTANTS.DELETE_ROLE_ERROR
                    });
                }
                if (response.status == 401) {
                    toast.error(LIST_SCREEN_CONSTANTS.DELETE_ROLE_UNAUTHORIZED)
                }
            }
            else if (result.isDismissed) {
                navigate('/users')
            }
        });
    }

    //EDIT MODAL
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const updateUserList = () => {
        fetchUserList(currentPage, selectedRole, emailId);
    };

    const isDisabled = (JSON.parse(getItemFromLocalStorage('auth')!)).email !== value.original.userEmail;
    return (
        <>
            <div className="button-group">
                <div className="button-text-container" style={{ marginLeft: '-10px' }}>
                    <Button variant="text-primary" size="sm" onClick={handleShow} disabled={!isDisabled || isNotAdmin()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mi-outline mi-pencil" viewBox="0 0 24 24">
                            <path d="M3.1 17.15c-.1.1-.15.22-.15.36v3.04c0 .28.22.5.5.5h3.04c.13 0 .26-.05.35-.15L17.76 9.99l-3.75-3.75L3.1 17.15Zm11.25-6.8-8.5 8.5a.485.485 0 0 1-.7 0c-.2-.2-.2-.51 0-.71l8.5-8.5c.2-.2.51-.2.71 0s.2.51 0 .71Zm6.31-4.67-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41Z" />
                        </svg>
                        <span className="button-text">{LIST_SCREEN_CONSTANTS.MODIFY_ROLE_LABEL}</span>
                    </Button>
                </div>

                <div className="button-text-container">
                    <Button variant="text-primary" size="sm" onClick={initiateDelete} disabled={!isDisabled || isNotAdmin()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="mi-outline mi-delete" viewBox="0 0 24 24">
                            <path d="M13.5 10c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-3 0c-.28 0-.5.22-.5.5v7c0 .28.22.5.5.5s.5-.22.5-.5v-7c0-.28-.22-.5-.5-.5Zm-4.49 8.99c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2v-12h-12v12Zm2-10h8v10h-8v-10Zm9.99-5h-2.08c-.27 0-.52-.11-.71-.29l-.41-.41a.99.99 0 0 0-.71-.29H9.92c-.27 0-.52.11-.71.29l-.41.41a.99.99 0 0 1-.71.29H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1Z" />
                        </svg>
                        <span className="button-text">{LIST_SCREEN_CONSTANTS.DELETE_USER_LABEL}</span>
                    </Button>
                </div>
            </div>

            <Modal show={show} centered onHide={handleClose}>
                <Modal.Header placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} closeButton>
                    <Modal.Title>{LIST_SCREEN_CONSTANTS.MODAL_TITLE}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Edit value={value} modalClose={handleClose} updateUserList={updateUserList} setIsUpdating={setIsUpdating} />
                </Modal.Body>
            </Modal>

        </>
    )
}

function ListScreen({ addedUserEmail }: IListScreenProps) {
    const [emailId, setEmailId] = useState('');
    const [hasNext, setHasNext] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [selectedRole, setSelectedRole] = useState<string>('');
    const [users, setUsers] = useState<any[]>([]);
    const [isSearchEmail, setIsSearchEmail] = useState(false);
    const [noEmailFound, setNoEmailFound] = useState(false);

    const fetchUserList = async (
        page: number,
        role: string,
        email: string
    ) => {
        try {
            setIsLoaded(false);
            let usersResponse = await fetchUsers({ pageSize: 5, pageNumber: page, role, email });
            if (usersResponse.status == 200) {
                setIsLoaded(true);
                if (
                    usersResponse.data.users &&
                    usersResponse.data.users.length > 0
                ) {
                    setNoEmailFound(false);
                    let updatedUsers = usersResponse.data.users.map((user: { role: string, createdAt: string }) => {
                        return {
                            ...user,
                            role: user.role.toUpperCase(),
                            createdAt: new Date(user.createdAt).toLocaleString(),
                        }
                    });
                    setUsers(updatedUsers)
                    setHasNext(usersResponse.data.hasNextPage);
                } else {
                    setCurrentPage(0);
                    setHasNext(false);
                    setNoEmailFound(true);
                    setUsers([]);
                }
            }
            if (usersResponse.status == 400 || usersResponse.status == 500) {
                toast.error(usersResponse?.data ?? "Failed to fetch users")
                logEvent('GET_ALL_USERS', 'EXCEPTION', {
                    actions: 'Retrieve',
                    resource: 'Users',
                    error: usersResponse?.data ? `"${usersResponse?.data}"` : 'Failed to fetch users'
                });
            }
            if (usersResponse.status == 401) {
                toast.error("Unauthorized to access users")
                clearSessionAndRedirect()
            }
        }
        catch (error) {
            toast.error("Something went wrong")
        }
    }
    const columns = useMemo(
        () => [
            {
                Header: "Email",
                accessor: "userEmail",
                width: 350,
            },
            {
                Header: "Role",
                accessor: "role",
                width: 100,
            },
            {
                Header: "User Creation Date",
                accessor: "createdAt",
                width: 200,
            },
            {
                Header: "Action",
                accessor: "delete",
                width: 250,
                Cell: ({ row }: any) => (
                    <Action
                        value={row}
                        setIsUpdating={setIsUpdating}
                        currentPage={currentPage}
                        selectedRole={selectedRole}
                        emailId={emailId}
                        fetchUserList={fetchUserList}
                    />
                )
            }
        ],
        [setIsUpdating, currentPage, selectedRole, emailId, fetchUserList]
    )

    const onEmailIdChange = (newEmailId: any) => {
        setEmailId(() => newEmailId);
    };

    const handleNext = () => {
        setIsLoaded(false);
        setCurrentPage(currentPage + 1);
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setIsLoaded(false);
            setCurrentPage(currentPage - 1);
        }
    };
    const handleRoleChange = (event: any) => {
        setCurrentPage(0);
        setSelectedRole(event.target.value);
    };

    const searchEmail = () => {
        setCurrentPage(0);
        setIsSearchEmail(!isSearchEmail);
    };

    useEffect(() => {
        fetchUserList(currentPage, selectedRole, emailId);
    }, [currentPage, selectedRole, isSearchEmail, addedUserEmail]);

    return (
      <>
        <Card className='shadow'>
          <div>
            <div className='grid-container-users'>
              <div className='search-box-email grid-items-users'>
                <EmailSearchBox
                  inputValue={emailId}
                  onEmailIdChange={onEmailIdChange}
                  searchEmail={searchEmail}
                />
              </div>
              <div className='roles-dropdown grid-items-users'>
                <Form.Group controlId='exampleForm.SelectCustom'>
                  <Form.Control as='select' custom onChange={handleRoleChange}>
                    {LIST_SCREEN_CONSTANTS.ROLE_LIST.map((value) => (
                      <option value={value.code} key={value.code}>
                        {value.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className='pagination-buttons grid-items-users'>
                <PaginationButton
                  currentPage={currentPage}
                  hasNext={hasNext}
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  isContacts={true}
                />
              </div>
            </div>

            {!isLoaded || isUpdating ? (
              <Loading />
            ) : noEmailFound ? (
              <div>
                <h5>
                  <br></br>No Users Found!
                </h5>
              </div>
            ) : (
              <div className='fixed-header-datatable'>
                <DataTable
                  id='user-list-table'
                  columns={columns}
                  hover
                  style={{ maxHeight: '400px' }}
                  data={users}
                  resizeColumns
                  disablePagination
                ></DataTable>
              </div>
            )}
          </div>
        </Card>
      </>
    );
}
export default ListScreen;