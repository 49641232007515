import { getItemFromLocalStorage } from "../utils/LocalStorage.utils";
import { fetchReq } from "./http.service";
import serviceUrls from "./serviceUrls";

const {
    usersURL
} = serviceUrls;

interface FetchUsersParams {
  pageSize?: number;
  pageNumber?: number;
  role?: string;
  email?: string;
}

export const addUser = async (userDetails: any) => {
    userDetails.createdBy = JSON.parse(getItemFromLocalStorage("auth")!).email;
    const url = `${usersURL}/api/users`;
    return await fetchReq(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      },
      body: userDetails,
    });
}

export const fetchUsers = async({
  pageSize,
  pageNumber,
  role,
  email
}: FetchUsersParams) => {
  let url = `${usersURL}/api/users?`;
  const params = new URLSearchParams();
  if (pageNumber !== undefined) params.append('pageNumber', pageNumber.toString());
  if (pageSize !== undefined) params.append('pageSize', pageSize.toString());
  if (role && role != '') params.append('role', role);
  if (email && email !== '') params.append('userEmail', email);
  url += params.toString();

    return await fetchReq(url, {
        method: "GET",
        headers: {
            'Authorization': `Bearer ${getItemFromLocalStorage("accessToken")}`
        },
    });
};

export const updateRole = async (data: any) => {
    data.modifiedBy = JSON.parse(getItemFromLocalStorage("auth")!).email;
    const url = `${usersURL}/api/users`;
    return await fetchReq(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${getItemFromLocalStorage('accessToken')}`,
      },
      body: JSON.stringify(data),
    });
}

export const deleteUser = async (userEmail: string) => {
    const userEmailId = encodeURIComponent(userEmail);
    const modifiedBy = encodeURIComponent(JSON.parse(getItemFromLocalStorage("auth")!).email);
    const url = `${usersURL}/api/users?userEmailId=${userEmailId}&modifiedBy=${modifiedBy}`;

    return await fetchReq(url, {
        method: "DELETE",
        headers: {
            'Authorization': `Bearer ${getItemFromLocalStorage("accessToken")}`
        },
    });
}
