import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import {
  START_TIME_CONSTANTS,
  IStartDateTimeInputProps,
} from './StartDateTimeInput.constants';
import { Col, Form, Row } from '@trimbleinc/modus-react-bootstrap';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

function StartDateTimeInput({
  onDateTimeChange,
  isError,
  value,
  isDisabled,
}: IStartDateTimeInputProps) {
  const dateTimeValue = value ? dayjs(value) : null;

  const handleDateTimeChange = (newValue: any) => {
    const formattedDateTime = newValue
      ? newValue.format('YYYY-MM-DD HH:mm')
      : null;

    if (onDateTimeChange) {
      onDateTimeChange(formattedDateTime);
    }
  };
  return (
    <Row className="form-row">
      <Col md={6}>
        <h4 className="form-label" style={{ marginTop: '6px' }}>
          {START_TIME_CONSTANTS.input}
        </h4>
      </Col>
      <Col md={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DateTimePicker
              format="YYYY-MM-DD HH:mm"
              ampm={false}
              className="datetime-style"
              sx={{
                '& .MuiInputBase-input': {
                  height: '30px', // Adjust as needed
                  padding: '0px', // Adjust as needed
                  backgroundColor: 'white',
                  '&:focus': {
                    backgroundColor: 'white',
                  },
                },
              }}
              onChange={handleDateTimeChange}
              disabled={isDisabled}
              value={dateTimeValue}
            />
          </DemoContainer>
        </LocalizationProvider>
        {isError && (
          <Form.Control.Feedback
            type="invalid"
            style={{
              display: 'block',
              marginTop: '-12px',
              marginBottom: '15px',
            }}
          >
            {START_TIME_CONSTANTS.error}
          </Form.Control.Feedback>
        )}
      </Col>
    </Row>
  );
}

export default StartDateTimeInput;
