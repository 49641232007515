import { useState } from 'react';
import AddEscalationContacts from './components/AddEscalationContacts';
import ListEscalationContacts from './components/ListEscalationContacts';

function EscalationContacts() {
  const [addedContactEmail, setAddedContactEmail] = useState<string>('');
  return (
    <div>
      <br></br>
      <AddEscalationContacts setAddedContactEmail={setAddedContactEmail} />
      <br></br>
      <ListEscalationContacts addedContactEmail={addedContactEmail} />
    </div>
  );
}

export default EscalationContacts;
