import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';
import { useEffect, useState } from 'react';
import { fetchIncidentMessages } from 'services/statuscastManagement.service';
import { toast } from 'react-toastify';
import {
  statusCastRequestBody,
  IMessageTimelineProps,
} from './MessageTimeline.constants';
import Loading from 'components/Common/Loading';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function MessageTimeline({ incidentId }: IMessageTimelineProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  function formatDate(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}`;
  }

  const fetchMessages = async () => {
    try {
      const messageRequest: statusCastRequestBody = {
        externalId: incidentId,
      };
      const messageResponse = await fetchIncidentMessages(messageRequest);
      if (messageResponse.status === 200) {
        setIsLoading(false);
        if (messageResponse.data.items.length > 0) {
          setData(messageResponse.data.items[0].posts);
        }
      } else {
        toast.error('Failed to fetch message timeline');
        logEvent('GET_INCIDENT_TIMELINE', 'EXCEPTION', {
          incidentId: incidentId,
          actions: 'Retrieve',
          resource: 'Services',
          error: messageResponse?.data
            ? `"${messageResponse?.data}"`
            : 'Failed to fetch service details',
        });
      }
    } catch (error) {
      toast.error('Error fetching messages');
      console.error('Error fetching messages', error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : data.length <= 0 ? (
        <p>No Messages Found</p>
      ) : (
        <Timeline
          sx={{
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {data.map((item: any, index: number) => (
            <TimelineItem>
              <TimelineOppositeContent color='textSecondary'>
                <strong>{formatDate(item.date)}</strong>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color='primary' />
                {index !== data.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>
                {item.text === ' ' ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: 'N/A' }}
                  ></div>
                ) : (
                  <div
                    className='timeline-message-card'
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  ></div>
                )}
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </div>
  );
}

export default MessageTimeline;
