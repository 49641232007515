import { Node } from '../../ExternalContactUtilityConsts';

export const SERVICE_SELECT_CONSTANTS = {
  input: 'Services',
  placeholder: '-Select Service-',
  error: 'Please select atleast one service.',
};

export interface IServiceSelectProps {
  isError: boolean;
  data: Node[];
  handleNodeSelect: any;
  statuscastServiceId: number[] | undefined;
}
