export function isNotAdmin() {
    return localStorage.getItem('role') !== 'admin';
}

export function isAdmin() {
    return localStorage.getItem('role') === 'admin';
}

export function isNotUser() {
    return localStorage.getItem('role') !== 'user';
}

export function isUser() {
    return localStorage.getItem('role') === 'user';
}