import { Navbar } from "@trimbleinc/modus-react-bootstrap";
import { FOOTER_CONSTANTS } from "./Footer.constants";

function Footer () {
  return (
    <Navbar className='nav-color' fixed='bottom'>
      <footer className='footer'>
        <span className='footer-text'>{FOOTER_CONSTANTS.TEXT}</span>
      </footer>
    </Navbar>
  );
}

export default Footer;