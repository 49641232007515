export const INCIDENTS_ID_CONSTANTS = {
  incidentInput: 'Incident ID',
  incidentPlaceholder: 'Incident ID',
  closedIncidentInput: 'Closed Incident ID',
  optional: ' (Optional)',
  closedIncidentPlaceholder: 'Closed Incident ID',
  error: 'Please enter a valid Incident ID.',
};

export interface IIncidentIdInputProps {
  onIdChange?: (id: string) => void;
  isError: boolean;
  value?: string;
  isDisabled: boolean;
  label?: string;
  type?: string;
};
