import { IIncident, IService } from '../../ViewIncidents.constants';

export interface IManageIncidentsProps {
  incidentDetails: IIncident;
  serviceDetails: IService;
  parentURL: string;
}

export const MANAGE_FORM_CONSTANTS = {
  ALERT_CONFIRM_TEXT: 'Are you sure ?',
  ALERT_CONFIRM_CLOSE: 'Do you really want to discard update? ',
  CONFIRM_CLOSE_CANCEL_TEXT: 'Yes',
  CONFIRM_CLOSE_DISCARD_TEXT: 'No',
  UPDATE_INCIDENT_PROMISE_MESSAGE: 'Update Incident in Progress ...',
  RESOLVE_INCIDENT_PROMISE_MESSAGE: 'Resolve Incident in Progress ...',
  UPDATE_INCIDENT_SUCCESS_MESSAGE: 'Incident updated successfully',
  RESOLVE_INCIDENT_SUCCESS_MESSAGE: 'Incident resolved successfully',
};

export interface updateRequest {
  incidentId: string;
  type: string;
  incidentState: string;
  product: string;
  service: string;
  startTimeInUTC: string | null;
  endTimeInUTC?: string;
  message?: string;
  isFalseAlarm?: boolean;
  hideResolveMessage?: boolean;
}

export const MANAGE_INCIDENT_MESSAGE_TYPE = {
  Updating: {
    Outage: 'Updating_Outage_1',
    PartialOutage: 'Updating_PartialOutage_1',
    PlannedMaintenance: 'Updating_PlannedMaintenance_1',
    TransitionOutage: 'Transition_Outage',
    TransitionPartialOutage: 'Transition_PartialOutage',
    FalseAlarm: 'falseAlarm',
  },
  Resolved: {
    Outage: 'Resolved_Outage',
    PartialOutage: 'Resolved_PartialOutage',
    PlannedMaintenance: 'Resolved_PlannedMaintenance',
    FalseAlarm: 'falseAlarm',
  },
};

export var details: any = {};
details['Updating'] = MANAGE_INCIDENT_MESSAGE_TYPE.Updating;
details['Resolved'] = MANAGE_INCIDENT_MESSAGE_TYPE.Resolved;

export const UPDATE_INCIDENT_MACRO_VARIBALES = [
  { value: '${service}', label: 'service' },
  { value: '${plannedTime} UTC', label: 'plannedTime' },
  { value: '${plannedDate}', label: 'plannedDate' },
  { value: '${startTime} UTC', label: 'startTime' },
  { value: '${endTime} UTC', label: 'endTime' },
  { value: '${existingStartTime} UTC', label: 'existingStartTime' },
  { value: '${duration}', label: 'duration' },
];
