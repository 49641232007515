import { Button, Col, Form } from "@trimbleinc/modus-react-bootstrap";
import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getConfig, modifyConfig } from "services/configuration.service"; // This will handle the update call
import { clearSessionAndRedirect } from "utils/Error.utils";
import logEvent from "utils/TrimbleInsightsLogger.utils";
import { UPDATE_CONFIG_CONSTANTS } from "./UpdateConfiguration.constants"; // Updated constants
import Loading from "components/Common/Loading";

function UpdateConfiguration() {
    const { register, handleSubmit, formState, formState: { errors }, watch, reset } = useForm({});
    const [isLoaded, setIsLoaded] = useState(false);
    const [updateConfig, setUpdateConfig] = useState<any>();
    const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    const [isFormValid, setIsFormValid] = useState(false);

    const fetchUpdateConfiguration = async () => {
        try {
            setIsLoaded(false);
            let configResponse = await getConfig('updateTimer');
            setIsLoaded(true);
            if (configResponse.status === 200) {
                const { update1Timer, update2Timer } = configResponse.data[0];
                setUpdateConfig({ update1Timer, update2Timer });
            }
            if (configResponse.status === 400 || configResponse.status === 500) {
                toast.error(configResponse?.data ?? "Failed to fetch update config");
                logEvent('GET_UPDATE_CONFIG', 'EXCEPTION', {
                    actions: 'Retrieve',
                    resource: 'Config',
                    error: configResponse?.data ? `"${configResponse?.data}"` : 'Failed to fetch update config'
                });
            }
            if (configResponse.status === 401) {
                toast.error("Unauthorized to access config");
                clearSessionAndRedirect();
            }
        } catch (error) {
            toast.error("Something went wrong");
        }
    };

    const onSubmit = async (data: any) => {
        try {
            let notificationDurations = {} as any;
            if (data.update1Timer !== updateConfig.update1Timer) {
                notificationDurations.update1NotificationDuration = data.update1Timer;
            }
            if (data.update2Timer !== updateConfig.update2Timer) {
                notificationDurations.update2NotificationDuration = data.update2Timer;
            }
            setIsLoaded(false);
            let updateResponse = await modifyConfig('updateTimer', notificationDurations);
            setIsLoaded(true);
            if (updateResponse.status === 200) {
                toast.success(updateResponse?.message ?? UPDATE_CONFIG_CONSTANTS.SUCCESS_MESSAGE);
                fetchUpdateConfiguration();
                logEvent('MODIFY_UPDATE_CONFIG', 'USER_ACTIVITY', {
                    actions: 'Update',
                    resource: 'Config'
                });
            }
            if (updateResponse.status == 409 || updateResponse.status == 400 || updateResponse.status == 500) {
                toast.error(updateResponse?.data ?? UPDATE_CONFIG_CONSTANTS.FAILURE_MESSAGE);
                logEvent('MODIFY_UPDATE_CONFIG', 'EXCEPTION', {
                    actions: 'Update',
                    resource: 'Config',
                    error: updateResponse?.data ? `"${updateResponse?.data}"` : UPDATE_CONFIG_CONSTANTS.FAILURE_MESSAGE
                });
            }
            if (updateResponse.status == 401) {
                toast.error(updateResponse?.data ?? "Unauthorized to access configurations")
                clearSessionAndRedirect()
            }

        } catch (error) {
            toast.error("Something went wrong");
        }
    };

    const watchedValues = watch();
    useEffect(() => {
        if (updateConfig) {
            const hasChanges = JSON.stringify(watchedValues) !== JSON.stringify(updateConfig);
            setIsSaveButtonEnabled(hasChanges);
        }
    }, [watchedValues, updateConfig]);

    useEffect(() => {
        if (Object.keys(formState.errors)?.length == 0) {
            setIsFormValid(true)
        } else {
            setIsFormValid(false)
        }
    }, [formState])

    useEffect(() => {
        fetchUpdateConfiguration();
    }, []);

    return (
        <>
            <div style={{ marginLeft: '20px', marginRight: '20px' }}>
                {!isLoaded ?
                    (<div className="loading-overlay">
                        <Loading /></div>) :

                    <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <Form.Row>
                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {UPDATE_CONFIG_CONSTANTS.UP1.LABEL}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    aria-label="UP1"
                                    defaultValue={updateConfig.update1Timer}
                                    {...register(UPDATE_CONFIG_CONSTANTS.UP1.KEY, {
                                        required: { value: true, message: UPDATE_CONFIG_CONSTANTS.UP1.ERROR_TEXT },
                                        valueAsNumber: true,
                                        validate: value => value >= 0 || "Please enter a positive value",
                                    })}
                                ></Form.Control>
                                {errors?.[UPDATE_CONFIG_CONSTANTS.UP1.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[UPDATE_CONFIG_CONSTANTS.UP1.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label className='form-label'>
                                    {UPDATE_CONFIG_CONSTANTS.UP2.LABEL}
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    aria-label="UP2"
                                    defaultValue={updateConfig.update2Timer}
                                    {...register(UPDATE_CONFIG_CONSTANTS.UP2.KEY, {
                                        required: { value: true, message: UPDATE_CONFIG_CONSTANTS.UP2.ERROR_TEXT },
                                        valueAsNumber: true,
                                        validate: value => value >= 0 || "Please enter a positive value",
                                    })}
                                ></Form.Control>
                                {errors?.[UPDATE_CONFIG_CONSTANTS.UP2.KEY]?.message && (
                                    <Form.Control.Feedback type="invalid" className="field-error-message">
                                        {errors[UPDATE_CONFIG_CONSTANTS.UP2.KEY]?.message?.toString()}
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Form.Row>
                        <Button
                            variant="primary"
                            className="mr-2 float-right-on-desktop save-button"
                            type="submit"
                            disabled={!isSaveButtonEnabled || !isFormValid}
                        >
                            Save
                        </Button>
                    </Form>
                }
            </div >
        </>
    );
}

export default UpdateConfiguration;
