import { Button } from '@trimbleinc/modus-react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ADD_SERVICE_CONSTANTS } from './AddService.constants';
import { isNotAdmin } from "utils/RoleCheck.utils";
import RouterPath from 'config/routerPath';

const AddService = ({ data }: any) => {
  const navigate = useNavigate();

  const handleAddService = () => {
    const names = getMainComponent(data);
    navigate(RouterPath.PRIVATE_ROUTE.createService, { state: { names } });
  };

  const getMainComponent = (data: any) => {
    let parentNames: any[] = [];
    parentNames = data.map((array: { product: any }) => array.product);
    return parentNames;
  };

  return (
    <Button
      variant="primary"
      size="lg"
      onClick={handleAddService}
      style={{ borderRadius: '35px' }}
     disabled={isNotAdmin()}>
      <i className="modus-icons material-icons left-icon" aria-hidden="true">
        add
      </i>
      Create Service
    </Button>
  );
};

export default AddService;
