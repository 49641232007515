import { Service } from '../../ExternalContactsConstants';

export const LIST_EXTERNAL_CONTACTS_CONSTANTS = {
  UPDATE: 'Modify Role',
  ALERT_CONFIRM_TEXT: 'Are you sure ?',
  ALERT_CONFIRM_EMAIL: 'Do you really want to delete this contact ? ',
  DELETE_EXT_API_ERROR: 'Something went wrong',
  DELETE_EXT_SUCCESS: 'Contact deleted successfully',
  DELETE_EXT_ERROR: 'Contact deletion failed',
  DELETE_EXT_UNAUTHORIZED: 'Unauthorized to delete the Contact',
  DELETE_CONFIRM_TEXT: 'Delete',
  DELETE_CANCEL_TEXT: 'Cancel',
  MODIFY_EXT_LABEL: 'Modify Contact',
  DELETE_USER_LABEL: 'Delete Contact',
  VIEW_SERVICES: 'View',
  VIEW_SERVICES_LABEL: 'Subscribed Services',
};

export interface IListExternalContactsProps {
  services: Service[];
  setServiceIds: React.Dispatch<React.SetStateAction<number[]>>;
}
