import { Button } from '@trimbleinc/modus-react-bootstrap';
import InputEmail from './components/InputEmail';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import {
  EXTERNAL_CONTACT_UTILITY_CONSTANTS,
  IExternalContactUtilityProps,
} from './ExternalContactUtilityConsts';
import ServiceSelect from './components/ServiceSelect';
import { Service } from '../ServiceFilter/ServiceFilterConstants';
import { Node, apiRequestBody } from './ExternalContactUtilityConsts';
import { toast } from 'react-toastify';
import { upsertExternalContact } from 'services/groupManagement.service';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';

function ExternalContactUtilit({
  data,
  markEntry,
}: IExternalContactUtilityProps) {
  const [emailId, setEmailId] = useState('');
  const [isEmailError, setIsEmailError] = useState(false);
  const [treeItems, setTreeItems] = useState<Node[]>([]);
  const [selectedServiceError, setSelectedServiceError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailDisabled, setIsEmailDisabled] = useState(false);
  const [resetKey, setResetKey] = useState(0);
  const navigate = useNavigate();

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/i;
    const maxLength = 250;

    if (
      !emailRegex.test(email) ||
      email.endsWith('.ru') ||
      email.length > maxLength
    ) {
      return false;
    }

    return true;
  };

  const handleEmailChange = (email: string) => {
    setEmailId(email);
    if (email !== '') {
      setIsEmailError(false);
    }
  };

  const initiateCancel = () => {
    Swal.fire({
      title: `<h4>${EXTERNAL_CONTACT_UTILITY_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'error',
      html: `<p>${EXTERNAL_CONTACT_UTILITY_CONSTANTS.ALERT_CONFIRM_CLOSE}<br/>`,
      showCancelButton: true,
      confirmButtonText:
        EXTERNAL_CONTACT_UTILITY_CONSTANTS.CONFIRM_CLOSE_CANCEL_TEXT,
      cancelButtonText:
        EXTERNAL_CONTACT_UTILITY_CONSTANTS.CONFIRM_CLOSE_DISCARD_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          navigate(RouterPath.PRIVATE_ROUTE.contactManagement);
        } catch (error) {
          console.error(error);
        }
      },
    });
  };

  // Function to convert Service data to TreeItem data
  const convertToTreeItems = (serviceData: Service[]) => {
    let nodeIdCounter = 0;

    const convertItem = (serviceItem: Service): Node | null => {
      nodeIdCounter++;
      const treeItem: Node = {
        nodeId: nodeIdCounter,
        label: serviceItem.displayName,
        statuscastId: serviceItem.statuscastId,
        statuscastGroupId: serviceItem.statuscastGroupId,
        isHidden: serviceItem.isHidden,
        isSelected: false,
        isDisabled: false,
        isIndeterminate: false,
        isMainComponent: serviceItem.isMainComponent,
      };

      if (serviceItem.children && serviceItem.children.length > 0) {
        treeItem.children = serviceItem.children
          .map(convertItem)
          .filter((child) => child !== null) as Node[];
      }

      return treeItem;
    };

    return serviceData
      .map(convertItem)
      .filter((item) => item !== null) as Node[]; // Filter out null entries
  };

  const handleNodeSelect = (selectedNodes: Node[]) => {
    setTreeItems(() => selectedNodes);
  };

  // to check if atleast one node is slelected, if not setSelectedServiceError to true
  let isSelected = false;
  const checkIfOneNodeSelected = (Nodes: Node[]) => {
    Nodes.forEach((node) => {
      if (node.isSelected) {
        isSelected = true;
      }
      if (node.children && node.children.length > 0) {
        checkIfOneNodeSelected(node.children);
      }
    });

    return isSelected;
  };

  const validForm = () => {
    const hasMandatoryValues =
      emailId !== '' && checkIfOneNodeSelected(treeItems);
    return hasMandatoryValues;
  };

  const backToHome = () => {
    navigate(RouterPath.PRIVATE_ROUTE.contactManagement);
  };
  
  const handleCreate = async () => {
    setIsLoading(true);
    if (!validateEmail(emailId)) {
      setIsEmailError(true);
      setIsLoading(false);
      return;
    }
    if (!checkIfOneNodeSelected(treeItems)) {
      setSelectedServiceError(true);
      setIsLoading(false);
      return;
    }
    //create Requuest body
    let apiRequestBody: apiRequestBody = {
      emails: [emailId],
      statuscastServiceId: [],
      statuscastGroupId: [],
      displayName: [],
    };

    const createRequestBody = async (Nodes: Node[]) => {
      Nodes.forEach((node) => {
        if (node.isSelected && !node.isDisabled) {
          apiRequestBody.statuscastServiceId.push(node.statuscastId);
          apiRequestBody.statuscastGroupId.push(node.statuscastGroupId);
          apiRequestBody.displayName.push(node.label);
        }
        if (node.children && node.children.length > 0) {
          createRequestBody(node.children);
        }
      });
    };

    await createRequestBody(treeItems);

    // API call to create external contact
    try {
      let createContactResponse = await toast.promise(
        upsertExternalContact(apiRequestBody, false),
        {
          pending: 'Contact Creation in Progress...',
        }
      );
      if (createContactResponse.status == 200) {
        reset();
        toast.success(
          createContactResponse?.message ?? 'Contact Created Successfully'
        );
        logEvent('NEW_CONTACT', 'USER_ACTIVITY', {
          displayNames: `"${apiRequestBody.displayName.join(',')}"`,
          actions:'Create',
          resource: 'External_Contacts'
        });
        setTimeout(() => {
          backToHome();
        }, 5000);
      } else if (createContactResponse.status == 401) {
        toast.error(
          createContactResponse?.data ?? 'Unauthorized to access contacts page'
        );
        clearSessionAndRedirect();
      } else {
        toast.error(createContactResponse?.data ?? createContactResponse.data);
        logEvent('NEW_CONTACT', 'EXCEPTION', {
          displayNames: `"${apiRequestBody.displayName.join(',')}"`,
          actions:'Create',
          resource: 'External_Contacts',
          error: createContactResponse?.data ? `"${createContactResponse?.data}"` : 'Failed to add External Contact'
        });
      }
    } catch (error) {
      toast.error(String(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleModify = async () => {
    setIsLoading(true);
    if (!validateEmail(emailId)) {
      setIsEmailError(true);
      setIsLoading(false);
      return;
    }
    if (!checkIfOneNodeSelected(treeItems)) {
      setSelectedServiceError(true);
      setIsLoading(false);
      return;
    }
    //create Requuest body
    let apiRequestBody: apiRequestBody = {
      emails: [emailId],
      statuscastServiceId: [],
      statuscastGroupId: [],
      displayName: [],
    };


    const backToHome = () => {
      navigate(RouterPath.PRIVATE_ROUTE.contactManagement);
    };
    
    const createRequestBody = async (Nodes: Node[]) => {
      Nodes.forEach((node) => {
        if (node.isSelected && !node.isDisabled) {
          apiRequestBody.statuscastServiceId.push(node.statuscastId);
          apiRequestBody.statuscastGroupId.push(node.statuscastGroupId);
          apiRequestBody.displayName.push(node.label);
        }
        if (node.children && node.children.length > 0) {
          createRequestBody(node.children);
        }
      });
    };

    await createRequestBody(treeItems);

    // API call to create external contact
    try {
      let createContactResponse = await toast.promise(
        upsertExternalContact(apiRequestBody, true),
        {
          pending: 'Contact Update in Progress...',
        }
      );
      if (createContactResponse.status == 200) {
        toast.success(
          createContactResponse?.message ?? 'Contact Updated Successfully'
        );
        logEvent('MANAGE_CONTACT', 'USER_ACTIVITY', {
          displayNames: `"${apiRequestBody.displayName.join(',')}"`,
          actions:'Update',
          resource: 'External_Contacts'
        });
        setTimeout(() => {
          backToHome();
        }, 5000);
      } else if (createContactResponse.status == 401) {
        toast.error(
          createContactResponse?.data ?? 'Unauthorized to access contacts page'
        );
        clearSessionAndRedirect();
      } else {
        toast.error(createContactResponse?.data ?? createContactResponse.data);
        logEvent('MANAGE_CONTACT', 'EXCEPTION', {
          displayNames: `"${apiRequestBody.displayName.join(',')}"`,
          actions:'Update',
          resource: 'External_Contacts',
          error: createContactResponse?.data ? `"${createContactResponse?.data}"` : 'Failed to update External Contact'
        });
      }
    } catch (error) {
      toast.error(String(error));
    } finally {
      setIsLoading(false);
    }
  };

  const reset = async () => {
    if (markEntry) {
      setEmailId(markEntry.email);
    } else {
      setEmailId('');
    }
    setTreeItems([]);
    // Force a re-render by changing the resetKey state
    setResetKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    if (markEntry) {
      setEmailId(markEntry.email);
      setIsEmailDisabled(true);
    }
    setTreeItems(() => convertToTreeItems(data));
  }, [resetKey]);

  return (
    <div>
      <div className="create-contact-card">
        {treeItems.length > 0 && (
          <>
            <InputEmail
              onEmailChange={handleEmailChange}
              isError={isEmailError}
              isDisabled={isEmailDisabled}
              value={emailId}
            />
            <ServiceSelect
              isError={selectedServiceError}
              data={treeItems}
              handleNodeSelect={handleNodeSelect}
              statuscastServiceId={markEntry?.statusCastServiceIds}
            />
          </>
        )}
      </div>
      <div
        className="d-flex justify-content-center"
        style={{ marginTop: '40px' }}
      >
        <Button
          variant="secondary"
          style={{ marginRight: '10px' }}
          onClick={initiateCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          variant="secondary"
          style={{ marginRight: '10px' }}
          onClick={reset}
          disabled={isLoading}
        >
          Reset
        </Button>
        {markEntry ? (
          <Button
            variant="primary"
            onClick={handleModify}
            disabled={!validForm() || isLoading}
          >
            <i
              className="modus-icons material-icons left-icon"
              aria-hidden="true"
            >
              save_disk
            </i>
            Update
          </Button>
        ) : (
          <Button
            variant="primary"
            onClick={handleCreate}
            disabled={!validForm() || isLoading}
          >
            <i
              className="modus-icons material-icons left-icon"
              aria-hidden="true"
            >
              save_disk
            </i>
            Create
          </Button>
        )}
      </div>
      <br></br>
    </div>
  );
}

export default ExternalContactUtilit;
