export const CREATE_FORM_CONSTANTS = {
  ALERT_CONFIRM_TEXT: 'Are you sure ?',
  ALERT_CONFIRM_CLOSE: 'Do you really want to discard creation? ',
  ALERT_CONFIRM_CREATE: 'Creating an incident will trigger emails to all the subscribed customers of this service. Would you like to proceed with this action?',
  CONFIRM_CLOSE_CANCEL_TEXT: 'Yes',
  CONFIRM_CLOSE_DISCARD_TEXT: 'No'
};

export interface createRequest {
  incidentId: string;
  type: string;
  incidentState: string;
  product: string;
  service: string;
  startTimeInUTC: string | null;
  endTimeInUTC?: string;
  message?: string;
  closedIncidentId?: string;
  alternativeBeamName?: string;
}

export const CREATE_INCIDENT_MESSAGE_TYPE = {
  New: {
    Outage: 'New_Outage',
    PartialOutage: 'New_PartialOutage',
    PlannedMaintenance: 'New_PlannedMaintenance',
  },
  Resolved: {
    Outage: 'Resolved_Outage',
    PartialOutage: 'Resolved_PartialOutage',
    PlannedMaintenance: 'Resolved_PlannedMaintenance',
  },
  Transition:{
    Outage: 'Transition_Outage',
    PartialOutage: 'Transition_PartialOutage'
  }
};

export var details: any = {};
details['New'] = CREATE_INCIDENT_MESSAGE_TYPE.New;
details['Resolved'] = CREATE_INCIDENT_MESSAGE_TYPE.Resolved;
details['Transition'] = CREATE_INCIDENT_MESSAGE_TYPE.Transition;

export const CREATE_INCIDENT_MACRO_VARIBALES = [
  { value: '${service}', label: 'service' },
  { value: '${plannedTime} UTC', label: 'plannedTime' },
  { value: '${plannedDate}', label: 'plannedDate' },
  { value: '${startTime} UTC', label: 'startTime' },
  { value: '${endTime} UTC', label: 'endTime' },
  { value: '${duration}', label: 'duration' },
];
