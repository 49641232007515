const USERMANAGEMENT_BASE_URL =
  'https://cloud.dev.api.trimblecloud.com/tap-informer/users/v1';
const INCIDENTMANAGEMENT_BASE_URL =
  'https://cloud.dev.api.trimblecloud.com/tap-informer/v1';
const SERVICEMANAGEMENT_BASE_URL =
  'https://cloud.dev.api.trimblecloud.com/tap-informer/services/v1';
const GROUPMANAGEMENT_BASE_URL =
  'https://cloud.dev.api.trimblecloud.com/tap-informer/groups/v1';
const EMAILMANAGEMENT_BASE_URL =
  'https://cloud.dev.api.trimblecloud.com/tap-informer/mailer/v1';
const APP_BASE_URL = 'https://tap-management-portal.azurewebsites.net';
const TID_BASE_URL = 'https://stage.id.trimblecloud.com';
const STATUS_SIGNUP_PAGE_URL = 'https://tapinformerdevui.azurewebsites.net/';
const STATUSCAST_BASEURL = 'https://trimble-spime-sandbox.status.page';
const STATUSCAST_TOKEN = process.env.REACT_APP_STATUSCAST_TOKEN;

export const env = {
  clientId: 'ebddfa25-34fa-47ca-ba2d-3270694185f6',
  applicationName: 'tap-informer-user-ui-dev',
  tenantDomain: 'Trimble.com',
  scope: 'openid',
  redirectUrl: `${APP_BASE_URL}/redirect`,
  logoutUrl: `${TID_BASE_URL}/oauth/logout`,
  userManagementUrl: `${USERMANAGEMENT_BASE_URL}`,
  authCodeUrl: `${TID_BASE_URL}/oauth/authorize`,
  accessTokenUrl: `${TID_BASE_URL}/oauth/token`,
  userInfoUrl: `${TID_BASE_URL}/oauth/userinfo`,
  applicationHomeUrl: `${APP_BASE_URL}`,
  incidentManagementUrl: `${INCIDENTMANAGEMENT_BASE_URL}`,
  serviceManagementUrl: `${SERVICEMANAGEMENT_BASE_URL}`,
  groupManagementUrl: `${GROUPMANAGEMENT_BASE_URL}`,
  emailManagementUrl: `${EMAILMANAGEMENT_BASE_URL}`,
  domoDatasetId: 'ee686c76-3a1a-4a15-977b-9739ee4ab3ff',
  domoClientId: '93bcf1e2-ed09-44a1-ba27-814f18ee0079',
  domoClientSecret:
    'd967bc573e563e0a696016d393a785358eb4957459edf49843d6914b155b6242',
  statusSignupPageUrl: STATUS_SIGNUP_PAGE_URL,
  statusCastBaseUrl: STATUSCAST_BASEURL,
  statusCastToken: STATUSCAST_TOKEN,
};
