export const ADD_ESCALATION_CONTACTS = {
  EMAIL: {
    label: 'Email',
    key: 'emails',
    validationMessage: 'Please enter a valid trimble email address',
    error_length: 'Email should not exceed 250 characters',
    placeholder: 'Enter the email address',
  },
  LEVEL: {
    label: 'Level',
    key: 'escalationLevel',
    drop_down_default: '-Select a level-',
    validationMessage: 'Please select atleast one Level',
  },
  LEVEL_LIST: [
    { name: 'First Internal Escalation (L team)', code: 1 },
    { name: 'Second Internal Escalation (VP)', code: 2 },
  ],
  ADD_USER: {
    label: 'Add Contact',
  },
};

export interface IAddEscalationContactsProps {
  setAddedContactEmail: React.Dispatch<React.SetStateAction<string>>;
}
