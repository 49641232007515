import {
  Alert,
  AlertDismissible,
  Button,
  FileUploadDropZone,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip,
} from '@trimbleinc/modus-react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ListExternalContacts from './components/ListExternalContacts';
import { useEffect, useState } from 'react';
import { fetchAllServices } from 'services/serviceManagement.service';
import { toast } from 'react-toastify';
import { clearSessionAndRedirect } from 'utils/Error.utils';
import Loading from 'components/Common/Loading';
import {
  ESCALATION_CONTACTS_CONSTANTS,
  Service,
} from './ExternalContactsConstants';
import { isNotAdmin } from 'utils/RoleCheck.utils';
import RouterPath from 'config/routerPath';
import logEvent from 'utils/TrimbleInsightsLogger.utils';
import {
  downloadExternalContact,
  downloadFile,
  fetchImportStatusFromDB,
  pollDownloadStatus,
  uploadFile,
} from 'services/groupManagement.service';
import { getItemFromLocalStorage } from 'utils/LocalStorage.utils';
import { EXTERNAL_CONTACT_UTILITY_CONSTANTS } from './components/ListExternalContacts/components/ExternalContactUtility/ExternalContactUtilityConsts';
import Swal from 'sweetalert2';

interface DownloadFileRequests {
  loggedInUserEmail: string;
  statusCastServiceId?: string;
}

function ExternalContact() {
  const [services, setServices] = useState<Service[]>([]);
  const [isLoadedService, setIsLoadedService] = useState(false);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [serviceIds, setServiceIds] = useState<number[]>([]);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [downloadCompleted, setDownloadCompleted] = useState(false);
  const [renderComponent, setRenderComponent] = useState(false);
  const [importInProgress, setImportInProgress] = useState(false);
  const [importFailed, setImportFailed] = useState(false);
  const [importCompleted, setImportCompleted] = useState(false);
  const [importErrorMessage, setImportErrorMessage] = useState<string | null>(null);
  const [renderImportComponent, setRenderImportComponent] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RouterPath.PRIVATE_ROUTE.createContact, {
      state: { data: services },
    });
  };

  const fetchService = async () => {
    try {
      const serviceResponse = await fetchAllServices();
      if (serviceResponse.status === 200) {
        setIsLoadedService(true);
        setServices(serviceResponse.data);
      } else if (serviceResponse.status == 401) {
        toast.error('Unauthorized to access services');
        clearSessionAndRedirect();
      } else {
        toast.error('Failed to fetch Services');
        logEvent('GET_ALL_SERVICES', 'EXCEPTION', {
          actions: 'Retrieve',
          resource: 'Services',
          error: serviceResponse?.data
            ? `"${serviceResponse?.data}"`
            : 'Failed to fetch services',
        });
      }
    } catch (error) {
      toast.error('Something went wrong');
    }
  };

  const handleContactDump = () => {
    window.open(ESCALATION_CONTACTS_CONSTANTS.CONTACTS_DUMP_SHEET_URL);
  };

  const handleDownload = async () => {
    setDownloadInProgress(true);
    const userEmail = JSON.parse(getItemFromLocalStorage('auth')!).email;
    let requestBody: DownloadFileRequests = {
      loggedInUserEmail: userEmail,
    };

    if (serviceIds.join(',') !== '0') {
      requestBody.statusCastServiceId = serviceIds.join(',');
    }

    const downloadContacts = await downloadExternalContact(requestBody);
    if (downloadContacts.status === 200) {
      setDownloadInProgress(true);
      setRenderComponent(!renderComponent);

    } else if (downloadContacts.status == 401) {
      toast.error('Unauthorized to access services');
      clearSessionAndRedirect();
    } else {
      setDownloadInProgress(false);
      toast.error('Failed to download contacts');
      logEvent('DOWNLOAD_EXTERNAL_CONTACTS', 'EXCEPTION', {
        actions: 'Download',
        resource: 'External_Contacts',
        error: downloadContacts?.data
          ? `"${downloadContacts?.data}"`
          : 'Failed to download contacts',
      });
    }
  };

  const onUpload = async () => {
    try {
      setIsFileUpload(true)
      const userEmail = JSON.parse(getItemFromLocalStorage('auth')!).email;
      const response = await uploadFile(filesUploaded, userEmail);
      setIsFileUpload(false)
      if (response.status == 200) {
        setImportInProgress(true);
        setRenderImportComponent(!renderImportComponent)
        Swal.fire(
          `<h4>${EXTERNAL_CONTACT_UTILITY_CONSTANTS.UPLOAD_SUCCESS}<h4>`,
          `<h4>${EXTERNAL_CONTACT_UTILITY_CONSTANTS.SUCCESS_INFO}<h4>`,
          'success'
        )
        onUploadSuccess()
        logEvent('IMPORT_EXTERNAL_CONTACTS', 'USER_ACTIVITY', {
          actions: 'Import',
          resource: 'External_Contacts'
        });
      }
      else if (response.status == 401) {
        toast.error('Unauthorized to import contacts')
        clearSessionAndRedirect()
      }
      else {
        setImportInProgress(false)
        toast.error(response.data ?? EXTERNAL_CONTACT_UTILITY_CONSTANTS.UPLOAD_ERROR)
        logEvent('IMPORT_EXTERNAL_CONTACTS', 'EXCEPTION', {
          actions: 'Import',
          resource: 'External_Contacts',
          error: response?.data
            ? `"${response?.data}"`
            : 'Failed to import contacts',
        });
      }
    }
    catch {
      toast.error('Something went wrong')
    }
  }

  const onUploadSuccess = () => {
    clearFileList()
    handleFileClose()
  }
  // handle fileUpload modal
  const [filesUploaded, setFilesUploaded] = useState<File[]>([]);
  const [fileShow, setFileShow] = useState(false);
  const handleFileShow = () => setFileShow(true);
  const handleFileClose = () => {
    setFileShow(false);
    clearFileList()
  };

  const [isUploadValid, setIsUploadValid] = useState<boolean>(true)
  useEffect(() => { //enable and disable file upload button
    if (filesUploaded.length > 0) {
      setIsUploadValid(false)
    } else {
      setIsUploadValid(true)
    }
  }, [filesUploaded])

  const downloadTemplate = async () => {
    try {
      const filename = EXTERNAL_CONTACT_UTILITY_CONSTANTS.TEMPLATE_FILENAME;
      const url = '/' + filename;
      const link = document.createElement('a')
      link.href = url
      link.download = EXTERNAL_CONTACT_UTILITY_CONSTANTS.TEMPLATE_FILENAME
      link.click()
      link.remove()
    }
    catch (error) {
      let err: any = error
      toast.error(`${err.message}`);
    }
  }

  const clearFileList = () => {
    const emptyFileList = new DataTransfer().files;
    setFilesUploaded(Array.from(emptyFileList));
  };

  useEffect(() => {
    const userEmail = JSON.parse(getItemFromLocalStorage('auth')!).email;
    const interval = setInterval(async () => {
      const importStatus = await fetchImportStatusFromDB(userEmail);
      if (importStatus.data.length > 0) {
        const status = importStatus.data[0].importStatus;
        const errorMessage = importStatus.data[0]?.errorMessage;
        const startTime = new Date(importStatus.data[0].startTime);
        const currentTime = new Date();
        const timeDiffInHours = (currentTime.getTime() - startTime.getTime()) / (1000 * 60 * 60);
        if (timeDiffInHours < 1) {
          if (status === 'InProgress') {
            setImportInProgress(true);
          } else if (status === 'Failed') {
            setImportInProgress(false);
            setImportFailed(true);
            setImportErrorMessage(errorMessage);
            clearInterval(interval);
          } else if (status === 'Success') {
            setImportInProgress(false);
            setImportCompleted(true);
            clearInterval(interval);
          }
        }
        else {
          clearInterval(interval);
        }
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [renderImportComponent]);

  useEffect(() => {
    fetchService();
  }, []);

  const downloadFileHelper = async (fileName: string) => {
    const downloadurlFetch = await downloadFile(fileName);
    if (downloadurlFetch.status === 200) {
      const downloadUrl = downloadurlFetch.data;

      if (typeof (window.navigator as any).msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = fileName;
        a.target = '_blank';
        a.click();
      } else {
        var tempLink = document.createElement('a');
        tempLink.style.display = 'none';
        tempLink.href = downloadUrl;
        tempLink.setAttribute('download', fileName);

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
        }

        document.body.appendChild(tempLink);
        tempLink.click();

        // Fixes "webkit blob resource error 1"
        setTimeout(function () {
          document.body.removeChild(tempLink);
        }, 200);
      }
    }
  };

  useEffect(() => {
    const userEmail = JSON.parse(getItemFromLocalStorage('auth')!).email;
    const interval = setInterval(async () => {
      const pollStatus = await pollDownloadStatus(userEmail);
      if (pollStatus.data.length > 0) {
        if (pollStatus.data[0].exportStatus === 'InProgress') {
          setDownloadInProgress(true);
        } else if (pollStatus.data[0].exportStatus === 'Success') {
          const fileName = pollStatus.data[0].orchestrationId;
          await downloadFileHelper(fileName);
          setDownloadInProgress(false);
          setDownloadCompleted(true);
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [renderComponent]);
  return (
    <div>
      {isLoadedService ? (
        <>
          <div className='d-flex justify-contents-center create-ext-button-position'>
            <div className='download-btn'>
              <OverlayTrigger
                key='overlay1'
                placement='top'
                overlay={<Tooltip id='btntooltip'>Download</Tooltip>}
              >
                <div className='d-flex export-button'>
                  <Button
                    variant='tertiary'
                    size='lg'
                    onClick={handleDownload}
                    style={{ borderRadius: '35px' }}
                    disabled={downloadInProgress}
                  >
                    <i className='modus-icons left-icon' aria-hidden='true'>
                      unload_route_stop
                    </i>
                    Download
                  </Button>
                </div>
              </OverlayTrigger>
            </div>

            <div>
              <OverlayTrigger
                key='overlay1'
                placement='top'
                overlay={<Tooltip id='btntooltip'>Import</Tooltip>}
              >
                <div className='d-flex export-button'>
                  <Button
                    variant='tertiary'
                    size='lg'
                    onClick={handleFileShow}
                    style={{ borderRadius: '35px' }}
                    disabled={importInProgress}
                  >
                    <i className='modus-icons left-icon' aria-hidden='true'>
                      upload
                    </i>
                    Import Contacts
                  </Button>
                </div>
              </OverlayTrigger>
            </div>

            <div>
              <OverlayTrigger
                key='overlay1'
                placement='top'
                overlay={<Tooltip id='btntooltip'>Contacts Dump</Tooltip>}
              >
                <div className='d-flex export-button'>
                  <Button
                    variant='tertiary'
                    size='lg'
                    onClick={handleContactDump}
                    style={{ borderRadius: '35px' }}
                  >
                    <i
                      className='modus-icons material-icons left-icon'
                      aria-hidden='true'
                    >
                      external_link
                    </i>
                    Contacts Dump
                  </Button>
                </div>
              </OverlayTrigger>
            </div>

            <div>
              <Button
                variant='primary'
                size='lg'
                onClick={handleClick}
                style={{ borderRadius: '35px' }}
                disabled={isNotAdmin()}
              >
                <i
                  className='modus-icons material-icons left-icon'
                  aria-hidden='true'
                >
                  add
                </i>
                Add Contact
              </Button>
            </div>
          </div>
          <Modal show={fileShow} centered onHide={handleFileClose}>
            {isFileUpload && <div className="overlay"><Loading /></div>}
            <Modal.Header placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }} closeButton>
              <Modal.Title>Upload File</Modal.Title>
            </Modal.Header>

            <Modal.Body className='file-modal'>
              <div>
                <FileUploadDropZone placeholder="" onPointerEnterCapture={() => { }} onPointerLeaveCapture={() => { }}
                  id="excelUpload"
                  maxFileCount={1}
                  maxTotalFileSizeBytes={EXTERNAL_CONTACT_UTILITY_CONSTANTS.EXCEL_FILE_SIZE}
                  accept=".xlsx"
                  onFiles={(files, err) => {
                    if (!err) {
                      const filesArray = Array.from(files) as File[];
                      setFilesUploaded(filesArray);
                    }
                  }
                  }
                  style={{ width: "350px", height: "150px" }}
                ></FileUploadDropZone>
                {filesUploaded && (
                  <>
                    <ul className="list-group list-group-borderless">
                      {Array.from(filesUploaded).map((file, index) => (
                        <li className="list-group-item list-item-left-control" key={index}>
                          <i className="modus-icons">check_circle</i>
                          <span>{file.name}</span>
                        </li>
                      ))}
                    </ul>
                  </>
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <p style={{ marginTop: '10px', marginBottom: '10px' }}>
                For reference, you can find the product and servcice trigger names in the {' '}
                <a href={ESCALATION_CONTACTS_CONSTANTS.CONTACTS_DUMP_SHEET_URL} target='_blank'>
                  Contacts Dump sheet.
                </a>
              </p>
              <Button variant="tertiary" onClick={downloadTemplate}>
                {EXTERNAL_CONTACT_UTILITY_CONSTANTS.DOWNLOAD_TEMPLATE}
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="mi-outline mi-download-line" viewBox="0 0 24 24">
                  <path d="M18 18.5H6c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1Zm-6.71-2.71c.39.39 1.02.39 1.41 0l4.59-4.59c.63-.63.18-1.71-.71-1.71h-1.59v-5c0-.55-.45-1-1-1h-4c-.55 0-1 .45-1 1v5H7.4c-.89 0-1.34 1.08-.71 1.71l4.59 4.59ZM10.5 11V5h3v6h1.88L12 14.38 8.62 11h1.88Z" />
                </svg>
              </Button>
              <Button variant="primary" onClick={onUpload} disabled={isUploadValid}>
                {EXTERNAL_CONTACT_UTILITY_CONSTANTS.IMPORT_FILE}
              </Button>
            </Modal.Footer>
          </Modal>

          <div>
            {importInProgress && (
              <Alert key='a1' variant='primary'>
                <i className='modus-icon material-icons alert-icon'>info</i>
                Your import is in progress. Please wait...
              </Alert>
            )}
            {importCompleted && (
              <AlertDismissible
                key='a4'
                variant='success'
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClose={() => setImportCompleted(false)}
              >
                <i className='material-icons alert-icon'>check_circle</i>
                Contacts import has been successfully completed.
              </AlertDismissible>
            )}
            {importFailed && (
              <AlertDismissible
                key='a4'
                variant='danger'
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClose={() => setImportFailed(false)}
              >
                <i className='material-icons alert-icon'>warning</i>
                {importErrorMessage}
              </AlertDismissible>
            )}
          </div>

          <div>
            {downloadInProgress && (
              <Alert key='a1' variant='primary'>
                <i className='modus-icon material-icons alert-icon'>info</i>Your
                download is in progress. Please wait...
              </Alert>
            )}
            {downloadCompleted && (
              <AlertDismissible
                key='a4'
                variant='success'
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                onClose={() => setDownloadCompleted(false)}
              >
                <i className='material-icons alert-icon'>check_circle</i>
                Download has been completed. Please check your downloads.
              </AlertDismissible>
            )}
          </div>
          <ListExternalContacts
            services={services}
            setServiceIds={setServiceIds}
          />
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default ExternalContact;
