import { ToastContainer } from 'react-toastify';
import TabContainer from './components/TabContainer/TabContainer';

function ContactListManagement() {
  return (
    <div className="main-tab-container">
      <h1 className="heading">Contacts List Management</h1>
      <TabContainer />
      <ToastContainer />
    </div>
  );
}

export default ContactListManagement;
