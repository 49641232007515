import { Button, Col, Form, Row } from "@trimbleinc/modus-react-bootstrap";
import Loading from "components/Common/Loading";
import Editor from "components/Common/SummerNote/Editor";
import { transformNotificationMsg } from "constants/MessageType";
import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { toast } from "react-toastify";
import { fetchNotificationTemplate, updateNotificationTemplate } from "services/mailer.service";
import { clearSessionAndRedirect } from "utils/Error.utils";
import logEvent from "utils/TrimbleInsightsLogger.utils";
import RouterPath from 'config/routerPath';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import './styles.scss'

interface TempConfigProps {
    handleModalClose: () => void;
}

interface ModifiedValue {
    type: string;
    subject: string;
    template: string;
}

const NotificationTemplate = forwardRef((props: TempConfigProps, ref) => {
    const { handleModalClose } = props;
    const [editorValues, setEditorValues] = useState<{ [key: string]: { Subject: string, Template: string } }>({});
    const [initialValues, setInitialValues] = useState<{ [key: string]: { Subject: string, Template: string } }>({});
    const [templates, setTemplates] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    const handleEditorChange = (field: string, subField: string, value: string) => {
        setEditorValues(prevValues => ({
            ...prevValues,
            [field]: {
                ...prevValues[field],
                [subField]: value
            }
        }));
    };
    const getTemplates = async () => {
        try {
            setIsLoading(true);
            const templateResponse = await fetchNotificationTemplate();
            setTemplates(templateResponse.data);
            setIsLoading(false);
        }
        catch (error) {
            toast.error('Failed to fetch templates');
        }
    }
    const defaultMessageTypes = [
        "Subscriber_Add", "Subscriber_Delete", "Subscriber_Update",
        "ESC_Subscriber_Add", "ESC_Subscriber_Delete", "ESC_Subscriber_Update",
        "User_Add", "User_Delete", "User_Update", 'Service_Delete'
    ];

    const getMacroOptions = (type: string) => {
        if (type.includes('ESC')) {
            return [{ value: '${escLevel}', label: 'escLevel' }];
        } else if (type.includes('User')) {
            return [{ value: '${role}', label: 'role' }];
        } else if (type.includes('Subscriber') || type.includes('Service')) {
            return [{ value: '${serviceNames}', label: 'serviceNames' }];
        }
        return [];
    };


    const handleSave = async () => {
        try {
            const modifiedValues: ModifiedValue[] = Object.keys(editorValues).reduce((acc, type) => {
                const cleanseSubject = (subject: string | undefined) => subject?.replace(/<p>|<\/p>|<br>/g, '') || '';

                const cleansedEditorSubject = cleanseSubject(editorValues[type].Subject);
                const cleansedInitialSubject = cleanseSubject(initialValues[type].Subject);
                if (
                    cleansedEditorSubject !== cleansedInitialSubject ||
                    editorValues[type].Template !== initialValues[type].Template
                ) {
                    acc.push({
                        type,
                        subject: cleansedEditorSubject,
                        template: editorValues[type].Template
                    });
                }
                return acc;
            }, [] as ModifiedValue[]);

            if (modifiedValues.length > 0) {
                setIsLoading(true);
                const response = await updateNotificationTemplate(modifiedValues);
                setIsLoading(false);
                if (response.status == 200) {
                    handleModalClose();
                    Swal.fire({
                        title: `<h4>Success!<h4>`,
                        html: `<p>Template updated successfully !</p>`,
                        icon: 'success',
                        preConfirm: () => Swal.close()
                    });
                    logEvent('NOTIFICATION_TEMPLATE', 'USER_ACTIVITY', {
                        actions: 'Update',
                        resource: 'Mailer'
                    });
                    navigate
                }
                if (response.status == 409 || response.status == 400 || response.status == 500) {
                    toast.error(response?.data ?? "Failed to add user")
                    logEvent('NOTIFICATION_TEMPLATE', 'EXCEPTION', {
                        actions: 'Update',
                        resource: 'Mailer',
                        error: response?.data ? `"${response?.data}"` : "Failed to update template"
                    });
                }
                if (response.status == 401) {
                    toast.error(response?.data ?? "Unauthorized to access templates page")
                    clearSessionAndRedirect()
                }
            } else {
                toast.error('No changes to save');
            }
        }
        catch (e) {
            console.log(e);
        };
    }

    useImperativeHandle(ref, () => ({
        handleSave
    }));

    useEffect(() => {
        getTemplates();
    }, []);

    useEffect(() => {
        setIsLoading(true);
        const initialEditorValues: { [key: string]: { Subject: string, Template: string } } = {};
        if (Array.isArray(templates)) {
            templates.forEach(item => {
                if (item.type) {
                    initialEditorValues[item.type] = {
                        Subject: item.subject,
                        Template: item.template
                    };
                }
            });
        }
        setEditorValues(initialEditorValues);
        setInitialValues(initialEditorValues);
        setIsLoading(false);
    }, [templates]);
    return (
        <>
            {isLoading && (
                <div className='overlay'>
                    <Loading />
                </div>
            )}
            <div>
                {defaultMessageTypes.map((type) => (
                    <Row className="form-row" key={type}>
                        <Col xs={12} md={4}>
                            <Form.Label><h4>{transformNotificationMsg(type)}</h4></Form.Label>
                        </Col>
                        <Col xs={12} md={8}>
                            <Form.Group controlId={`formBasic${type}`}>
                                <Form.Label className="template-label"><h4>Subject:</h4></Form.Label>
                                <Editor
                                    id={`${type}SubEditor`}
                                    value={editorValues[type]?.Subject || ''}
                                    onChange={(value: any) => handleEditorChange(type, 'Subject', value)}
                                    placeholder="Enter the subject here"
                                />
                            </Form.Group>
                            <Form.Group controlId={`formBasic${type}`}>
                                <Form.Label className="template-label"><h4>Template:</h4></Form.Label>
                                <Editor
                                    id={`${type}TemEditor`}
                                    value={editorValues[type]?.Template || ''}
                                    onChange={(value: any) => handleEditorChange(type, 'Template', value)}
                                    variableMacroOptions={getMacroOptions(type)}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                ))}
            </div>
        </>
    );
});

export default NotificationTemplate;