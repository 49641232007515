import { statusCastRequestBody } from 'features/ViewIncidents/components/MessageTimeline/MessageTimeline.constants';
import { fetchReq } from './http.service';
import serviceUrls, { serviceTokens } from './serviceUrls';

const { statusCastURL } = serviceUrls;
const { statusCastToken } = serviceTokens;

export const fetchIncidentMessages = async (request: statusCastRequestBody) => {
  const url = `${statusCastURL}/api/v4/incidents`;

  return await fetchReq(url, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${statusCastToken}`,
    },
    body: JSON.stringify(request),
  });
};
