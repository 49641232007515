import {
  UncontrolledTreeEnvironment,
  Tree,
  StaticTreeDataProvider,
  TreeItem,
  DraggingPosition,
  TreeItemIndex,
} from 'react-complex-tree'; // Replace with actual library imports
import {
  ITreeItem,
  ITreeNodeProps,
  TREE_NODE_CONSTANTS,
} from './TreeNode.constants';
import './styles.scss';
import { TreeLayout } from 'components/Common/TreeLayout/TreeLayout';
import {
  deleteService,
  fetchAllServices,
  updateServiceDetails,
} from 'services/serviceManagement.service';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import { getUpdateDetails } from '../UpdateDetails/UpdateDetails';
import CustomArrowRenderer from '../CustomArrow/CustomArrow';
import { useState } from 'react';
import Loading from 'components/Common/Loading';
import logEvent from 'utils/TrimbleInsightsLogger.utils';
import { useNavigate } from 'react-router-dom';
import RouterPath from 'config/routerPath';
import { fetchAllIncidentsfromBackend } from 'services/incidentManagement.service';
import { set } from 'date-fns';
import { restoreService } from 'services/groupManagement.service';

const convertData = (jsonData: ITreeItem[]): Record<string, any> => {
  const items: Record<string, any> = {};

  // Recursive function to convert each item in the JSON data
  const convertItem = (item: ITreeItem): Record<string, any> => {
    const {
      displayName,
      emailName,
      statuscastId,
      product,
      service,
      isHidden,
      statuscastGroupId,
      peerPriority,
      statuscastServiceParentId,
      children,
      isMainComponent,
      isDeletion,
      orchestrationId,
    } = item;

    // Construct the item object
    const newItem = {
      index: statuscastId.toString(),
      isFolder: true,
      children: children
        ? children.map((child) => child.statuscastId.toString())
        : [],
      data: displayName,
      emailName: emailName,
      statuscastId: statuscastId,
      product: product,
      service: service,
      isHidden: isHidden,
      statuscastGroupId: statuscastGroupId,
      peerPriority: peerPriority,
      statuscastServiceParentId: statuscastServiceParentId,
      isMainComponent: isMainComponent,
      isDeletion: isDeletion,
      orchestrationId: orchestrationId,
    };

    items[statuscastId.toString()] = newItem;

    // Recursively convert children if they exist
    if (children && children.length > 0) {
      children.forEach((child) => convertItem(child));
    }

    return newItem;
  };

  // Process each top-level item in the JSON data
  const rootChildren: string[] = [];
  jsonData.forEach((item) => {
    convertItem(item);
    rootChildren.push(item.statuscastId.toString());
  });

  // Add root item
  items['root'] = {
    index: 'root',
    isFolder: true,
    children: rootChildren,
    data: 'Root item',
  };

  return items;
};

function TreeNode({ getServices, data }: ITreeNodeProps) {
  const navigate = useNavigate();

  const handleModifyService = async (
    updateDetails: Record<string, any>,
    getServices: () => Promise<void>,
    modifyService: string
  ) => {
    if (updateDetails.product === updateDetails.updatedProduct) {
      delete updateDetails.updatedProduct;
    }
    let response: any = null;
    Swal.fire({
      title: `<h4>Are you sure?<h4>`,
      icon: 'error',
      html: `<p>Do you really want to modify ${modifyService} service ?<p>`,
      showCancelButton: true,
      confirmButtonText: 'Modify',
      cancelButtonText: `Cancel`,
      preConfirm: async () => {
        try {
          Swal.close();
          response = await toast.promise(updateServiceDetails(updateDetails), {
            pending: TREE_NODE_CONSTANTS.UPDATE_INPROGRESS,
          });
        } catch (error) {
          toast.error('Something went wrong');
        }
      },
    }).then((result) => {
      if (response && response.status == 200) {
        logEvent('MANAGE_SERVICE', 'USER_ACTIVITY', {
          service: updateDetails.service,
          product: updateDetails.product,
          ...(updateDetails?.updatedProduct && {
            updatedProduct: updateDetails.updatedProduct,
          }),
          ...(updateDetails?.updatedPeerPriority && {
            updatedPeerPriority: updateDetails.updatedPeerPriority,
          }),
          actions: 'Update',
          resource: 'Services',
        });
        window.location.reload();
      }
      if (response && (response.status == 400 || response.status == 500)) {
        toast.error(response.data ?? 'Error', {
          onClose: () => getServices(),
        });
        logEvent('MANAGE_SERVICE', 'EXCEPTION', {
          service: updateDetails.service,
          product: updateDetails.product,
          ...(updateDetails?.updatedProduct && {
            updatedProduct: updateDetails.updatedProduct,
          }),
          ...(updateDetails?.updatedPeerPriority && {
            updatedPeerPriority: updateDetails.updatedPeerPriority,
          }),
          actions: 'Update',
          resource: 'Services',
          error: response?.data
            ? `"${response?.data}"`
            : 'Failed to update service',
        });
      }
      if (response && response.status == 401) {
        toast.error('Unauthorized');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        getServices();
      }
    });
  };

  const [originalData, setOriginalData] = useState<ITreeItem[]>(data);
  const [isLoading, setIsLoading] = useState(false);

  const items: Record<string, any> = convertData(originalData);

  let dataProvider = new StaticTreeDataProvider(items, (item, newData) => ({
    ...item,
    data: newData,
  }));

  const handleDrop = (
    updatedItems: TreeItem<any>[],
    target: DraggingPosition
  ) => {
    const { updateDetails, modifyService } = getUpdateDetails(
      updatedItems,
      target,
      originalData
    );
    if (updateDetails.error) {
      Swal.fire({
        icon: 'info',
        title: `<h4>Invalid<h4>`,
        html: `<p>${updateDetails.message}</p>`,
        preConfirm: () => {
          getServices();
        },
      });
      return;
    }
    handleModifyService(updateDetails, getServices, modifyService);
  };

  const handleDelete = (item: any) => {
    //delete function
    let response: any = null;
    Swal.fire({
      title: `<h4>${TREE_NODE_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'error',
      html:
        item.children.length > 0
          ? `<p>Do you really want to delete the service <b>${item.data}</b> and its child services? Its associated subscribers will also be deleted.</p>`
          : `<p>Do you really want to delete the service <b>${item.data}</b>? Its Its associated subscribers will also be deleted.</p>`,
      showCancelButton: true,
      confirmButtonText: TREE_NODE_CONSTANTS.DELETE_CONFIRM_TEXT,
      cancelButtonText: TREE_NODE_CONSTANTS.CANCEL_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          setIsLoading(true);
          response = await deleteService(item.product, item.service);
          setIsLoading(false);
        } catch (error) {
          toast.error(TREE_NODE_CONSTANTS.DELETE_API_ERROR);
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (response && response.status === 200) {
          Swal.fire({
            title: `<h4>Success<h4>`,
            icon: 'success',
            html: `<p>${TREE_NODE_CONSTANTS.DELETE_SERVICE_SUCCESS}</p>`,
            showCancelButton: true,
            showConfirmButton: false,
          });
          const response = await fetchAllServices();
          if (response.status === 200) {
            setOriginalData(response.data);
            const items: Record<string, any> = convertData(response.data);

            dataProvider = new StaticTreeDataProvider(
              items,
              (item, newData) => ({
                ...item,
                data: newData,
              })
            );
          }
          logEvent('DELETE_SERVICE', 'USER_ACTIVITY', {
            service: item.service,
            product: item.product,
            actions: 'Delete',
            resource: 'Services',
          });
        } else if (
          (response && response.status === 400) ||
          response.status === 500
        ) {
          toast.error(response.data ?? TREE_NODE_CONSTANTS.DELETE_ERROR);
          logEvent('DELETE_SERVICE', 'EXCEPTION', {
            actions: 'Delete',
            resource: 'Services',
            error: response?.data
              ? `"${response?.data}"`
              : 'Failed to delete service',
          });
        } else if (response && response.status === 401) {
          toast.error(TREE_NODE_CONSTANTS.DELETE_UNAUTHORIZED);
        }
      } else if (result.isDismissed) {
        navigate(RouterPath.PRIVATE_ROUTE.serviceManagement);
      }
    });
  };

  const handleRestore = (item: any) => {
    //restore function
    let response: any = null;
    Swal.fire({
      title: `<h4>${TREE_NODE_CONSTANTS.ALERT_CONFIRM_TEXT}<h4>`,
      icon: 'info',
      html: `<p>Do you really want to restore the service <b>${item.data}</b>?</p>`,
      showCancelButton: true,
      confirmButtonText: TREE_NODE_CONSTANTS.RESTORE_CONFIRM_TEXT,
      cancelButtonText: TREE_NODE_CONSTANTS.CANCEL_TEXT,
      preConfirm: async () => {
        try {
          Swal.close();
          setIsLoading(true);
          response = await restoreService(item.orchestrationId);
          setIsLoading(false);
        } catch (error) {
          toast.error(TREE_NODE_CONSTANTS.DELETE_API_ERROR);
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (response && response.status === 200) {
          Swal.fire({
            title: `<h4>Success<h4>`,
            icon: 'success',
            html: `<p>${TREE_NODE_CONSTANTS.RESTORE_SERVICE_SUCCESS}</p>`,
            showCancelButton: true,
            showConfirmButton: false,
          });
          const response = await fetchAllServices();
          if (response.status === 200) {
            setOriginalData(response.data);
            const items: Record<string, any> = convertData(response.data);

            dataProvider = new StaticTreeDataProvider(
              items,
              (item, newData) => ({
                ...item,
                data: newData,
              })
            );
          }
          logEvent('RESTORE_SERVICE', 'USER_ACTIVITY', {
            service: item.service,
            product: item.product,
            actions: 'Restore',
            resource: 'Services',
          });
        } else if (
          (response && response.status === 400) ||
          response.status === 500
        ) {
          toast.error(response.data ?? TREE_NODE_CONSTANTS.RESTORE_ERROR);
          logEvent('RESTORE_SERVICE', 'EXCEPTION', {
            actions: 'Restore',
            resource: 'Services',
            error: response?.data
              ? `"${response?.data}"`
              : 'Failed to restore service',
          });
        } else if (response && response.status === 401) {
          toast.error(TREE_NODE_CONSTANTS.RESTORE_UNAUTHORIZED);
        }
      } else if (result.isDismissed) {
        navigate(RouterPath.PRIVATE_ROUTE.serviceManagement);
      }
    });
  };

  return (
    <>
      {isLoading && (
        <div className='overlay'>
          <Loading />
        </div>
      )}
      <UncontrolledTreeEnvironment
        key={Date.now()}
        dataProvider={dataProvider}
        getItemTitle={(item) => item.data}
        viewState={{}}
        canDragAndDrop={true}
        canReorderItems={true}
        canDropOnFolder={true}
        canDropOnNonFolder={true}
        renderItemArrow={(props) => <CustomArrowRenderer {...props} />}
        renderItem={(props) => (
          <TreeLayout
            {...props}
            getServices={getServices}
            data={originalData}
            handleDelete={handleDelete}
            handleRestore={handleRestore}
          />
        )}
        onDrop={handleDrop}
      >
        <Tree treeId='tree-5' rootItem='root' treeLabel='Tree Example' />
      </UncontrolledTreeEnvironment>
      <ToastContainer />
    </>
  );
}

export default TreeNode;
