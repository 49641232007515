export const EDIT_CONSTANTS = {
    EMAIL: {
        label: 'Email',
        key: 'userEmailId',
        validationMessage: 'Please enter a valid email',
        error_length: 'Email should not exceed 250 characters'
    },
    ROLE: {
        label: 'Role',
        key: 'updatedRole',
        drop_down_default : "-Select a role-",
        validationMessage: 'Please select a role',
    },
    ROLE_LIST : [
        {name: "Admin", code: "admin"},
        {name: "Super User", code: "superuser"},
        {name: "User",code: "user"},
    ],
    MODIFY_ROLE:{
        label: 'Modify Role',
    },
    MODIFY_ROLE_API_ERROR: "Something went wrong",
    MODIFY_ROLE_SUCCESS:"User has been updated successfully",
    MODIFY_ROLE_ERROR:"User updation failed",
    MODIFY_ROLE_UNAUTHORIZED:"Unauthorized to update the user role",
}
export interface IEditProps {
    value: any;
    modalClose: any;
    updateUserList: any;
    setIsUpdating: any;
}