import { Form } from '@trimbleinc/modus-react-bootstrap';

function TimestampFilter(props: any) {
  const handleFilterChange = (event: any) => {
    const value = event.target.value;
    props.onFilterChange(value); // Call the handler passed by the parent component
  };
  return (
    <Form className="timestamp-box">
      <Form.Group controlId="exampleForm.SelectCustom">
        <Form.Control
          as="select"
          size="lg"
          custom
          onChange={handleFilterChange}
          className='timestamp-filter'
        >
          <option value=''>All date</option>
          <option value="1">Today</option>
          <option value="2">Yesterday</option>
          <option value="7">Last 7 days</option>
          <option value="30">Last 30 days</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
}

export default TimestampFilter;
